import React, { useEffect, useState } from "react";
import "../Product/Product.scss";
import { IoReload } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { parseImage } from "../../Services/Regular-services/ImageService";
import { API_URL } from "../../Utils/Utils";
import { setProductData } from "../../Redux/Actions/ProductAction/ProductAction";
import onion from '../../assets/images/onion.png';
import Jain from '../../assets/images/hand.png';
import { BiDish, BiFoodTag } from "react-icons/bi";

export default function Product({
  updateTotalQuantity,
  decreaseTotalQuantity,
  onProductClick,
  hideImageAndAddButton,
  showReorderButton,
  date,
  showReorderButtonInsteadOfQuantity,
  product,
  quantityData,
  showMessageInput = false,
  showRadio = false,
  showBrandColor = true,
  propsBrandId = false,
  page = false,
  showPrice = false
}) {
  const addedProducts = useSelector((state) => state.product?.items);
  const [quantity, setQuantity] = useState(1);
  const [showQuantityInput, setShowQuantityInput] = useState(quantityData ? quantityData : false);
  const [imageData, setImageData] = useState('');
  // const [formData, setFormData] = useState('');
  const company = useSelector(state => state.company?.items);
  const dispatch = useDispatch();
  const brand_code = useSelector(state => state.brand?.brand_code);
  const productOrder = useSelector((state) => state.productOrder?.items);
  // const [outputData, setOutputData] = useState(null)

  useEffect(() => {
    if (product && product.image_data) {
      const data = parseImage(product.image_data);
      const newImageData = API_URL.TOKEN_URL + data[0].url;
      setImageData(newImageData);
    }
  }, [product]);
  useEffect(() => {
    if (propsBrandId) {
      setShowQuantityInput((productOrder && productOrder.length > 0 && productOrder.find((item) => item?.productId === product?.productId)?.quantity) ? true : false);
    }
    else {
      setShowQuantityInput((addedProducts && addedProducts.length > 0 && addedProducts.find((item) => item?.productId === product?.productId)?.quantity) ? true : false);
    }
    // eslint-disable-next-line
  }, [addedProducts, product, propsBrandId, productOrder]);


  // useEffect(() => {
  // if (product?.selectedAttributes) {
  //   // console.log("this is data BEfore  ::", product.selectedAttributes);


  //   // Transform selectedAttributes object into an array of objects
  //   let dataArray = Object.keys(product.selectedAttributes).map((attr) => {
  //     const { id, name, value, cat_id } = product.selectedAttributes[attr];
  //     return {
  //       id,
  //       name,
  //       value,
  //       cat_id,
  //       cat_name: attr
  //     };
  //   });
  //   // console.log("this is data After  ::", dataArray);
  //   // const stringifiedDataArray = JSON.stringify(dataArray);
  //   // setOutputData(stringifiedDataArray);
  // }
  // }, [product]);



  useEffect(() => {
    if (showQuantityInput) {
      const isOrderHistory = page && page === 'orderHistory';
      const currentOrder = isOrderHistory ? productOrder : addedProducts;
      const quantityData = currentOrder && currentOrder.length > 0 ? currentOrder.find((item) => item.productId === product.productId)?.quantity : 1;
      // const quantityData = addedProducts && addedProducts.length > 0 ? addedProducts.find((item) => item.productId === product.productId)?.quantity : 1;
      setQuantity(quantityData);
    }
    // eslint-disable-next-line
  }, [addedProducts, product, showQuantityInput, productOrder]);

  const handleMessageChange = (event) => {
    const value = event.target.value;
    const prevProduct = addedProducts.map(p =>
      p.productId === product.productId
        ? { ...p, product_instruction: value }
        : p
    );
    dispatch(setProductData({ company: company, product: prevProduct }));
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => {
      const selectedProduct = {
        ...product,
        quantity: prevQuantity
      };
      updateTotalQuantity(selectedProduct);
      return prevQuantity + 1;
    });
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => {
      const productQuantity = {
        ...product,
        quantity: prevQuantity
      };
      if (prevQuantity > 1) {
        decreaseTotalQuantity(productQuantity);
        return prevQuantity - 1;
      } else {
        setShowQuantityInput(false);
        decreaseTotalQuantity(productQuantity);
        return 1;
      }
    });
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const handleAddButtonClick = (e) => {
    e.stopPropagation();
    //changes by sakshi
    if (product.attribute_data) {
      handleProductClick();
    }
    else {
      setShowQuantityInput(true);
      const productData = {
        ...product,
        quantity: 1
      };
      updateTotalQuantity(productData);
    }
  };

  const handleReorderClick = (e) => {
    e.stopPropagation();
    // Handle reorder logic here
  };

  const handleProductClick = () => {
    if (onProductClick) {
      onProductClick(product);
    }
    return;
  };

  const handleChangeRadio = (event) => {
    const { value } = event.target;
    // setFormData(value);
    const prevProduct = addedProducts.map(p =>
      p.productId === product.productId
        ? { ...p, food_type_jain_regular: value }
        : p
    );
    dispatch(setProductData({ company: company, product: prevProduct }));
  };

  const currentRadioValue = addedProducts.find(p => p.productId === product.productId)?.food_type_jain_regular || '';
  const currentMessage = addedProducts.find(p => p.productId === product.productId)?.product_instruction || '';

  return (
    <>
      <div className={page && page=== 'reviewOrder' ? 'bg-for-order-review': ""}>
      <div className="product" onClick={handleProductClick}>
        <div className="row">
          <div className="col-6">
            <div className="product-detail">
              <h6><BiFoodTag className={product.veg_nonveg === '1' ? "nonveg-icon" : "veg-icon"} /> {product.name}</h6>
              {showBrandColor && (
                <>
                  {product.jain_or_not === '1' && product.jain_regular === '0' && (
                    <span className="jain-available">Jain option available</span>
                  )}
                  {product.jain_regular === '0' && product.jain_or_not !== '1' && (
                    <span className="jain-available">No onion/garlic option available</span>
                  )}
                  {product.jain_regular !== '0' && product.jain_or_not === '1' && (
                    <span className="jain-available">Jain option available</span>
                  )}
                </>
              )}

              {!showPrice && product?.price && product.price !== '' && (
                <span>₹{product.price}</span>
              )}

              {!hideImageAndAddButton && <p>{product.description && typeof product.description === 'string' ? new DOMParser().parseFromString(product.description, 'text/html').body.innerText : product.description}</p>}

              {date && <p className="order-date">Ordered on: {date}</p>}
              {showReorderButtonInsteadOfQuantity ? (
                <button onClick={handleReorderClick} className="reorder-button">
                  <IoReload className="reload-icon" /> Reorder
                </button>
              ) : showQuantityInput && !hideImageAndAddButton ? (
                <div className="quantity-input" onClick={(e) => e.stopPropagation()}>
                  <button onClick={handleDecrease} className={propsBrandId && !brand_code ? "quantity-brand-button" : "quantity-button"}>
                    <span>-</span>
                  </button>
                  <input
                    type="text"
                    value={quantity}
                    onChange={handleChange}
                    disabled={true}
                    min="1"
                    className="quantity-field"
                  />
                  <button onClick={handleIncrease} className={propsBrandId && !brand_code ? "quantity-brand-button" : "quantity-button"}>  
                    <span>+</span>
                    </button>
                </div>
              ) : (
                !hideImageAndAddButton && (
                  <button onClick={handleAddButtonClick} className={propsBrandId && !brand_code ? "add-brand-color" : "add-button"}>Add</button>
                )
              )}


              {product?.selectedAttributes && (
                <div className='attr-container'>
                  {
                    Object.keys(product?.selectedAttributes || {}).map((cat_name, index) => {
                      const attrArray = product?.selectedAttributes[cat_name];

                      if (attrArray && attrArray.length === 1) {
                        return (
                          <div key={index}>
                            {attrArray && attrArray.length > 0 && attrArray.map((data, idx) => (
                              <div key={idx}>
                                <p className='cat-name'>
                                  {cat_name} - {data.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      else {
                        return (
                          <div key={index} className="multi-options-container">
                            <p className='cat-name'>
                              {cat_name} -
                            </p>
                            <div className="multi-options">
                              {attrArray && attrArray.length > 0 && attrArray.map((data, idx) => (
                                <p className='cat-name' key={idx}>
                                  {data.name}
                                  {idx < attrArray.length - 1 ? ',   ' : ''}
                                </p>
                              ))}
                            </div>
                          </div>

                        );
                      }
                    })
                  }

                </div>
              )}

              {showPrice && product?.price && product.price !== '' && product?.quantity && product.quantity !== '' && <span>₹{Number(product.price) * Number(product.quantity)}</span>}

            </div>
          </div>
          <div className="col-6">
            {showQuantityInput && hideImageAndAddButton && !showReorderButtonInsteadOfQuantity && (
              <div className="quantity-input" onClick={(e) => e.stopPropagation()}>
                <button onClick={handleDecrease} className={showBrandColor ? "quantity-button" : 'no-quantity-button'}><span>-</span></button>
                <input
                  type="text"
                  value={quantity}
                  onChange={handleChange}
                  disabled={true}
                  min="1"
                  className="quantity-field"
                />
                <button onClick={handleIncrease} className={showBrandColor ? "quantity-button" : 'no-quantity-button'}><span>+</span></button>
              </div>
            )}
            {showReorderButton && !showReorderButtonInsteadOfQuantity && (
              <button onClick={handleReorderClick} className="reorder-button">
                <IoReload className="reload-icon" /> Reorder
              </button>
            )}
            {!hideImageAndAddButton && (
              <div className="product-image">
                <img src={imageData} alt={product.name} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showRadio && (product?.jain_or_not === '1' || product?.jain_regular === '0') && product?.veg_nonveg !== '1' && (
        <div>
          <div className="radio-btn">
            <label className={`radio-label ${currentRadioValue === '2' ? 'active' : ''}`}>
              <input
                type="radio"
                name={`Regular-${product.productId}`}
                value="2"
                checked={currentRadioValue === '2'}
                onChange={handleChangeRadio}
              />
              &nbsp;<BiDish />Regular
            </label>
            {product?.jain_or_not === '1' && (
              <label className={`radio-label ${currentRadioValue === '1' ? 'active' : ''}`}>
                <input
                  type="radio"
                  name={`Jain-${product.productId}`}
                  value="1"
                  checked={currentRadioValue === '1'}
                  onChange={handleChangeRadio}
                />
                &nbsp;<img src={Jain} alt="onion garli" />Jain
              </label>
            )}
            {product?.jain_regular === '0' && (
              <label className={`radio-label ${currentRadioValue === '0' ? 'active' : ''}`}>
                <input
                  type="radio"
                  name={`No Onion/Garlic-${product.productId}`}
                  value="0"
                  checked={currentRadioValue === '0'}
                  onChange={handleChangeRadio}
                />
                &nbsp;<img src={onion} alt="onion garlic" />No Onion/Garlic
              </label>
            )}
          </div>
        </div>
      )}
      {showMessageInput && (
        <div className="message-input">
          <textarea
            value={currentMessage}
            onChange={handleMessageChange}
            placeholder="Would you like to add instruction to chef 🧐?"
          />
        </div>
      )}
      </div>
    </>
  );
}