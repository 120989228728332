import React, { useState } from 'react';
import "../OTP/OTP.scss"
import Profile from "../../assets/images/PMS-TEA.png";
import { useSelector } from 'react-redux';

export default function OTP() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const companyDetail = useSelector(state => state.company?.company_detail);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSendOtp = () => {
    // Logic to send OTP goes here
    setOtpRequested(true);
    alert(`OTP sent to ${phoneNumber}`);
  };

  const handleVerifyOtp = () => {
    // Logic to verify OTP goes here
    alert(`OTP entered: ${otp}`);
  };

  return (
    <>
    <div className="msg-pms">
    <div className="profile-img">
        <img src={companyDetail?.company_logo ? `data:image/jpeg;base64,${companyDetail?.company_logo}` : Profile} alt='Product' />
      </div>
      <div className="otp-detail">
      {!otpRequested ? (
        <>
          <p>Please enter your phone number</p>
          <input 
            type="text" 
            value={phoneNumber} 
            onChange={handlePhoneNumberChange} 
            placeholder="Enter phone number" 
          />
          <button onClick={handleSendOtp}>Send OTP</button>
        </>
      ) : (
        <>
          <p>Please enter the OTP sent to your phone</p>
          <input 
            type="text" 
            value={otp} 
            onChange={handleOtpChange} 
            placeholder="Enter OTP" 
          />
          <button onClick={handleVerifyOtp}>Verify OTP</button>
        </>
      )}
    </div>
    </div>
    </>
    
  );
}
