import { toast } from "react-toastify";
import { ValidationMessages } from "../../Utils/Utils";
import { clearLoader, setLoader } from "../../Redux/Actions/LoaderAction/LoaderAction";

export const getAddressFromPincode = async (pincode, dispatch) => {
    try {
        dispatch(setLoader(true));
        const response = await fetch('https://api.postalpincode.in/pincode/'+pincode, { method: 'GET' });
        if (!response.ok) {
          const data = await response.json();
          return data;
        }
        const data = await response.json();
        return data;
      } catch (error) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      } finally {
        dispatch(clearLoader());
      }
}