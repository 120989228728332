const LocationInitialState = {
    location: true
};

/**
 * Store leaf level category filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const LocationReducer = (state = LocationInitialState, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            return {
                ...state,
                location: action.payload.location
            }
        default:
            return state;
      };
}

const LocationMessageInitialState = {
    location: false
};

/**
 * Store leaf level category filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const LocationMessageReducer = (state = LocationMessageInitialState, action) => {
    switch (action.type) {
        case 'SET_LOCATION_MESSAGE':
            return {
                ...state,
                location: action.payload.location
            }
        default:
            return state;
      };
}