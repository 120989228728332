// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.order-history .order-header {
  background-color: #FFF;
  padding: 15px 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 991px;
  margin: 0 auto;
}
.order-history .order-header .order-header-detail {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.order-history .order-header .order-header-detail a {
  display: flex;
}
.order-history .order-header .order-header-detail h6 {
  margin: 0;
  font-size: 18px;
  color: #e25233;
  font-weight: bold;
}
.order-history .order-header .order-header-detail svg {
  font-size: 20px;
  color: #e25233;
  margin-right: 10px;
}

.history-product {
  margin-top: 50px;
  margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/pages/OrderHistory/OrderHistory.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAAI;EACI,sBDFK;ECGL,eAAA;EACA,wCAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;AAGR;AADQ;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AAGZ;AADY;EACI,aAAA;AAGhB;AAAY;EACI,SAAA;EACA,eAAA;EAEA,cDbA;ECcA,iBAAA;AAChB;AAEY;EACI,eAAA;EAEA,cDpBA;ECqBA,kBAAA;AADhB;;AAOA;EACI,gBAAA;EACA,mBAAA;AAJJ","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import\"../../variables\";\n\n.order-history {\n    .order-header {\n        background-color: $white-color;\n        padding: 15px 0;\n        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);\n        width: 100%;\n        max-width: 991px;\n        margin: 0 auto;\n\n        .order-header-detail {\n            display: flex;\n            align-items: center;\n            padding: 0 10px;\n\n            a {\n                display: flex;\n            }\n\n            h6 {\n                margin: 0;\n                font-size: 18px;\n                // color: var(--main-color);\n                color: $bespeakk-color;\n                font-weight: bold;\n            }\n\n            svg {\n                font-size: 20px;\n                //    color: var(--main-color);\n                color: $bespeakk-color;\n                margin-right: 10px;\n            }\n        }\n    }\n}\n\n.history-product {\n    margin-top: 50px;\n    margin-bottom: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
