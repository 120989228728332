/**
 * Set cltegory list data into reducer
 * @param {*} category 
 * @returns {*}
 */
export const getCategoryData = (category) => ({
    type: 'GET_CATEGORY_DATA',
    payload: category
})

/**
 * Clear category list data
 * @returns {*}
 */
export const clearCategoryData = () => ({
    type: 'CLEAR_CATEGORY_DATA'
})

/**
 * Set total category list data into reducer (No pagination) 
 * @param {*} category 
 * @returns {*}
 */
export const getTotalCategoryData = (category) => ({
    type: 'GET_TOTAL_CATEGORY',
    payload: category
})

/**
 * Clear total category list data (No pagination)
 * @returns {*}
 */
export const cleatTotalCategory = () => ({
    type: 'CLEAR_TOTAL_CATEGORY'
})