import { Dispatch } from "redux";
import { getApiService } from "../../../../Services/API-services/GetService";
import { API_URL, ValidationMessages, removePersistStorage, toastClass } from "../../../../Utils/Utils";
import { getAllSubCategoryRequest, getAllSubCategoryResponse } from "./SubCategoryModal";
import { clearLoader, setLoader } from "../../../../Redux/Actions/LoaderAction/LoaderAction";
import { toast } from "react-toastify";
import { getSubCategoryData } from "../../../../Redux/Actions/subCategoryAction/SubCategoryAction";

let errorShow = false;

/*
 * Service to get sub category list
*/
export const getAllSubCategoryService = (
    subCategoryListRequest: getAllSubCategoryRequest, dispatch: Dispatch, total?: any
): Promise<getAllSubCategoryResponse> => {
    return new Promise<getAllSubCategoryResponse>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            let queryParams = '';
            // Adding query parameters if they are provided
            if (subCategoryListRequest.p_skip !== undefined && subCategoryListRequest.p_skip !== null) {
                queryParams += `p_skip=${subCategoryListRequest.p_skip}&`;
            }
            if (subCategoryListRequest.p_take !== undefined && subCategoryListRequest.p_take !== null) {
                queryParams += `p_take=${subCategoryListRequest.p_take}&`;
            }
            if (subCategoryListRequest.p_company_id !== undefined && subCategoryListRequest.p_company_id !== null) {
                queryParams += `p_company_id=${subCategoryListRequest.p_company_id}&`
            }
            if (subCategoryListRequest.p_name !== undefined && subCategoryListRequest.p_name !== null) {
                queryParams += `p_name=${subCategoryListRequest.p_name}&`;
            }
            if (subCategoryListRequest.p_categoryIds !== undefined && subCategoryListRequest.p_categoryIds !== null) {
                queryParams += `p_categoryIds=${subCategoryListRequest.p_categoryIds}&`;
            }
            if (subCategoryListRequest.p_category_code !== undefined && subCategoryListRequest.p_category_code !== null) {
                queryParams += `p_category_code=${subCategoryListRequest.p_category_code}&`;
            }
            if (subCategoryListRequest.p_brand_id !== undefined && subCategoryListRequest.p_brand_id !== null) {
                queryParams += `p_brand_id=${subCategoryListRequest.p_brand_id}&`;
            }
            if (subCategoryListRequest.p_type !== undefined && subCategoryListRequest.p_type !== null) {
                queryParams += `p_type=${subCategoryListRequest.p_type}&`;
            }
            if (subCategoryListRequest.p_is_active !== undefined && subCategoryListRequest.p_is_active !== null) {
                queryParams += `p_is_active=${subCategoryListRequest.p_is_active}&`
            }
            if (subCategoryListRequest.p_isDeleted !== undefined && subCategoryListRequest.p_isDeleted !== null) {
                queryParams += `p_isDeleted=${subCategoryListRequest.p_isDeleted}&`
            }
            const response = await getApiService(API_URL.BASE_URL + API_URL.SUB_CATEGORY + (queryParams && queryParams !== '' ? `?${queryParams}` : ''));
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                // if (total && total === true) {
                //   dispatch(getTotalSubCategoryData(response));
                // }
                // else 
                if (total && total === 'filter') {
                    toast.success('Filter success!');
                    dispatch(getSubCategoryData(response));
                }
                else {
                    dispatch(getSubCategoryData(response));
                }
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Sub category permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};