import React, { useEffect, useState } from 'react'
import "../Product/Product.scss"
import { parseImage } from '../../Services/Regular-services/ImageService';
import { API_URL } from '../../Utils/Utils';
export default function SquareProduct(props) {

  const { productData, handleAddClick, showBrandColor = true, onProductClick } = props 

  const [imageData, setImageData] = useState('');

  useEffect(() => {
    if(productData && productData.image_data) {
      const data = parseImage(productData.image_data);
      const newImageData = API_URL.TOKEN_URL+data[0].url
      setImageData(newImageData);
    }
  }, [productData])

  const truncateDescription = (description) => {
    const parsedDescription = new DOMParser().parseFromString(description, 'text/html').body.innerText;
    const words = parsedDescription.split(' ');
    return words.length > 3 ? words.slice(0, 3).join(' ') + '...' : parsedDescription;
  };

  const handleProductClick = () => {
    if (onProductClick) {
      onProductClick(productData);
    }
    return;
  };

  const handleAddButtonClick = (e) => {
    e.stopPropagation();
    if (productData.attribute_data) {
      handleProductClick();
    }
    else {
      handleAddClick(productData)
    }
  };
  
  return (
    <>
      <div className="new-product-card">
        <div onClick={handleProductClick}>
        <div className="new-product-img" >
          <img src={imageData} alt={productData.name} />
        </div>
        <div className="new-product-detail">
          <h6>{productData.name}</h6>
          <p>{productData.description && typeof productData.description === 'string' ? truncateDescription(productData.description) : productData.description}</p>
          <span>₹{productData.price}</span>
        </div>
        </div>

        <button className={showBrandColor ? "add-button" : "no-brand-add-color"} onClick={(e) => handleAddButtonClick(e)}>Add</button>
      </div>
    </>
  )
}