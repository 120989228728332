import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import NormalInput from "../../components/Input/NormalInput";
import "../Profile/Profile.scss";
import Select from "react-select";
import Profileimg from "../../assets/images/man.png";
import { RiSaveFill } from "react-icons/ri";

const Profile = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptions3, setSelectedOptions3] = useState([]);

  const optionsGender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const options = [
    { value: "Veg ", label: "Veg " },
    { value: "Non veg", label: "Non veg" },
  ];
  const optionsFood = [
    { value: "Misal Pav ", label: "Misal Pav " },
    { value: "Makki di Roti and Sarson da Saag", label: "Makki di Roti and Sarson da Saag" },
    { value: "Kosha Mangsho", label: "Kosha Mangsho" },
    { value: "Dhokla ", label: "Dhokla " },
  ];
  const optionsIngredients = [
    { value: "Misal Pav ", label: "Misal Pav " },
    { value: "Makki di Roti and Sarson da Saag", label: "Makki di Roti and Sarson da Saag" },
    { value: "Kosha Mangsho", label: "Kosha Mangsho" },
    { value: "Dhokla ", label: "Dhokla " },
  ];

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const handleChange1 = (selectedOptions) => {
    setSelectedOptions1(selectedOptions);
  };
  const handleChange2 = (selectedOptions) => {
    setSelectedOptions2(selectedOptions);
  };
  const handleChange3 = (selectedOptions) => {
    setSelectedOptions3(selectedOptions);
  };

  const handleSave = () => {
    // Implement your save functionality here
    console.log("Gender:", selectedOptions);
    console.log("Food preference:", selectedOptions1);
    console.log("Favourite cuisines:", selectedOptions2);
    console.log("Favourite Ingredients:", selectedOptions3);
    alert("Profile saved!");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
  };

  return (
    <div className="profile">
      <div className="order-header fixed-header">
        <div className="row">
          <div className="header-save-btn">
          <div className="order-header-detail">
            <Link to={`/`}>
              <FaChevronLeft />
            </Link>
            <h6>Profile</h6>
          </div>
          <div className="save-btn-icon">
            <RiSaveFill onClick={handleSave}/>
          </div>
          </div>
        </div>
      </div>
      <div className="profile-detail">
        <div className="container">
            <div className="profile-detail-input">
              <div className="row">
              <div className="col-12">
              <div className="profile-icon">
                <img src={Profileimg} alt='Product' />
              </div>
            </div>
              <div className="col-6">
              <NormalInput
                type="text"
                name="first name"
                placeholder="Enter your first name"
                label="First name"
              />
            </div>
            <div className="col-6">
              <NormalInput
                type="text"
                name="last name"
                placeholder="Enter your last name"
                label="Last name"
              />
            </div>
            <div className="col-12 mt-3">
              <NormalInput
                type="email"
                name="Email"
                placeholder="Enter your Email"
                label="Email"
              />
            </div>
            <div className="col-12 mt-3">
              <NormalInput
                type="text"
                name="Mobile Number"
                placeholder="Enter your Mobile Number"
                label="Mobile Number"
              />
            </div>
            <div className="col-12 mt-3">
              <label>Gender</label>
              <Select
                value={selectedOptions}
                onChange={handleChange}
                options={optionsGender}
                placeholder="Select your gender"
                styles={customStyles}
              />
            </div>
            <div className="col-12 mt-3">
              <label>Food preference</label>
              <Select
                value={selectedOptions1}
                onChange={handleChange1}
                options={options}
                isMulti
                placeholder="Select Food preference"
                styles={customStyles}
              />
            </div>
            <div className="col-12 mt-3">
              <label>Favourite cuisines</label>
              <Select
                value={selectedOptions2}
                onChange={handleChange2}
                options={optionsFood}
                isMulti
                placeholder="Select Favourite cuisines"
                styles={customStyles}
              />
            </div>
            <div className="col-12 mt-3">
              <label>Favourite Ingredients</label>
              <Select
                value={selectedOptions3}
                onChange={handleChange3}
                options={optionsIngredients}
                isMulti
                placeholder="Select Favourite Ingredients"
                styles={customStyles}
              />
            </div>
            {/* <div className="col-12 mt-3">
              <button className="save-btn" onClick={handleSave}>
                Save
              </button>
            </div> */}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
