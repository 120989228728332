
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import { store } from '../Redux/Store';
import { logout } from '../Redux/Actions/authAction/AuthAction';
import { clearProductData } from '../Redux/Actions/ProductAction/ProductAction';
import { toast } from 'react-toastify';
import { clearCompany } from '../Redux/Actions/CompantAction';
import { clearBranch } from '../Redux/Actions/BranchAction';
import { clearBrand } from '../Redux/Actions/BrandAction';
import { useSelector } from 'react-redux';

export const ValidationMessages = {
    FIRST_NAME: 'First name is required!',
    LAST_NAME: 'Last name is required!',
    EMAIL: 'Email is required!',
    TABLE_NUMBER: 'Table number is required!',
    PHONE: 'Phone number is required!',
    FIELD_REQUIRED: 'This field is required',
    MAX_EMAIL_LIMIT: 'Email must be at most 64 characters!',
    INVALID_EMAIL: 'Email is invalid!',
    SOMETHING_WENT_WRONG: 'Something went wrong!',
    ADDRESS_LINE_1: 'Address line 1 is required!',
    ADDRESS_LINE_2: 'Address line 2 is required!',
    ADDRESS: 'Address is required',
    LANDMARK: 'Landmark is required',
    CITY: 'City is required!',
    PIN_CODE: 'Pin code is required!',
    TITLE: 'Title is required!'
}

export const Regex = {
    EmailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PasswordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s]).{8,15}$/
}

export const toastClass = '.Toastify__toast-body';

export const API_URL = {
    BASE_URL: process.env.REACT_APP_API_URL + 'api/',
    TOKEN_URL: process.env.REACT_APP_API_URL,
    PRODUCT: 'client/product',
    CATEGORY: 'client/categories',
    SUB_CATEGORY: 'client/subCategory',
    LOGIN: 'client/register',
    VERIFY_OTP: 'client/verify-otp',
    PLACE_ORDER: 'client/orders',
    ORDER_LIST: 'client/orders',
    USER_DETAIL: 'client/user',
    COMPANY: 'company/client',
    BRANDS: 'client/brands',
    UPDATE_ORDER: 'client/orders/update',
    ADDRESS: 'client/address',
    DISCOUNT: 'discount/client'
}

/**
 * Remove total persist storage
 * @param {*} dispatch 
 */
export const removePersistStorage = async (dispatch) => {
    await persistStore(store, { storage }).purge();
    dispatch(clearProductData());
    dispatch(logout());
    toast.success('Successfully logged out!');
}

/**
 * Remove total persist storage
 * @param {*} dispatch 
 */
export const removePersistLoginStorage = async (dispatch) => {
    await persistStore(store, { storage }).purge();
    dispatch(logout());
}

/**
 * Remove product count persist storage
 * @param {*} dispatch 
 */
export const removePersistProductStorage = async (dispatch) => {
    await persistStore(store, { storage }).purge();
    dispatch(clearProductData());
}

/**
 * Remove product count persist storage
 * @param {*} dispatch 
 */
export const removePersistCompanyBranchStorage = async (dispatch) => {
    await persistStore(store, { storage }).purge();
    dispatch(clearProductData());
    dispatch(clearCompany());
    dispatch(clearBranch());
    dispatch(clearBrand());
}

export default function UtilsData() {
    const authData = useSelector(state => state.auth);
    return authData?.user?.token ? authData.user.token : null;
}