import { combineReducers } from 'redux';
import { MenuProductAPIReducer, ProductOrderReducer, ProductReducer, productAPIReducer } from './Reducers/ProductReducer/ProductReducer';
import { LoaderReducer } from './Reducers/LoaderReducer/LoaderReducer';
import { productFilterReducer } from './Reducers/ProductReducer/ProductFilterReducer';
import { CategoryReducer } from './Reducers/categoryReducer/CategoryReducer';
import { categoryFilterReducer } from './Reducers/categoryReducer/CategoryFilterReducer';
import { CategoryPageReducer } from './Reducers/categoryReducer/CategoryPageReducer';
import { SubCategoryReducer } from './Reducers/subCategoryReducer/SubCategoryReducer';
import { subCategoryFilterReducer } from './Reducers/subCategoryReducer/SubCategoryFilterReducer';
import { SubCategoryPageReducer } from './Reducers/subCategoryReducer/SubCategoryPageReducer';
import AuthReducer from './Reducers/authReducer/AuthReducer';
import { TotalProductReducer } from './Reducers/ProductReducer/TotalProductReducer';
import { CompanyReducer } from './Reducers/CompanyReducer';
import { BranchReducer } from './Reducers/BranchReducer';
import { TableReducer } from './Reducers/TableReducer';
import { LocationMessageReducer, LocationReducer } from './Reducers/Location/LocationReducer';
import { BrandClickReducer, BrandIdClickReducer, BrandReducer } from './Reducers/BrandReducer';
import { AddressReducer } from './Reducers/AddressReducer/AddressReducer';
import { DiscountByCodeReducer } from './Reducers/DiscountReducer/DiscountReducer';

const rootReducer = combineReducers({

  auth: AuthReducer,

  product: ProductReducer,
  productAPI: productAPIReducer,
  productFilter: productFilterReducer,
  totalProduct: TotalProductReducer,
  productOrder: ProductOrderReducer,

  category: CategoryReducer,
  categoryFilter: categoryFilterReducer,
  categoryPage: CategoryPageReducer,

  subCategory: SubCategoryReducer,
  subCategoryFilter: subCategoryFilterReducer,
  subCategoryPage: SubCategoryPageReducer,

  loader: LoaderReducer,

  company: CompanyReducer,
  branch: BranchReducer,
  table: TableReducer,

  location: LocationReducer,
  locationMessage: LocationMessageReducer,

  menuProduct: MenuProductAPIReducer,

  brand: BrandReducer,
  brandClick: BrandClickReducer,
  brandIdClick: BrandIdClickReducer,

  address: AddressReducer,
  discountByCode: DiscountByCodeReducer
});

export default rootReducer;