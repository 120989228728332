// export const parseImage = (dataStr) => {
//      const pairs = dataStr.replace("[{", "").replace("}]", "").split("},{");
//      const jsonArray = [];
//      pairs.forEach(pair => {
//        const components = pair.split(",");
//        const jsonObject = {};
//        components.forEach(component => {
//          const [key, value] = component.split(":");
//          jsonObject[key.trim().replace(/\"/g, '')] = value.trim().replace(/\"$/, '');
//        });
//        jsonArray.push(jsonObject);
//      });
//      return jsonArray ? jsonArray : [];
// }
export const parseImage = (dataStr) => {
  const pairs = dataStr.replace("[{", "").replace("}]", "").split("},{");
  const jsonArray = [];
  pairs.forEach(pair => {
    const components = pair.split(",");
    const jsonObject = {};
    components.forEach(component => {
      const [key, value] = component.split(":");
      if (key && value) {
        jsonObject[key.trim().replace(/\"/g, '')] = value.trim().replace(/\"$/, '');
      }
     //  jsonObject[key.trim().replace(/\"/g, '')] = value.trim().replace(/\"$/, '');
    });
    jsonArray.push(jsonObject);
  });
  return jsonArray ? jsonArray : [];
}