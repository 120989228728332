import React, { useState } from 'react';
import NormalInput from '../Input/NormalInput';
import { IoSend } from 'react-icons/io5';
import "../Chat/Chat.scss";
import Product from '../Product/Product';

const productData = [
  {
    id: 1,
    name: 'Apna burger',
    price: '50',
    category: 'Sasta Burger',
    description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora provident, consequatur reprehenderit delectus facilis libero est, eos impedit enim culpa eveniet accusamus. Necessitatibus minima, error voluptate vel minus placeat in!'
  },
  {
    id: 2,
    name: 'Apna pizza',
    price: '150',
    category: 'Sasta Pizza',
    description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora provident, consequatur reprehenderit delectus facilis libero est, eos impedit enim culpa eveniet accusamus. Necessitatibus minima, error voluptate vel minus placeat in!'
  },
  {
    id: 3,
    name: 'Apna sandwich',
    price: '55',
    category: 'Sand wich',
    description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora provident, consequatur reprehenderit delectus facilis libero est, eos impedit enim culpa eveniet accusamus. Necessitatibus minima, error voluptate vel minus placeat in!'
  },
  {
    id: 4,
    name: 'Apna pasta',
    price: '75',
    category: 'Sasta Pasta',
    description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora provident, consequatur reprehenderit delectus facilis libero est, eos impedit enim culpa eveniet accusamus. Necessitatibus minima, error voluptate vel minus placeat in!'
  }
];

export default function Chat() {
  const [isSearchHistoryVisible, setIsSearchHistoryVisible] = useState(false);

  const handleInputClick = () => {
    setIsSearchHistoryVisible(!isSearchHistoryVisible);
  };

  return (
    <>
      <div className="chat">
        <div className="chat-bar">
          <NormalInput type="text" name="Chat Here" placeholder="Order Now" onClick={handleInputClick} />
          <button className="send-btn">
            <IoSend />
          </button>
        </div>

        {isSearchHistoryVisible && (
          <div className="search-history">
            {productData.map((product, index) => (
              <Product
                product={product}
                key={index}
                hideImageAndAddButton={true}
                quantityData={true}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
