const branchInitialState = { 
    items: '',
    branch_code: '',
    branch_detail: {}
};

/**
 * Store sub-category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const BranchReducer = (state = branchInitialState, action) => {
    switch (action.type) {
            case 'GET_BRANCH_DATA':
                return {
                    ...state,
                    items: action.payload.items,
                    branch_code: action.payload.code,
                    branch_detail: action.payload.branch_detail
                }
            case 'CLEAR_BRANCH_DATA':
                return branchInitialState;
        default:
            return state;
      };
}