const companyInitialState = { 
    items: '',
    company_code: '',
    company_detail: {}
};

/**
 * Store sub-category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const CompanyReducer = (state = companyInitialState, action) => {
    switch (action.type) {
            case 'GET_COMPANY_DATA':
                return {
                    ...state,
                    items: action.payload.items,
                    company_code: action.payload.code,
                    company_detail: action.payload.company_detail
                }
            case 'CLEAR_COMPANY_DATA':
                return companyInitialState;
        default:
            return state;
      };
}