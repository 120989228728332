// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.pms-msg {
  background-color: #EF9C66;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 0 10px 10px 10px;
  margin: 10px 0;
  width: 100%;
}
.pms-msg h6 {
  font-size: 14px;
  margin: 0;
  color: #FFF;
}
.pms-msg p {
  font-size: 13px;
  color: #FFF;
}

.order-btn-place a {
  padding: 6px 20px;
  background-color: var(--primary-color);
  margin-right: 10px;
  border-radius: 5px;
  color: #FFF;
  font-size: 14px;
}
.order-btn-place .no-btn {
  background-color: #FFF;
  color: #000;
}

.profile-img {
  border-radius: 50%;
}
.profile-img img {
  width: 50px;
  height: 50px;
}

.msg-pms {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/PmsMaggese/PmsMaggese.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAFA;EACI,yBDOS;ECNT,sBAAA;EACA,aAAA;EACA,+BAAA;EACA,cAAA;EACA,WAAA;AAKJ;AAJI;EACI,eAAA;EACA,SAAA;EACA,WDTK;ACeb;AAJI;EACI,eAAA;EACA,WDbK;ACmBb;;AAFI;EACI,iBAAA;EACA,sCAAA;EACA,kBAAA;EACA,kBAAA;EACA,WDtBK;ECuBL,eAAA;AAKR;AAHI;EACI,sBD1BK;EC2BL,WD1BK;AC+Bb;;AAFA;EACI,kBAAA;AAKJ;AAJI;EACI,WAAA;EACA,YAAA;AAMR;;AAHA;EACI,aAAA;AAMJ","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import\"../../variables\";\n.pms-msg{\n    background-color: $pms-chat-bg;\n    border: 1px solid #ccc;\n    padding: 10px;\n    border-radius: 0 10px 10px 10px;\n    margin: 10px 0;\n    width: 100%;\n    h6{\n        font-size: 14px;\n        margin: 0;\n        color: $white-color;\n    }\n    p{\n        font-size: 13px;\n        color: $white-color;\n    }\n}\n.order-btn-place{\n    a{\n        padding: 6px 20px;\n        background-color: var(--primary-color);\n        margin-right: 10px;\n        border-radius: 5px;\n        color: $white-color;\n        font-size: 14px;\n    }\n    .no-btn{\n        background-color: $white-color;\n        color: $black-color;\n    }\n}\n.profile-img{\n    border-radius: 50%;\n    img{\n        width: 50px;\n        height: 50px;\n    }\n}\n.msg-pms{\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
