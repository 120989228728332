import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import "../ReviewOrder/ReviewOrder.scss";
import Product from "../../components/Product/Product";
import SquareProduct from "../../components/Product/SquareProduct";
import { Link, useNavigate } from "react-router-dom";
import { setProductData } from "../../Redux/Actions/ProductAction/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  API_URL,
  ValidationMessages,
  removePersistLoginStorage,
  toastClass,
} from "../../Utils/Utils";
import { toast } from "react-toastify";
import NormalInput from "../../components/Input/NormalInput";
import { parseImage } from "../../Services/Regular-services/ImageService";
import { loginAuth } from "./OrderAndLoginService/LoginService/LoginService.tsx";
import { verifyOtp } from "./OrderAndLoginService/VerifyOtpService/VerifyOtpService.tsx";
import { placeOrderService } from "./OrderAndLoginService/OrderService/PlaceOrderService/PlaceOrderService.tsx";
import { login } from "../../Redux/Actions/authAction/AuthAction.js";
import { getAllProductService } from "../../components/Product/ProductService/ProductService.tsx";
import DetailContainer from "../../components/Detail-container/DetailContainer.js";
import LoginPopup from "../../components/LoginPopup/LoginPopup.js";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { TbPaperBag, TbTruckDelivery } from "react-icons/tb";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import {
  addAddressService,
  getAddressListService,
  updateAddressService,
} from "./AddressService/AddressService";
import ConfettiCannon from "react-confetti";
import AddressList from "../../components/AddressList/AddressList";
import DiscountPopup from "../../components/DiscountPopup/DiscountPopup";
import { postDiscountByCodeService } from "./DiscountService/DiscountService";
import { clearDiscountDataByCode } from "../../Redux/Actions/DiscountAction/DiscountAction";
import { RiDiscountPercentFill } from "react-icons/ri";

const validationSchema = Yup.object({
  firstName: Yup.string().required(ValidationMessages.FIRST_NAME),
  number: Yup.string().min(10).required(ValidationMessages.PHONE),
});

export default function ReviewOrder() {
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const [otpButtonVisible, setOtpButtonVisible] = useState(false);
  const userAuthData = useSelector((state) => state.auth?.user);
  const [loginData, setLoginData] = useState(null);
  const [emptyCart, setEmptyCart] = useState(true);
  const loader = useSelector((state) => state.loader?.items);
  const productData = useSelector((state) => state.totalProduct);
  const [filterAddedProduct, setFilterAddedProduct] = useState([]);
  const company = useSelector((state) => state.company?.items);
  const table = useSelector((state) => state.table?.tableNo);
  const branchId = useSelector((state) => state.branch?.items);
  const [message, setMessage] = useState("");
  const brandDetail = useSelector((state) => state.brand?.brand_detail);
  const address = useSelector((state) => state.address?.items);
  const discountByCode = useSelector((state) => state.discountByCode?.items);

  const addedProducts = useSelector((state) => state.product?.items);

  const [discountCode, setDiscountCode] = useState("");

  const [discountData, setDiscountData] = useState(null);

  const [discountedAmount, setDiscountedAmount] = useState(0);

  useEffect(() => {
    if (discountByCode && discountByCode?.id) {
      setDiscountData(discountByCode);
      setDiscountCode(discountCode.code);
    } else {
      setDiscountData(null);
      setDiscountCode("");
    }
    // eslint-disable-next-line
  }, [discountByCode]);

  const [addressValidationSchema, setAddressValidationSchema] = useState(
    Yup.object({
      title: Yup.string().required(ValidationMessages.TITLE),
      address1: Yup.string().required(ValidationMessages.ADDRESS),
      address2: Yup.string().required(ValidationMessages.LANDMARK),
      city: Yup.string().required(ValidationMessages.CITY),
      pin_code: Yup.string().required(ValidationMessages.PIN_CODE),
    })
  );

  const [editAddressData, setEditAddressData] = useState(null);
  const [selectedAddressData, setSelectedAddressData] = useState(null);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    number: "",
    otp: "",
  });

  const [addressInititalValues, setAddressInitialValues] = useState({
    title: "Home",
    address1: "",
    address2: "",
    city: "",
    pin_code: "",
    otherInput: "",
  });

  const [formData, setFormData] = useState({
    tableNo: "",
    dineIn: "",
    takeOut: "",
    delivery: "",
  });

  const [imageData, setImageData] = useState("");
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [discountBool, setDiscountBool] = useState(false);

  const [timer, setTimer] = useState(120); // 2 minutes in seconds
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [callPlaceOrder, setCallPlaceOrder] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [deliveryCharge, setDelivaryCharge] = useState(0);

  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const cannonRef = useRef(false);
  const [showConfetti, setShowConfetti] = useState(false);


  useEffect(() => {
    const total = addedProducts.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );
    const totalTax = addedProducts.reduce(
      (sum, product) =>
        sum +
        (product.price *
          product.quantity *
          ((product.brand_cgst ? Number(product.brand_cgst) : 0) +
            (product.brand_sgst ? Number(product.brand_sgst) : 0) +
            (product.brand_vat ? Number(product.brand_vast) : 0))) /
          100,
      0
    );

    const delivery =
      formData.delivery === "2"
        ? brandDetail?.distance_data && brandDetail.distance_data.length > 0
          ? Number(brandDetail.distance_data[0].value)
          : 0
        : 0;

    let discount = 0;

    if (discountData && discountData?.id && total) {
      if (
        discountData?.discount_type &&
        total >= discountData?.min_cart_value
      ) {
        if(discountData.discount_type === "1") {
          let discountCalc =
          (total * discountData.discount_amount) / 100 >
          discountData.max_discount_ammount
            ? discountData.max_discount_ammount
            : (total * discountData.discount_amount) / 100;
        discount = discountCalc;
        setDiscountBool(false);
        }
        else {
        discount = discountData.discount_amount;
        setDiscountBool(false);
        }
      } else {
        toast.warn(
          `Discount is only applicable for minimum amount of ₹${discountData?.min_cart_value}`
        );
        setDiscountBool(true);
        // dispatch(clearDiscountDataByCode());
      }
    } else {
      setDiscountBool(false);
    }

    setTotalAmount(total + totalTax + delivery - discount);
    setTotalTax(totalTax);
    setDelivaryCharge(delivery);
    setDiscountedAmount(discount);
    // eslint-disable-next-line
  }, [addedProducts, formData, discountData]);

  useEffect(() => {
    setShowConfetti(false);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsLinkActive(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [timer]);



  useEffect(() => {
    if (table && table !== "" && table !== "0" && table !== 0) {
      setFormData({
        tableNo: Number(table),
        dineIn: "0",
        takeOut: "",
        delivery: "",
      });
    } else {
      setFormData({
        tableNo: "",
        dineIn: "",
        takeOut:
          brandDetail?.delivery_available &&
          brandDetail?.delivery_available === "1"
            ? ""
            : "1",
        delivery:
          brandDetail?.delivery_available &&
          brandDetail?.delivery_available === "1"
            ? "2"
            : "",
      });
    }
    // eslint-disable-next-line
  }, [table]);

  const handleClick = (values) => {
    if (isLinkActive) {
      handleLogin(values);
      setTimer(120); // Reset the timer to 2 minutes
      setIsLinkActive(false);
    }
  };

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line
  }, []);

  /**
   * Fetch product data
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id ? brandDetail?.brand_id : null,
    };
    getAllProductService(requestBody, dispatch, true);
  };

  useEffect(() => {
    if (selectedProduct && selectedProduct.image_data) {
      const data = parseImage(selectedProduct.image_data);
      const newImageData = API_URL.TOKEN_URL + data[0].url;
      setImageData(newImageData);
    }
  }, [selectedProduct]);

  const [addressData, setAddressData] = useState("");

  useEffect(() => {
    if (
      productData &&
      productData.items &&
      productData.items.length > 0 &&
      productData.items[0].data &&
      productData.items[0].data.length > 0
    ) {
      if (addedProducts && addedProducts.length > 0) {
        const filteredProductData = productData.items[0].data.filter(
          (product) =>
            !addedProducts.some(
              (addedProduct) => addedProduct.productId === product.productId
            )
        );
        setFilterAddedProduct(filteredProductData);
      } else {
        setFilterAddedProduct(productData.items[0].data);
      }
    } else {
      setFilterAddedProduct([]);
    }
  }, [productData, addedProducts]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onCloseDeleteModal = () => {
    setDeleteAddress(false);
  };

  const handleShowModal = () => {
    setCallPlaceOrder(true);
    if (formData.dineIn === "0") {
      if (formData.tableNo === "" || formData.tableNo === null) {
        toast.warn("Please add table number!");
        return;
      }
    }
    setEmptyCart(false);
    setOtpButtonVisible(false);
    setShowModal(userAuthData?.token ? false : true);
    setInitialValues({
      firstName: "",
      lastName: "",
      number: "",
      otp: "",
    });

    if (userAuthData?.token) {
      if (!selectedAddressData && formData.delivery === "2") {
        toast.warn("Add an address for delivery!");
        return;
      }
      const transformedProductData = addedProducts.map((item) => {
        const attributeData = item.selectedAttributes
          ? JSON.stringify(
              Object.keys(item.selectedAttributes).flatMap((cat_name) =>
                item.selectedAttributes[cat_name].map((attr) => ({
                  id: attr.id,
                  name: attr.name,
                  value: attr.value,
                  cat_id: attr.cat_id,
                  cat_selection_type: attr.cat_selection_type,
                  cat_name: cat_name,
                }))
              )
            )
          : null;

        return {
          product_id: item.productId,
          brand_id: item.brand_id,
          brand_cgst: item.brand_cgst,
          brand_sgst: item.brand_sgst,
          brand_vat: item.brand_vat,
          product_size: item.size,
          quantity: item.quantity,
          product_price: item.price,
          product_purchase_price: item.purchase_price ? item.purchase_price : 0,
          product_instruction:
            item?.product_instruction && item.product_instruction !== ""
              ? item.product_instruction
              : null,
          food_type_one:
            item?.veg_nonveg && item.veg_nonveg !== "" ? item.veg_nonveg : "0",
          food_type_two:
            item?.food_type_jain_regular && item.food_type_jain_regular !== ""
              ? item.food_type_jain_regular
              : "2",
          attribute_data: attributeData, // changed by sakshi
        };
      });

      const groupedByBrandId = transformedProductData.reduce((acc, product) => {
        const { brand_id, brand_cgst, brand_sgst, product_price, quantity, brand_vat, ...productData } = product;
        let brandEntry = acc.find((entry) => entry.brand_id.toString() === brand_id.toString());
      
        let totalPriceForProduct = quantity * product_price;
        let totalTax = totalPriceForProduct * (Number(brand_cgst) + Number(brand_sgst) + Number(brand_vat)) / 100;
        let totalPriceWithTax = totalPriceForProduct + totalTax;
      
        if (!brandEntry) {
          brandEntry = {
            brand_id: brand_id.toString(),
            p_total_amount: 0,
            p_total_tax_amount: 0,
            p_delivery_charges: deliveryCharge && deliveryCharge !== "" && deliveryCharge > 0 ? deliveryCharge : 0,
            p_discount_id: discountData && discountData?.id ? discountData.id : 0,
            p_discount_code: discountCode && discountCode !== "" ? discountCode : '',
            p_discount_amount: discountedAmount && discountedAmount !== "" && discountedAmount > 0 ? discountedAmount : 0,
            product_data: []
          };
          acc.push(brandEntry);
        }
      
        brandEntry.product_data.push({ ...productData, quantity, product_price });
      
        brandEntry.p_total_amount += totalPriceWithTax;
        brandEntry.p_total_tax_amount += totalTax;
      
        return acc;
      }, []);
      
      // Apply delivery charges and discounts once per brand
      groupedByBrandId.forEach((brandEntry) => {
        if (brandEntry.p_delivery_charges) {
          brandEntry.p_total_amount += brandEntry.p_delivery_charges;
        }
        if (brandEntry.p_discount_amount) {
          brandEntry.p_total_amount -= brandEntry.p_discount_amount;
        }
      });
      
      const requestBody = {
        p_table_no: formData.tableNo,
        p_company_id: company,
        p_branch_id: branchId && branchId !== "" ? branchId.toString() : "",
        p_address_id: selectedAddressData?.addressId ? selectedAddressData.addressId : null,
        p_instruction: message,
        p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_orderData: groupedByBrandId,
        p_status: "0",
        p_type: formData.delivery === "2" ? "2" : formData.takeOut === "1" ? "1" : "0",
        p_payment: "0",
      };
      
      
      if (discountBool) {
        toast.warn(
          `Discount is only applicable for minimum amount of ₹${discountData?.min_cart_value}`
        );
        return;
      }
      placeOrderService(requestBody, userAuthData.token, dispatch).then(
        (res) => {
          if (
            res &&
            (res.status === 200 ||
              res.status === "200" ||
              res.status === "success")
          ) {
            // onPlay();
            dispatch(clearDiscountDataByCode());
            navigate("/thank-you");
            setCallPlaceOrder(false);
          } else {
            if (res.message === "Unauthorized access!") {
              setShowModal(true);
            }
          }
        }
      );
    }
  };

  /**
   * Fetch Address Data
   */
  const fetchAddress = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 4000,
    };
    getAddressListService(requestBody, userAuthData?.token, dispatch).then(
      (res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === "200" ||
            res.status === "success")
        ) {
          return;
        } else {
          if (res.message === "No token found in header.") {
            setCallPlaceOrder(false);
            setShowModal(true);
            // handleShowModal();
          }
        }
      }
    );
  };

  useEffect(() => {
    if (
      address &&
      address.length === 0 &&
      brandDetail &&
      brandDetail?.brand_id &&
      brandDetail?.delivery_available === "1"
    ) {
      fetchAddress();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      (!addedProducts || (addedProducts && addedProducts.length < 1)) &&
      emptyCart === true
    ) {
      if (!document.querySelector(toastClass)) {
        toast.warn("Your cart is empty!");
        dispatch(clearDiscountDataByCode());
        navigate("/");
        // navigate(`/menu/${companyCode}/${branchCode}/${table}`);
      }
    }
    // eslint-disable-next-line
  }, [addedProducts, emptyCart]);

  const handleCloseModal = () => {
    setOtpButtonVisible(false);
    setLoginData(null);
    setTimer(120); // Reset the timer to 2 minutes
    setIsLinkActive(false);
    setShowModal(false);
    setInitialValues({
      firstName: "",
      lastName: "",
      // email: '',
      number: "",
      otp: "",
    });
  };
  const navigate = useNavigate();

  const hideImageAndAddButton = true;

  /**
   * Update Table Number
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (/[^0-9\s]/g.test(value)) {
      console.log("Invalid data: ", value);
    } else {
      if (Number(value) <= 500) {
        setFormData((prevData) => ({ ...prevData, [name]: Number(value) }));
      }
    }
  };

  /**
   * Handle login
   */
  const handleLogin = (values) => {
    if (values.firstName === "" || values.number === "") {
      toast.error("Please fill the required data!");
      return;
    } else {
      loginAuth(values, dispatch).then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === "200" ||
            res.status === "success")
        ) {
          setLoginData(res);
          setOtpButtonVisible(true);
        }
      });
    }
  };

  const dispatch = useDispatch();

  /**
   * Increase Product Quantity
   * @param {*} product
   * @param {*} quantity
   */
  const updateTotalQuantity = (product, quantity) => {
    if (addedProducts && addedProducts.length > 0) {
      const selectData = addedProducts.find(
        (item) => item.productId === product.productId
      );
      if (selectData) {
        if (quantity) {
          const prevProduct = addedProducts.map((p) =>
            p.productId === product.productId
              ? { ...p, quantity: p.quantity + quantity }
              : p
          );
          dispatch(setProductData({ company: company, product: prevProduct }));
        } else {
          const prevProduct = addedProducts.map((p) =>
            p.productId === product.productId
              ? { ...p, quantity: p.quantity + 1 }
              : p
          );
          dispatch(setProductData({ company: company, product: prevProduct }));
        }
      } else {
        const productData = [...addedProducts, product];
        dispatch(setProductData({ company: company, product: productData }));
      }
    } else {
      const productData = [...addedProducts, product];
      dispatch(setProductData({ company: company, product: productData }));
    }
  };

  /**
   * Decrease product quantity
   * @param {*} product
   */
  const decreaseTotalQuantity = (product) => {
    if (product.quantity === 1) {
      const selectedProducts =
        addedProducts && addedProducts.length > 0
          ? addedProducts.filter((item) => item.productId !== product.productId)
          : [];
      dispatch(setProductData({ company: company, product: selectedProducts }));
    } else if (addedProducts && addedProducts.length > 0) {
      const selectData = addedProducts.find(
        (item) => item.productId === product.productId
      );
      if (selectData) {
        const prevProduct = addedProducts.map((p) =>
          p.productId === product.productId
            ? { ...p, quantity: p.quantity - 1 }
            : p
        );
        dispatch(setProductData({ company: company, product: prevProduct }));
      }
    } else {
      dispatch(setProductData({ company: company, product: [] }));
    }
  };

  /**
   * Show product detail on click
   * @param {*} product
   */
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowOffcanvas(true);
    const productQuantity =
      addedProducts &&
      addedProducts.length > 0 &&
      addedProducts.find((item) => item.productId === product.productId)
        ?.quantity
        ? addedProducts.find((item) => item.productId === product.productId)
            ?.quantity
        : 1;
    setSelectedProductQuantity(productQuantity);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    setSelectedProduct(null);
  };

  /**
   * Add button click on product detail popup
   * @returns
   */
  const handleAddButtonClick = (totalPrize, selectedAttributes) => {
    if (addedProducts && addedProducts.length > 0) {
      const selectData = addedProducts.find(
        (item) => item.productId === selectedProduct.productId
      );
      if (selectData) {
        const prevProduct = addedProducts.map((p) =>
          p.productId === selectedProduct.productId
            ? {
                ...p,
                quantity: selectedProductQuantity,
                price: totalPrize || selectedProduct.price,
                selectedAttributes,
              }
            : p
        );
        dispatch(setProductData({ company: company, product: prevProduct }));
        setSelectedProductQuantity(1);
        setShowOffcanvas(false);
        return;
      } else {
        const productData = {
          ...selectedProduct,
          quantity: selectedProductQuantity,
          price: totalPrize || selectedProduct.price,
          selectedAttributes,
        };
        updateTotalQuantity(productData);
        setSelectedProductQuantity(1);
        setShowOffcanvas(false);
        return;
      }
    } else {
      const productData = {
        ...selectedProduct,
        quantity: selectedProductQuantity,
        price: totalPrize || selectedProduct.price,
        selectedAttributes,
      };
      updateTotalQuantity(productData);
      setSelectedProductQuantity(1);
      setShowOffcanvas(false);
      return;
    }
  };

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setSelectedProductQuantity(value);
    } else {
      setSelectedProductQuantity(1);
    }
  };

  /**
   * Increase product count
   */
  const handleIncrease = () => {
    setSelectedProductQuantity((prevQuantity) => prevQuantity + 1);
  };

  /**
   * Decrease product count
   */
  const handleDecrease = () => {
    setSelectedProductQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  /**
   * Verify Otp service
   * @param {*} values
   */
  const handleVerifyOTPButton = (values) => {
    if (values.otp && values.otp.length < 4) {
      toast.warn("Please enter a four digit OTP");
      return;
    }
    verifyOtp(values, dispatch).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === "200" || res.status === "success")
      ) {
        dispatch(login(loginData));
        if (callPlaceOrder) {
          if (!selectedAddressData && formData.delivery === "2") {
            toast.warn("Add an address for delivery!");
            setShowModal(false);
            setCallPlaceOrder(false);
            return;
          }

          const transformedProductData = addedProducts.map((item) => {
            const attributeData = item.selectedAttributes
              ? JSON.stringify(
                  Object.keys(item.selectedAttributes).flatMap((cat_name) =>
                    item.selectedAttributes[cat_name].map((attr) => ({
                      id: attr.id,
                      name: attr.name,
                      value: attr.value,
                      cat_id: attr.cat_id,
                      cat_selection_type: attr.cat_selection_type,
                      cat_name: cat_name,
                    }))
                  )
                )
              : null;
    
            return {
              product_id: item.productId,
              brand_id: item.brand_id,
              brand_cgst: item.brand_cgst,
              brand_sgst: item.brand_sgst,
              brand_vat: item.brand_vat,
              product_size: item.size,
              quantity: item.quantity,
              product_price: item.price,
              product_purchase_price: item.purchase_price ? item.purchase_price : 0,
              product_instruction:
                item?.product_instruction && item.product_instruction !== ""
                  ? item.product_instruction
                  : null,
              food_type_one:
                item?.veg_nonveg && item.veg_nonveg !== "" ? item.veg_nonveg : "0",
              food_type_two:
                item?.food_type_jain_regular && item.food_type_jain_regular !== ""
                  ? item.food_type_jain_regular
                  : "2",
              attribute_data: attributeData, // changed by sakshi
            };
          });
    
          const groupedByBrandId = transformedProductData.reduce((acc, product) => {
            const { brand_id, brand_cgst, brand_sgst, product_price, quantity, brand_vat, ...productData } = product;
            let brandEntry = acc.find((entry) => entry.brand_id.toString() === brand_id.toString());
          
            let totalPriceForProduct = quantity * product_price;
            let totalTax = totalPriceForProduct * (Number(brand_cgst) + Number(brand_sgst) + Number(brand_vat)) / 100;
            let totalPriceWithTax = totalPriceForProduct + totalTax;
          
            if (!brandEntry) {
              brandEntry = {
                brand_id: brand_id.toString(),
                p_total_amount: 0,
                p_total_tax_amount: 0,
                p_delivery_charges: deliveryCharge && deliveryCharge !== "" && deliveryCharge > 0 ? deliveryCharge : 0,
                p_discount_id: discountData && discountData?.id ? discountData.id : 0,
                p_discount_code: discountCode && discountCode !== "" ? discountCode : '',
                p_discount_amount: discountedAmount && discountedAmount !== "" && discountedAmount > 0 ? discountedAmount : 0,
                product_data: []
              };
              acc.push(brandEntry);
            }
          
            brandEntry.product_data.push({ ...productData, quantity, product_price });
          
            brandEntry.p_total_amount += totalPriceWithTax;
            brandEntry.p_total_tax_amount += totalTax;
          
            return acc;
          }, []);
          
          // Apply delivery charges and discounts once per brand
          groupedByBrandId.forEach((brandEntry) => {
            if (brandEntry.p_delivery_charges) {
              brandEntry.p_total_amount += brandEntry.p_delivery_charges;
            }
            if (brandEntry.p_discount_amount) {
              brandEntry.p_total_amount -= brandEntry.p_discount_amount;
            }
          });
          
          const requestBody = {
            p_table_no: formData.tableNo,
            p_company_id: company,
            p_branch_id: branchId && branchId !== "" ? branchId.toString() : "",
            p_address_id: selectedAddressData?.addressId ? selectedAddressData.addressId : null,
            p_instruction: message,
            p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            p_orderData: groupedByBrandId,
            p_status: "0",
            p_type: formData.delivery === "2" ? "2" : formData.takeOut === "1" ? "1" : "0",
            p_payment: "0",
          };

          if (userAuthData?.token) {
            setShowModal(false);
            if (discountBool) {
              toast.warn(
                `Discount is only applicable for minimum amount of ₹${discountData?.min_cart_value}`
              );
              return;
            }
            placeOrderService(requestBody, userAuthData.token, dispatch).then(
              (response) => {
                if (
                  response &&
                  (response.status === 200 ||
                    response.status === "200" ||
                    response.status === "success")
                ) {
                  // onPlay();
                  dispatch(clearDiscountDataByCode());
                  navigate("/thank-you");
                }
              }
            );
          } else if (loginData?.token) {
            setShowModal(false);
            if (discountBool) {
              toast.warn(
                `Discount is only applicable for minimum amount of ₹${discountData?.min_cart_value}`
              );
              return;
            }
            placeOrderService(requestBody, loginData?.token, dispatch).then(
              (response) => {
                if (
                  response &&
                  (response.status === 200 ||
                    response.status === "200" ||
                    response.status === "success")
                ) {
                  // onPlay();
                  dispatch(clearDiscountDataByCode());
                  navigate("/thank-you");
                }
              }
            );
          } else {
            toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            removePersistLoginStorage(dispatch);
          }
          setCallPlaceOrder(false);
        }
        handleCloseModal();
      }
    });
  };

  const handleAddressChange = (item) => {
    setAddressData(item.addressId);
    setSelectedAddressData(item);
    setShowAddressModal(false);
  };

  const handleAddClick = (product) => {
    const selectedProduct = {
      ...product,
      quantity: 1,
    };
    updateTotalQuantity(selectedProduct);
  };

  /**
   * Change Order type radio input
   * @param {*} event
   * @param {*} name1
   * @param {*} name2
   */
  const handleChangeRadio = (event, name1, name2) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name1]: "",
      [name2]: "",
      [name]: value,
    });
    setEditAddressData(null);
    setSelectedAddressData(null);
    setAddFormOpen(false);
    setAddressData("");
  };

  /**
   * Open address modal
   * @param {*} action
   */
  const handleAddressModal = (action) => {
    setShowAddressModal(action);
    setAddFormOpen(false);
    if (!address || (address && address.length < 1)) {
      fetchAddress();
    }
  };

  const onEditClick = (data) => {
    setEditAddressData(data);
    setAddFormOpen(true);
  };

  /**
   * Submit address form
   */
  const handleAddUpdateAddress = (values) => {
    if (!editAddressData) {
      const requestBody = {
        p_title: values.title === "Other" ? values.otherInput : values.title,
        p_address_line1: values.address1,
        p_address_line2: values.address2,
        p_city: values.city,
        p_postal_code: values.pin_code.toString(),
        p_country_id: 0,
        p_latitude: null,
        p_longitude: null,
      };
      addAddressService(
        requestBody,
        userAuthData?.token,
        dispatch,
        setAddFormOpen,
        setEditAddressData
      ).then((res) => {
        if (
          res &&
          (res.status === "200" ||
            res.status === 200 ||
            res.status === "success")
        ) {
          fetchAddress();
          setSelectedAddressData(null);
        }
      });
    } else {
      const requestBody = {
        p_id: editAddressData.addressId,
        p_title: values.title === "Other" ? values.otherInput : values.title,
        p_address_line1: values.address1,
        p_address_line2: values.address2,
        p_city: values.city,
        p_postal_code: values.pin_code.toString(),
        p_country_id: 0,
        p_latitude: null,
        p_longitude: null,
        p_is_deleted: "0",
      };
      updateAddressService(
        requestBody,
        userAuthData?.token,
        dispatch,
        setAddFormOpen,
        setEditAddressData
      ).then((res) => {
        if (
          res &&
          (res.status === "200" ||
            res.status === 200 ||
            res.status === "success")
        ) {
          let editData = {
            addressId: editAddressData?.addressId,
            user_id: editAddressData?.user_id,
            title: values.title === "Other" ? values.otherInput : values.title,
            address_line1: values.address1,
            address_line2: values.address2,
            country_id: 0,
            city: values.city,
            postal_code: values.pin_code,
            latitude: editAddressData?.latitude,
            longitude: editAddressData?.longitude,
            created_at: editAddressData?.created_at,
            IsDeleted: editAddressData?.IsDeleted
        }
          
          setAddressData(editAddressData.addressId)
          fetchAddress();
          setSelectedAddressData(editData);
        }
      });
    }
  };

  const deleteAddressService = (deleteData) => {
    const requestBody = {
      p_id: deleteData.addressId,
      p_title: deleteData.title,
      p_address_line1: deleteData.address_line1,
      p_address_line2: deleteData.address_line2,
      p_city: deleteData.city,
      p_postal_code: deleteData.postal_code,
      p_country_id: 0,
      p_latitude: null,
      p_longitude: null,
      p_is_deleted: "1",
    };
    updateAddressService(
      requestBody,
      userAuthData?.token,
      dispatch,
      "delete"
    ).then((res) => {
      if (
        res &&
        (res.status === "200" || res.status === 200 || res.status === "success")
      ) {
        fetchAddress();
        setAddFormOpen(false);
        setDeleteAddress(false);
        if (deleteData?.addressId === selectedAddressData?.addressId) {
          setSelectedAddressData(null);
        }
      }
    });
  };

  const onBackClick = () => {
    setAddFormOpen(false);
    setEditAddressData(null);
  };

  const [parsedAttributes, setParsedAttributes] = useState([]);

  const [totalPrize, setTotalPrize] = useState(selectedProduct);

  useEffect(() => {
    if (editAddressData) {
      setAddressInitialValues({
        title:
          editAddressData.title !== "Home" && editAddressData.title !== "Office"
            ? "Other"
            : editAddressData.title,
        address1: editAddressData.address_line1,
        address2: editAddressData.address_line2,
        city: editAddressData.city,
        pin_code: editAddressData.postal_code.toString(),
        otherInput:
          editAddressData.title !== "Home" && editAddressData.title !== "Office"
            ? editAddressData.title
            : "",
      });
    } else {
      setAddressInitialValues({
        title: "Home",
        address1: "",
        address2: "",
        city: "",
        pin_code: "",
        otherInput: "",
      });
    }
    // eslint-disable-next-line
  }, [editAddressData]);

  const handleDiscountModal = () => {
    setShowDiscountModal(false);
  };

  const handleDiscountChange = (discount) => {
    if (userAuthData?.token) {
      if(discountData?.code === discount) {
        toast.warn('This discount is already applied!');
        return;
      }
      const requestBody = {
        p_code: discount,
        p_cart_amount: totalAmount - totalTax - deliveryCharge,
        p_phone_number: userAuthData.data.phoneNumber,
        p_brand_id: brandDetail.brand_id,
        p_company_id: company,
      };
      postDiscountByCodeService(requestBody, userAuthData.token, dispatch).then(
        (res) => {
          if (
            res?.status &&
           ( res.status === 200 ||
            res.status === "200" ||
            res.status === "success" ||
            res.status === "Status")
          ) {
            setDiscountData(res.data);
            handleDiscountModal(false);
            setDiscountCode(false);
            setShowConfetti(true);

            setTimeout(() => {
              setShowConfetti(false);
            }, 4000);
            // toast.success("Discount applied successfully!");
          }
        }
      );
    } else {
      setCallPlaceOrder(false);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (discountData && discountData?.code) {
      setDiscountCode(discountData.code);
    } else {
      setDiscountCode("");
    }
  }, [discountData]);

  const openDiscountPopup = () => {
    if (!userAuthData?.token) {
      setCallPlaceOrder(false);
      setShowModal(true);
    } else {
      setShowDiscountModal(true);
    }
  };

  const onAddressLoginOpen = () => {
    if (userAuthData?.token) {
      handleAddressModal(true);
    } else {
      setCallPlaceOrder(false);
      setShowModal(true);
    }
  };

  const scrollToBillDetails = () => {
    const billDetails = document.querySelector(".bill-details");
    if (billDetails) {
      billDetails.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="order-review">
        <div className="order-header fixed-header">
          <div className="row">
            <div className="order-header-detail">
              <Link to={`/`}>
                <FaChevronLeft />
              </Link>
              <h6>Your Cart</h6>
            </div>
          </div>
        </div>
        <div className="order-detail-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="radio-btn">
                  <label
                    className={`radio-label ${
                      formData.dineIn === "0" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="dineIn"
                      value="0"
                      checked={formData.dineIn === "0"}
                      onChange={(e) =>
                        handleChangeRadio(e, "takeOut", "delivery")
                      }
                    />
                    &nbsp;
                    <MdOutlineTableRestaurant />
                    Dine In
                  </label>

                  <label
                    className={`radio-label ${
                      formData.takeOut === "1" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="takeOut"
                      value="1"
                      checked={formData.takeOut === "1"}
                      onChange={(e) =>
                        handleChangeRadio(e, "dineIn", "delivery")
                      }
                    />
                    &nbsp;
                    <TbPaperBag />
                    Take Away
                  </label>

                  {brandDetail &&
                    brandDetail?.brand_id &&
                    brandDetail?.delivery_available === "1" && (
                      <label
                        className={`radio-label ${
                          formData.delivery === "2" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="delivery"
                          value="2"
                          checked={formData.delivery === "2"}
                          onChange={(e) =>
                            handleChangeRadio(e, "dineIn", "takeOut")
                          }
                        />
                        &nbsp;
                        <TbTruckDelivery />
                        Delivery
                      </label>
                    )}
                </div>
                {formData.dineIn === "0" && (
                  <NormalInput
                    type="text"
                    name="tableNo"
                    value={formData.tableNo}
                    handleChange={handleChange}
                    placeholder="Enter your table number/Office Number"
                    label="Table No."
                  />
                )}
                <div className="message-input">
                {formData.delivery === "2" && (
                  <>
                    {selectedAddressData && (
                      <div className="selected-box">
                        <span>
                          <b>Name:</b> {userAuthData?.data?.firstName}{" "}
                          {userAuthData?.data?.lastName}
                        </span>
                        <span>
                          <b>Address:</b> {selectedAddressData.address_line1},{" "}
                          {selectedAddressData.address_line2},{" "}
                          {selectedAddressData.city},{" "}
                          {selectedAddressData.postal_code}
                        </span>
                        <span>
                          <b>Phone Number:</b> {userAuthData?.data?.phoneNumber}
                        </span>
                      </div>
                    )}
                    <Button
                      className="add-address-btn"
                      onClick={onAddressLoginOpen}
                    >
                      <FaPlus /> Add Address
                    </Button>
                  </>
                )}
              </div>
              <div className="message-input">
                <textarea
                  value={message}
                  onChange={handleMessageChange}
                  placeholder="Would you like to add generalized instruction to chef 🧐?"
                />
              </div>

              {brandDetail && brandDetail?.brand_id && (
                <div className="discount-btn d-flex justify-content-center">
                  <Button
                    className="btn-fillter-main"
                    onClick={openDiscountPopup}
                  >
                    <RiDiscountPercentFill /> Try your luck and get some discount😉
                  </Button>
                </div>
              )}
              </div>
            
              <div className="col-12">
                {addedProducts && addedProducts.length > 0 ? (
                  addedProducts.map((product, index) => (
                    <Product
                      product={product}
                      key={index}
                      updateTotalQuantity={updateTotalQuantity}
                      decreaseTotalQuantity={decreaseTotalQuantity}
                      // onProductClick={handleProductClick}
                      hideImageAndAddButton={hideImageAndAddButton}
                      showMessageInput={true}
                      showRadio={true}
                      showBrandColor={false}
                      showPrice={true}
                      page="reviewOrder"
                    />
                  ))
                ) : (
                  <p>Menu items not found 🤷‍♂️ !</p>
                )}
              </div>
              {/* <div className="bill-details">
                <span className="bill-name">Bill Details</span>
                <div className="bill-details-box">
                  <div className="bill-details-text">
                    <span className="bill-name">Total Amount</span>
                    <span>
                      ₹{totalAmount - totalTax - deliveryCharge + discountedAmount}
                    </span>
                  </div>
                  <div className="bill-details-text">
                    <span className="bill-name">Tax Amount</span>
                    <span>₹{totalTax}</span>
                  </div>
                  {discountData && discountData?.id && (
                    <div className="bill-details-text">
                      <span className="bill-name">Discount</span>
                      <span>₹{discountedAmount}</span>
                    </div>
                  )}
                  {discountData && discountData?.id && (
                    <div className="bill-details-text">
                      <span className="bill-name">Discount Code</span>
                      <span>{discountCode}</span>
                    </div>
                  )}
                  {formData.delivery === '2' && (
                    <div className="bill-details-text">
                      <span className="bill-name">Delivery Charges</span>
                      <span>₹{deliveryCharge}</span>
                    </div>
                  )}
                </div>
              </div> */}

              {filterAddedProduct && filterAddedProduct.length > 0 && (
                <div className="col-12">
                  <h6 className="recommended-item-title">
                    Forgot to add something 🧐?
                  </h6>
                  <div className="new-product-box">
                    {filterAddedProduct.map((product, index) => (
                      <SquareProduct
                        productData={product}
                        key={index}
                        handleAddClick={handleAddClick}
                        showBrandColor={false}
                        onProductClick={handleProductClick}
                      />
                    ))}
                  </div>
                </div>
              )}

             <div className="col-12">
             <div className="bill-details">
                <span className="bill-title">Bill Details</span>
                <div className="bill-details-box">
                  <div className="bill-details-text">
                    <span className="bill-label">Total</span>
                    <span className="bill-value">
                      ₹
                      {(
                        totalAmount -
                        totalTax -
                        deliveryCharge +
                        discountedAmount
                      ).toFixed(2)}
                    </span>
                  </div>
                  <div className="bill-details-text">
                    <span className="bill-label">Tax</span>
                    <span className="bill-value">₹{totalTax.toFixed(2)}</span>
                  </div>
                  {discountData && discountData?.id && (
                    <>
                      <div className="bill-details-text">
                        <span className="bill-label">Discount</span>
                        <span className="bill-value">
                          ₹{discountedAmount.toFixed(2)}
                        </span>
                      </div>
                      <div className="bill-details-text">
                        <span className="bill-label">Discount Code</span>
                        <span className="bill-value">{discountCode}</span>
                      </div>
                    </>
                  )}
                  {formData.delivery === "2" && (
                    <div className="bill-details-text">
                      <span className="bill-label">Delivery Charges</span>
                      <span className="bill-value">₹{deliveryCharge}</span>
                    </div>
                  )}
                  <div className="bill-details-text">
                    <span className="bill-label">To pay</span>
                    <span className="bill-value">₹{totalAmount}</span>
                  </div>
                </div>
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fixed-bottom">
          {addedProducts && addedProducts.length > 0 ? (
            <div className="total-amount">
             <span>Total Amount: ₹{totalAmount}</span>
              <span className="tax-calculate" onClick={scrollToBillDetails}>
                View bill detail
              </span>
            </div>
          ) : (
            <div className="total-amount">Total Amount: ₹0</div>
          )}
          <button
            className={loader ? 'order-loader' : "order-button"}
            onClick={handleShowModal}
            disabled={loader}
          >
            Place Order
          </button>
        </div>
      </div>
      {showConfetti && (
        <ConfettiCannon
          count={200}
          origin={{ x: -10, y: 0 }}
          autoStart={false}
          ref={cannonRef} // Attach the ref to the ConfettiCannon component
        />
      )}

      <LoginPopup
        handleCloseModal={handleCloseModal}
        initialValues={initialValues}
        handleLogin={handleLogin}
        validationSchema={validationSchema}
        handleVerifyOTPButton={handleVerifyOTPButton}
        otpButtonVisible={otpButtonVisible}
        showModal={showModal}
        timer={timer}
        isLinkActive={isLinkActive}
        handleClick={handleClick}
      />

      {selectedProduct && (
        <DetailContainer
          showOffcanvas={showOffcanvas}
          handleCloseOffcanvas={handleCloseOffcanvas}
          selectedProduct={selectedProduct}
          imageData={imageData}
          handleDecrease={handleDecrease}
          selectedProductQuantity={selectedProductQuantity}
          handleQuantityChange={handleQuantityChange}
          handleIncrease={handleIncrease}
          addProductFromDetail={handleAddButtonClick}
          parsedAttributes={parsedAttributes}
          setParsedAttributes={setParsedAttributes}
          totalPrize={totalPrize}
          setTotalPrize={setTotalPrize}
        />
      )}

      <AddressList
        showAddressModal={showAddressModal}
        handleAddressModal={handleAddressModal}
        addFormOpen={addFormOpen}
        editAddressData={editAddressData}
        onBackClick={onBackClick}
        handleAddressChange={handleAddressChange}
        onEditClick={onEditClick}
        setAddFormOpen={setAddFormOpen}
        addressInititalValues={addressInititalValues}
        handleAddUpdateAddress={handleAddUpdateAddress}
        addressValidationSchema={addressValidationSchema}
        setAddressValidationSchema={setAddressValidationSchema}
        deleteAddressService={deleteAddressService}
        addressData={addressData}
        setSelectedAddressData={setSelectedAddressData}
        deleteAddress={deleteAddress}
        setDeleteAddress={setDeleteAddress}
      />

      <DiscountPopup
        showDiscountModal={showDiscountModal}
        handleDiscountModal={handleDiscountModal}
        handleDiscountChange={handleDiscountChange}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
      />

      <Modal show={deleteAddress} onHide={onCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this address?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              onClick={() => deleteAddressService(deleteAddress)}
              className={`me-2 flex-grow-1 ${
                brandDetail?.brand_id ? "yes-button" : "no-brand-yes-button"
              }`}
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={onCloseDeleteModal}
              className="flex-grow-1"
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
