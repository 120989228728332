// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.user-maggese {
  width: 100%;
}
.user-maggese .product {
  border-radius: 10px 10px 0 10px !important;
  border: 1px solid #ccc;
  background-color: #ff4155;
}
.user-maggese .product h6 {
  color: #FFF;
}
.user-maggese .product span {
  color: #FFF;
}

.user-pms {
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/UserMaggese/UserMaggese.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAFA;EACI,WAAA;AAKJ;AAJI;EACI,0CAAA;EACA,sBAAA;EACA,yBDEM;ACId;AAJQ;EACI,WDPC;ACab;AAJQ;EACI,WDVC;ACgBb;;AAFA;EACI,qBAAA;AAKJ","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import\"../../variables\";\n.user-maggese{\n    width: 100%;\n    .product{\n        border-radius: 10px 10px 0 10px !important;\n        border: 1px solid #ccc;\n        background-color: $user-chat-bg;\n\n        h6{\n            color: $white-color;\n        }\n        span{\n            color: $white-color;\n        }\n    }\n}\n.user-pms{\n    align-items: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
