const categoryFilterInitialState = {
    name: '', 
    code:'',
    isActive: '',
    isDelete: ''
};

/**
 * Store category filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const categoryFilterReducer = (state = categoryFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY_FILTER':
            return {
                ...state,
                name: action.payload.name,
                code: action.payload.code,
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete
            }
        case 'CLEAR_CATEGORY_FILTER':
            return categoryFilterInitialState;
        default:
            return state;
      };
}