import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/SearchBar/SearchBar";
import "../HomePage/Homepage.scss";
import Product from "../../components/Product/Product";
import Accordion from "react-bootstrap/Accordion";
import { FaCircleArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProductData,
  setOrderProductData,
  setProductData,
} from "../../Redux/Actions/ProductAction/ProductAction";
import { getAllProductService } from "../../components/Product/ProductService/ProductService.tsx";
import { getAllCategoryService } from "./Home-services/CategoryService/CategoryService.tsx";
import { getAllSubCategoryService } from "./Home-services/SubCategoryService/SubCategoryService.tsx";
import { parseImage } from "../../Services/Regular-services/ImageService.js";
import UtilsData, { API_URL } from "../../Utils/Utils.js";
import { clearSubCategoryData } from "../../Redux/Actions/subCategoryAction/SubCategoryAction.js";
import GifLoader from "../../assets/images/Food Loader - GIF Animation.gif";
import DetailContainer from "../../components/Detail-container/DetailContainer.js";
import {
  MdDelete,
  MdLocationOff,
  MdOutlineQrCodeScanner,
} from "react-icons/md";
import Logo from "../../assets/images/Bespeakk-logo.png";
import Footer from "../../components/Footer/Footer";
import QrScanner from "qr-scanner";
import { Offcanvas } from "react-bootstrap";
import { updateOrderService } from "../ReviewOrder/OrderAndLoginService/OrderService/PlaceOrderService/PlaceOrderService.tsx";
import { postDiscountByCodeService } from "../ReviewOrder/DiscountService/DiscountService";
import ClipLoader from 'react-spinners/ClipLoader';

export default function HomePage(props) {
  const {
    page = false,
    propsBrandId = null,
    orderData = null,
    setShowCanvasData,
  } = props;

  const productData = useSelector((state) => state.productAPI);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const addedProducts = useSelector((state) => state.product?.items);
  const productOrder = useSelector((state) => state.productOrder?.items);
  const productFilter = useSelector((state) => state.productFilter);
  const [imageData, setImageData] = useState("");
  const [inputData, setInputData] = useState("");
  const [filteredProductData, setFilteredProductData] = useState([]);
  const loader = useSelector((state) => state.loader?.items);

  const categoryData = useSelector((state) => state.category);
  const categoryFilter = useSelector((state) => state.categoryFilter);
  const subCategoryData = useSelector((state) => state.subCategory);
  const locationData = useSelector((state) => state.location?.location);
  const locationMessage = useSelector(
    (state) => state.locationMessage?.location
  );

  const brand_code = useSelector((state) => state.brand?.brand_code);
  const brandDetail = useSelector((state) => state.brand?.brand_detail);
  const userAuthData = useSelector((state) => state.auth?.user);

  const [categoryId, setCategoryId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subCategoryActiveIndex, setSubCategoryActiveIndex] = useState(null);
  const [productCategory, setProductCategory] = useState([]);
  const company = useSelector((state) => state.company?.items);

  const brandIdClick = useSelector((state) => state.brandIdClick?.items);
  const tokenData = UtilsData();

  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const companyDetail = useSelector((state) => state.company?.company_detail);
  // const [scannedResult, setScannedResult] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      if (videoEl.current) {
        scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
          onDecodeError: onScanFail,
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
          overlay: qrBoxEl.current || undefined,
        });

        scanner.current
          .start()
          .then(() => setQrOn(true))
          .catch((err) => {
            if (err) setQrOn(false);
          });
      }
    }, 500);
  };

  const onScanSuccess = (result) => {
    let urlToOpen = result?.data;
    window.open(urlToOpen, "_blank");
    // setScannedResult(result?.data);
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
        scanner.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and reload."
      );
    }
  }, [qrOn]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedProduct && selectedProduct.image_data) {
      const data = parseImage(selectedProduct.image_data);
      const newImageData = API_URL.TOKEN_URL + data[0].url;
      setImageData(newImageData);
    }
  }, [selectedProduct]);

  const [groupedProducts, setGroupedProducts] = useState({});

  /**
   * Fetch category data
   */
  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
      p_type: "0",
    };
    if (categoryFilter.name !== "" && categoryFilter.name !== null) {
      requestBody.p_name = categoryFilter.name;
    }
    if (categoryFilter.code !== "" && categoryFilter.code !== null) {
      requestBody.p_category_code = categoryFilter.code;
    }

    if (categoryFilter.isActive !== "" && categoryFilter.isActive !== null) {
      requestBody.p_is_active = categoryFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }

    if (categoryFilter.isDelete !== "" && categoryFilter.isDelete !== null) {
      requestBody.p_IsDeleted = categoryFilter.isDelete;
    }
    getAllCategoryService(requestBody, dispatch);
  };

  useEffect(() => {
    if (
      categoryData &&
      categoryData.items &&
      categoryData.items.length === 0 &&
      brand_code &&
      brand_code !== ""
    ) {
      fetchCategory();
    }
    if (categoryData.items[0]?.data && categoryData.items[0].data.length > 0) {
      const filteredCategory = categoryData.items[0].data.filter(
        (item) => item.type === "0" || item.type === 0
      );
      setProductCategory(filteredCategory);
    } else {
      setProductCategory([]);
    }
    // eslint-disable-next-line
  }, [categoryData]);

  /**
   * Fetch product data
   */
  const fetchProductFromOrder = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : propsBrandId
        ? propsBrandId
        : null,
    };
    if (productFilter.name !== "" && productFilter.name !== null) {
      requestBody.p_name = productFilter.name;
    }
    if (
      productFilter.category !== "" &&
      productFilter.category !== null &&
      productFilter.category.length > 0
    ) {
      requestBody.p_category_id = productFilter.category;
    }
    if (
      productFilter.subCategory !== "" &&
      productFilter.subCategory !== null &&
      productFilter.subCategory.length > 0
    ) {
      requestBody.p_sub_category_id = productFilter.subCategory;
    }
    if (
      productFilter.leafCategory !== "" &&
      productFilter.leafCategory !== null &&
      productFilter.leafCategory.length > 0
    ) {
      requestBody.p_leaf_level_category_id = productFilter.leafCategory;
    }
    if (
      productFilter.tags !== "" &&
      productFilter.tags !== null &&
      productFilter.tags.length > 0
    ) {
      requestBody.p_tag_id = productFilter.tags.join(",");
    }
    if (productFilter.isActive !== "" && productFilter.isActive !== null) {
      requestBody.p_is_active = productFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
      requestBody.p_IsDeleted = productFilter.isDelete;
    }
    getAllProductService(requestBody, dispatch);
  };

  /**
   * Fetch product data
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
    };
    if (productFilter.name !== "" && productFilter.name !== null) {
      requestBody.p_name = productFilter.name;
    }
    if (
      productFilter.category !== "" &&
      productFilter.category !== null &&
      productFilter.category.length > 0
    ) {
      requestBody.p_category_id = productFilter.category;
    }
    if (
      productFilter.subCategory !== "" &&
      productFilter.subCategory !== null &&
      productFilter.subCategory.length > 0
    ) {
      requestBody.p_sub_category_id = productFilter.subCategory;
    }
    if (
      productFilter.leafCategory !== "" &&
      productFilter.leafCategory !== null &&
      productFilter.leafCategory.length > 0
    ) {
      requestBody.p_leaf_level_category_id = productFilter.leafCategory;
    }
    if (
      productFilter.tags !== "" &&
      productFilter.tags !== null &&
      productFilter.tags.length > 0
    ) {
      requestBody.p_tag_id = productFilter.tags.join(",");
    }
    if (productFilter.isActive !== "" && productFilter.isActive !== null) {
      requestBody.p_is_active = productFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
      requestBody.p_IsDeleted = productFilter.isDelete;
    }
    getAllProductService(requestBody, dispatch);
  };

  useEffect(() => {
    if (
      propsBrandId &&
      page === "orderHistory" &&
      (!brand_code || (brand_code && brand_code === ""))
    ) {
      fetchProductFromOrder();
    }
    // eslint-disable-next-line
  }, [page, propsBrandId]);

  useEffect(() => {
    if (
      productData &&
      productData.items &&
      productData.items.length === 0 &&
      brand_code &&
      brand_code !== ""
    ) {
      fetchProduct();
    }

    if (
      productData &&
      productData.items &&
      productData.items.length > 0 &&
      productData.items[0].data.length > 0
    ) {
      const groupByCategory = (products) => {
        return products.reduce((acc, product) => {
          // Split the comma-separated category string into an array
          const categories = product.categoryName
            .split(",")
            .map((category) => category.trim());

          // Iterate over the categories array
          categories.forEach((category) => {
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(product);
          });

          return acc;
        }, {});
      };

      if (inputData && inputData !== "") {
        const filteredProduct = productData.items[0].data.filter((product) =>
          product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const grouped = groupByCategory(filteredProduct);
        setGroupedProducts(grouped);
        setFilteredProductData(filteredProduct);
      } else {
        const grouped = groupByCategory(productData.items[0].data);
        setGroupedProducts(grouped);
        setFilteredProductData(productData.items[0].data);
      }
      // }
    } else {
      setFilteredProductData([]);
    }
    // eslint-disable-next-line
  }, [productData, inputData]);

  const updateTotalQuantity = (product, quantity = 1) => {
    const isOrderHistory = page && page === "orderHistory";
    const currentOrder = isOrderHistory ? productOrder : addedProducts;
    const setData = isOrderHistory ? setOrderProductData : setProductData;

    if (currentOrder && currentOrder.length > 0) {
      const selectData = currentOrder.find(
        (item) => item.productId === product.productId
      );

      if (selectData) {
        const updatedProducts = currentOrder.map((p) =>
          p.productId === product.productId
            ? { ...p, quantity: p.quantity + quantity }
            : p
        );
        dispatch(setData({ company, product: updatedProducts }));
      } else {
        const newProduct = {
          ...product,
          food_type_jain_regular: "2",
          quantity,
        };
        const updatedProducts = [...currentOrder, newProduct];
        dispatch(setData({ company, product: updatedProducts }));
      }
    } else {
      const newProduct = { ...product, food_type_jain_regular: "2", quantity };
      dispatch(setData({ company, product: [newProduct] }));
    }
  };

  const decreaseTotalQuantity = (product) => {
    if (page && page === "orderHistory") {
      if (product.quantity === 1) {
        const selectedProducts =
          productOrder && productOrder.length > 0
            ? productOrder.filter(
                (item) => item.productId !== product.productId
              )
            : [];
        dispatch(
          setOrderProductData({ company: company, product: selectedProducts })
        );
      } else if (productOrder && productOrder.length > 0) {
        const selectData = productOrder.find((item) => item.id === product.id);
        if (selectData) {
          const prevProduct = productOrder.map((p) =>
            p.productId === product.productId
              ? { ...p, quantity: p.quantity - 1 }
              : p
          );
          dispatch(
            setOrderProductData({ company: company, product: prevProduct })
          );
        }
      }
    } else {
      if (product.quantity === 1) {
        const selectedProducts =
          addedProducts && addedProducts.length > 0
            ? addedProducts.filter(
                (item) => item.productId !== product.productId
              )
            : [];
        dispatch(
          setProductData({ company: company, product: selectedProducts })
        );
      } else if (addedProducts && addedProducts.length > 0) {
        const selectData = addedProducts.find((item) => item.id === product.id);
        if (selectData) {
          const prevProduct = addedProducts.map((p) =>
            p.productId === product.productId
              ? { ...p, quantity: p.quantity - 1 }
              : p
          );
          dispatch(setProductData({ company: company, product: prevProduct }));
        }
      }
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const isOrderHistory = page && page === "orderHistory";
    const currentOrder = isOrderHistory ? productOrder : addedProducts;

    const productQuantity =
      currentOrder &&
      currentOrder.length > 0 &&
      currentOrder.find((item) => item.productId === product.productId)
        ?.quantity
        ? currentOrder.find((item) => item.productId === product.productId)
            ?.quantity
        : 1;
    setSelectedProductQuantity(productQuantity);
    setShowOffcanvas(true);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    setSelectedProduct(null);
  };

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setSelectedProductQuantity(value);
    } else {
      setSelectedProductQuantity(1);
    }
  };

  const handleIncrease = () => {
    setSelectedProductQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = (product) => {
    setSelectedProductQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const navigateToOrder = () => {
    const Data = {
      data: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
      // data: brandDetail?.brand_id ? brandDetail?.brand_id : null
    };
    navigate("/order-review", { state: { Data } });
  };

  const discardCart = () => {
    dispatch(clearProductData());
  };
  const allEventKeys = Object.keys(groupedProducts).map((_, index) =>
    index.toString()
  );

  const addProductFromDetail = (totalPrize, selectedAttributes) => {
    const isOrderHistory = page && page === "orderHistory";
    const currentOrder = isOrderHistory ? productOrder : addedProducts;
    const setData = isOrderHistory ? setOrderProductData : setProductData;

    if (currentOrder && currentOrder.length > 0) {
      const selectData = currentOrder.find(
        (item) => item.productId === selectedProduct.productId
      );

      if (selectData) {
        const updatedProducts = currentOrder.map((p) =>
          p.productId === selectedProduct.productId
            ? {
                ...p,
                quantity: selectedProductQuantity,
                price: totalPrize || selectedProduct.price,
                selectedAttributes,
              }
            : p
        );
        dispatch(setData({ company, product: updatedProducts }));
      } else {
        const newProduct = {
          ...selectedProduct,
          quantity: selectedProductQuantity,
          price: totalPrize || selectedProduct.price,
          selectedAttributes,
        };
        updateTotalQuantity(newProduct, selectedProductQuantity);
      }
    } else {
      const newProduct = {
        ...selectedProduct,
        quantity: selectedProductQuantity,
        price: totalPrize || selectedProduct.price,
        selectedAttributes,
      };
      updateTotalQuantity(newProduct, selectedProductQuantity);
    }

    setSelectedProductQuantity(1);
    setShowOffcanvas(false);
    setSelectedProduct(null);
  };

  /**
   * Click on category filter
   * @param {*} category
   * @param {*} index
   * @returns
   */
  const clickCategory = (category, index) => {
    const categoryData = [category.categoryId];
    if (categoryId && categoryId.length > 0) {
      const duplicateCategory = categoryId.find(
        (item) => item === category.categoryId
      );
      if (duplicateCategory) {
        setActiveIndex(null);
        setSubCategoryActiveIndex(null);
        setCategoryId([]);
        setSubCategoryId([]);
        dispatch(clearSubCategoryData());
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_is_active: "1",
          p_company_id: company,
          p_brand_id: brandDetail?.brand_id
            ? brandDetail?.brand_id
            : brandIdClick?.brandId
            ? brandIdClick.brandId
            : null,
        };
        getAllProductService(productRequestBody, dispatch);
        return;
      }
    }
    setActiveIndex(index);
    setSubCategoryActiveIndex(null);
    setCategoryId(categoryData);
    setSubCategoryId([]);
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_categoryIds: categoryData.join(","),
      p_type: "0",
      p_is_active: "1",
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
    };
    getAllSubCategoryService(requestBody, dispatch);

    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryData.toString(),
      p_is_active: "1",
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
    };
    // if(subCategoryId && subCategoryId.length > 0) {
    //   productRequestBody.p_sub_category_id = subCategoryId.join(',')
    // }
    getAllProductService(productRequestBody, dispatch);
  };

  /**
   * Click on sub-category filter
   * @param {*} subCategory
   * @param {*} index
   * @returns
   */
  const clickSubCategory = (subCategory, index) => {
    const subCategoryData = [subCategory.id];
    if (subCategoryId && subCategoryId.length > 0) {
      const duplicateSubCategory = subCategoryId.find(
        (item) => item === subCategory.id
      );
      if (duplicateSubCategory) {
        setSubCategoryId([]);
        setSubCategoryActiveIndex(null);
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_category_id: categoryId.join(","),
          // p_sub_category_id: subCategoryData.join(','),
          p_is_active: "1",
          p_company_id: company,
          p_brand_id: brandDetail?.brand_id
            ? brandDetail?.brand_id
            : brandIdClick?.brandId
            ? brandIdClick.brandId
            : null,
        };
        getAllProductService(productRequestBody, dispatch);
        return;
      }
    }
    setSubCategoryId(subCategoryData);
    setSubCategoryActiveIndex(index);
    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryId.join(","),
      p_sub_category_id: subCategoryData.join(","),
      p_is_active: "1",
      p_company_id: company,
      p_brand_id: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
    };
    getAllProductService(productRequestBody, dispatch);
  };

  useEffect(() => {
    setActiveIndex(null);
    setSubCategoryActiveIndex(null);
    setCategoryId([]);
    setSubCategoryId([]);
    dispatch(clearSubCategoryData());
    // eslint-disable-next-line
  }, [brandIdClick]);

  useEffect(() => {
    if (activeIndex && page === "orderHistory") {
      fetchProductFromOrder();
    } else if (activeIndex) {
      fetchProduct();
    }
    setActiveIndex(null);
    setSubCategoryActiveIndex(null);
    setCategoryId([]);
    setSubCategoryId([]);
    dispatch(clearSubCategoryData());
    // eslint-disable-next-line
  }, [inputData]);

  const updateOrder = async() => {
    const total = orderData?.order_amount ? Number(orderData.order_amount) : 0;
    const tax = orderData?.total_tax_amount
      ? Number(orderData.total_tax_amount)
      : 0;
    const discount = orderData?.total_discount_amount
      ? Number(orderData.total_discount_amount)
      : 0;

      const productData = ((productOrder.reduce((sum, product) => sum + (Number(product.price) * Number(product.quantity)), 0)))

    let newTotal = total - tax + discount + productData;
    let newTax = (total - tax + discount + productData) * ((orderData.brand_cgst ? Number(orderData.brand_cgst) : 0) +
          (orderData.brand_sgst ? Number(orderData.brand_sgst) : 0) +
          (orderData.brand_vat ? Number(orderData.brand_vast) : 0)) /
      100;

      let newDiscount = 0;

      if (orderData?.discount_id) {
        const discountRequestBody = {
          p_code: orderData.Discount_Code,
          p_cart_amount: newTotal,
          p_phone_number: userAuthData.data.phoneNumber,
          p_brand_id: orderData?.brand_id,
          p_company_id: orderData?.company_id,
        };
       const discountData = await postDiscountByCodeService(
          discountRequestBody,
          userAuthData?.token,
          dispatch,
          true
        ).then((res) => {
          if (
            res.status === 200 ||
            res.status === "200" ||
            res.status === "success" ||
            res.status === "Success"
          ) {
            let discountCalc = (newTotal * res.data.discount_amount) / 100 > res.data.max_discount_ammount? res.data.max_discount_ammount : (total * res.data.discount_amount) / 100;
  
            return discountCalc
          }
        });
        newDiscount += discountData;
        newTotal = newTotal - discountData + newTax;
      }
      

      
    const transformedProductData =
      productOrder && productOrder.length > 0
        ? productOrder.map((item) => {
          const attributeData = item.selectedAttributes
          ? JSON.stringify(
            Object.keys(item.selectedAttributes).flatMap(cat_name =>
              item.selectedAttributes[cat_name].map(attr => ({
                id: attr.id,
                name: attr.name,
                value: attr.value,
                cat_id: attr.cat_id,
                cat_selection_type: attr.cat_selection_type,
                cat_name: cat_name
              }))
            )
          )
          : null;
            return {
              product_id: item.productId,
              product_size: item.size,
              quantity: Number(item.quantity),
              product_price: Number(item.price),
              product_purchase_price: Number(item.purchase_price),
              product_instruction: null,
              food_type_one: item.veg_nonveg,
              food_type_two:
                item?.food_type_jain_regular &&
                item.food_type_jain_regular !== ""
                  ? item.food_type_jain_regular
                  : "2",
              attribute_data: attributeData, // changed by sakshi
            };
          })
        : [];

    const orderRequestBody = {
      p_order_id: orderData?.orderId,
      p_discount_id: orderData?.discount_id ? orderData.discount_id : null,
      p_discount_code: orderData?.Discount_Code
        ? orderData.Discount_Code
        : null,
      p_discount_amount: newDiscount,
      p_total_amount: newTotal,
      p_total_tax_amount: newTax,
      p_product_data: transformedProductData,
    };
    updateOrderService(
      orderRequestBody,
      tokenData,
      dispatch,
      "orderHistory"
    ).then((response) => {
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        setShowCanvasData();
        navigate("/thank-you");
      }
    });
  };

  // changes by sakshi 26/7/24
  const [parsedAttributes, setParsedAttributes] = useState([]);

  const [totalPrize, setTotalPrize] = useState(selectedProduct);

  return (
    <>
      <div
        className={
          page && page === "orderHistory"
            ? "home-page-order hide-scrollbar"
            : "home-page hide-scrollbar"
        }
      >
        {(page !== "orderHistory" || !page) && (
          <>
            <div className="order-header fixed-header">
              <div className="row">
                <div className="header-save-btn">
                  <div className="order-header-detail">
                    <Link to={`/`}>
                      {/* <FaChevronLeft /> */}
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="save-btn-icon" onClick={handleShow}>
                    <MdOutlineQrCodeScanner />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Header />
              </div>
            </div>
          </>
        )}

        <div className="sticky-top">
          {page && page === "orderHistory" && (
            <>
              {productOrder && productOrder.length > 0 && (
                <div className="button-at-end">
                  <button
                    className={
                      brandDetail?.brand_id
                        ? "add-more-order-home-brand-btn"
                        : "add-more-order-home-btn"
                    }
                    onClick={updateOrder}
                  >
                    Update Order
                  </button>
                </div>
              )}
            </>
          )}
          <div className="row align-items-center">
            <div className="col-12">
              <SearchBar
                setInputData={setInputData}
                inputData={inputData}
                page={propsBrandId ? "orderHistory" : false}
              />
            </div>
          </div>
          {(page !== "orderHistory" || !page) && (
            <div className="row">
              <div className="col-12">
                <div className="category hide-scrollbar">
                  <ul className="category-list">
                    {productCategory &&
                      productCategory.length > 0 &&
                      locationData === true &&
                      locationMessage === false &&
                      productCategory.map((category, index) => (
                        <li
                          key={index}
                          onClick={() => clickCategory(category, index)}
                          className={activeIndex === index ? "active" : ""}
                        >
                          <Link>{category.name}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="col-12">
                <div className="category hide-scrollbar sub-category">
                  <ul className="category-list">
                    {subCategoryData &&
                      subCategoryData.items &&
                      subCategoryData.items.length > 0 &&
                      subCategoryData.items[0].data &&
                      subCategoryData.items[0].data.length > 0 &&
                      locationData === true &&
                      locationMessage === false &&
                      subCategoryData.items[0].data.map(
                        (subCategory, index) => (
                          <li
                            key={index}
                            onClick={() => clickSubCategory(subCategory, index)}
                            className={
                              subCategoryActiveIndex === index
                                ? "sub-active"
                                : ""
                            }
                          >
                            <Link>{subCategory.name}</Link>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="container hide-scrollbar">
          <div className="row">
            <div className="col-12">
              {filteredProductData &&
              filteredProductData.length > 0 &&
              locationData === true &&
              locationMessage === false ? (
                <>
                  {groupedProducts &&
                    Object.keys(groupedProducts).map(
                      (categoryName, catIndex) => (
                        <Accordion
                          className="custom-accordion"
                          key={catIndex}
                          defaultActiveKey={allEventKeys}
                        >
                          <Accordion.Item eventKey={catIndex.toString()}>
                            <Accordion.Header className="accordion-header">
                              {categoryName}
                            </Accordion.Header>
                            <Accordion.Body>
                              {groupedProducts[categoryName].map(
                                (product, index) => (
                                  <Product
                                    product={product}
                                    key={index} // Using product.id as key is generally better than using index
                                    updateTotalQuantity={updateTotalQuantity}
                                    decreaseTotalQuantity={
                                      decreaseTotalQuantity
                                    }
                                    onProductClick={handleProductClick}
                                    showBrandColor={true}
                                    propsBrandId={propsBrandId}
                                    page={page}
                                  />
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )
                    )}
                </>
              ) : (
                <div className="center-container">
                  {loader ? (
                    // <img className="gif-loader" src={GifLoader} alt="...Loading Product Data" />
                    <ClipLoader 
                      size={30}  // Size of the loader
                      color={"#e25233"}  // Color of the loader
                      loading={loader}
                      />
                  ) : (
                    <h1>
                      {locationData === false && locationMessage === false ? (
                        <b>
                          You are not within {companyDetail?.company_range}{" "}
                          meter distance! <MdLocationOff className="location" />{" "}
                        </b>
                      ) : locationMessage === true ? (
                        <b>
                          Please allow location access!{" "}
                          <MdLocationOff className="location" />
                        </b>
                      ) : (
                        <b>Menu items not found 🤷‍♂️ !</b>
                      )}
                    </h1>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {addedProducts && addedProducts.length > 0 && !selectedProduct && (
          <div className="item-btn-show">
            <div className="item-btn-detail" onClick={navigateToOrder}>
              <Link>
                {addedProducts.reduce(
                  (sum, product) => sum + product.quantity,
                  0
                )}{" "}
                Item added <FaCircleArrowRight />
              </Link>
              <span>
                Let's review <strong>🤓</strong> !
              </span>
            </div>
            <div className="delet-icon-btn" onClick={discardCart}>
              <MdDelete />
            </div>
          </div>
        )}
      </div>

      {/* {(page !== 'orderHistory' || !page) && (
        <> */}
      {selectedProduct && (
        <DetailContainer
          showOffcanvas={showOffcanvas}
          handleCloseOffcanvas={handleCloseOffcanvas}
          selectedProduct={selectedProduct}
          imageData={imageData}
          handleDecrease={handleDecrease}
          selectedProductQuantity={selectedProductQuantity}
          handleQuantityChange={handleQuantityChange}
          handleIncrease={handleIncrease}
          addProductFromDetail={addProductFromDetail}
          parsedAttributes={parsedAttributes}
          setParsedAttributes={setParsedAttributes}
          // selectedAttributes={selectedAttributes}
          // setSelectedAttributes={setSelectedAttributes}
          totalPrize={totalPrize}
          setTotalPrize={setTotalPrize}
          page={page}
        />
      )}
      {/* </>
      )} */}

      {((addedProducts && addedProducts.length < 1) || !addedProducts) &&
        !selectedProduct &&
        (page !== "orderHistory" || !page) && <Footer />}

      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mode">
            <img src={Logo} alt="logo" />
          </div>

          <div className="QR-code-bg">
            <div className="scan-code">
              <div className="scan-code__angle scan-code__angle--top"></div>
              <video className="scanner" ref={videoEl}></video>
              <div className="scan-code" ref={qrBoxEl}></div>
              <div className="scan-code__angle scan-code__angle--bottom"></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
