const discountInitialState = { 
    items: {}
};

/**
 * Store discount list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const DiscountByCodeReducer = (state = discountInitialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_BY_CODE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_DISCOUNT_BY_CODE':
            return discountInitialState;
        default:
            return state;
      };
}
