import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import "../OrderHistory/OrderHistory.scss";
import { useDispatch, useSelector } from 'react-redux';
import { clearLoader, setLoader } from '../../Redux/Actions/LoaderAction/LoaderAction';
import { API_URL, removePersistLoginStorage, toastClass, ValidationMessages } from '../../Utils/Utils';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OrderComponent from '../../components/OrderCard/OrderCard';
import { placeOrderService } from '../ReviewOrder/OrderAndLoginService/OrderService/PlaceOrderService/PlaceOrderService.tsx';
// import GifLoader from "../../assets/images/Food Loader - GIF Animation.gif";
import Footer from '../../components/Footer/Footer';
import ClipLoader from 'react-spinners/ClipLoader';

export default function OrderHistory() {

  const [skip, setSkip] = useState(0);
  const take = 10;
  const [orderItems, setOrderItems] = useState([]);
  const userAuthData = useSelector(state => state.auth?.user);
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true)
  const loader = useSelector(state => state.loader?.items);
  const [dataLength, setDataLength] = useState(0);
  const navigate = useNavigate();
  const company = useSelector(state => state.company?.items);
  const branchId = useSelector(state => state.branch?.items);

  const location = useLocation();
  const { Data } = location.state || {};


  

  useEffect(() => {
    if (!userAuthData?.token) {
      navigate('/');
      // navigate(`/menu/${companyCode}/${branchCode}/${table}`)

    }
    // eslint-disable-next-line
  }, [userAuthData])

  // const onPlay = () => {
  //   // new Audio(notificationSound).play()
  //   return;
  // }

  const fetchData = async () => {
    dispatch(setLoader(true));
    try {
      let queryParams = `?p_skip=${skip}&p_take=${take}`;
      const response = await fetch(API_URL.BASE_URL + API_URL.ORDER_LIST + queryParams, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userAuthData?.token,
        },
      });
      const data = await response.json();
      if (response.status === 401 || response.status === 403 || data.message === 'Unauthorized access!') {
        toast.error('Unauthorized access!');
        removePersistLoginStorage(dispatch);
        navigate(`/`)
        // navigate(`/menu/${companyCode}`);
        return;
      }
      else if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (data.data && data.total_records && data.data.length > 0 && dataLength <= data.total_records) {
          setDataLength((prevDataLength) => prevDataLength + data.data.length);
          setOrderItems((prevItems) => {
            const uniqueItemsMap = new Map(prevItems.map(item => [item?.orderId, item]));
            data.data.forEach(item => uniqueItemsMap.set(item.orderId, item));
            return Array.from(uniqueItemsMap.values());
          });
        } else {
          setHasMore(false); // No more data available
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : response?.statusText ? response.statusText : ValidationMessages.SOMETHING_WENT_WRONG);
        }
        setHasMore(false);
      }
    } catch (error) {
      if (!document.querySelector(toastClass)) {
        setHasMore(false);
        toast.error('Error fetching order history');
      }
    } finally {
      dispatch(clearLoader());
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [skip]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Check if user scrolled to the bottom of the page
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       // Fetch more data if there's more to fetch and not currently loading
  //       if (!loader && hasMore) {
  //         setSkip((prevSkip) => prevSkip + take); // Increase skip on scroll down
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', handleScroll);
  //   // eslint-disable-next-line
  // }, [loader, hasMore, take]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if user scrolled to the bottom of the page
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 1
      ) {
        // Fetch more data if there's more to fetch and not currently loading
        if (!loader && hasMore) {
          setSkip((prevSkip) => prevSkip + take); // Increase skip on scroll down
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loader, hasMore, take]);
  


  const handleReorderClick = (orderData, productData) => {
    if (productData && productData.length > 0) {
      const transformedProductData = productData.map(item => {
        const attributeData = item.attribute_data

        
        return {
          product_id: Number(item.productId),
          product_size: item.size,
          brand_id: orderData.brand_id,
          quantity: Number(item.quantity),
          product_price: Number(item.price),
          product_purchase_price: item.purchase_price ? Number(item.purchase_price) : 0,
          product_instruction: item?.product_instruction && item.product_instruction !== '' && item.product_instruction !== 'null' && item.product_instruction !== 'NULL' ? item.product_instruction : null,
          food_type_one: item?.food_type_1 && item?.food_type_1 !== '' && item?.food_type_1 !== 'null' && item?.food_type_1 !== 'NULL' ? item?.food_type_1 : '0',
          food_type_two: item?.food_type_2 && item?.food_type_2 !== '' && item?.food_type_2 !== 'null' && item?.food_type_2 !== 'NULL' ? item?.food_type_2 : '2',
          attribute_data: attributeData ? attributeData : null //  changed by sakshi
        };
      });
      
      const groupedByBrandId = transformedProductData.reduce((acc, product) => {
        const { brand_id, ...productData } = product;
        let brandEntry = acc.find(entry => entry.brand_id.toString() === brand_id.toString());

        if (!brandEntry) {
          brandEntry = { brand_id: brand_id.toString(), product_data: [] };
          acc.push(brandEntry);
        }

        brandEntry.product_data.push(productData);

        return acc;
      }, []);

      const orderRequestBody = {
        p_table_no: orderData.table_no,
        p_company_id: company,
        p_branch_id: branchId && branchId !== '' ? branchId.toString() : '',
        p_instruction: orderData?.instructions ? orderData.instructions : "",
        p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_delivery_charges: orderData?.delivery_charge && orderData.delivery_charge !== "" && orderData?.delivery_charge ? orderData.delivery_charge
            : null,
        p_discount_id: null,
        p_discount_code: null,
        p_discount_amount: null,
        p_total_amount: orderData?.order_amount,
        p_total_tax_amount: orderData?.total_tax_amount ? orderData.total_tax_amount: null,
        p_orderData: groupedByBrandId,
        p_status: '0',
        p_type: orderData.type,
        p_payment: '0',
        p_address_id: orderData?.address_id ? orderData.address_id : null
      }
      placeOrderService(orderRequestBody, userAuthData?.token, dispatch, 'orderHistory').then(response => {
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          navigate('/thank-you');
        }
      });
    }
    else {
      return;
    }
  }
  
  
  return (
    <>
      <div className="order-history">
        <div className="order-header fixed-top">
          <div className="row">
            <div className="col-12">
              <div className="order-header-detail">
                <Link to={`/`}>
                  <FaChevronLeft />
                </Link>
                <h6>My Orders</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="history-product">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {orderItems && orderItems.length > 0 ? orderItems.map((product, index) => (
                  <React.Fragment key={index}>
                    <OrderComponent
                      orderData={product} 
                      reorderClick={handleReorderClick}
                      brandId={Data?.data}
                    />
                    {/* {loader && (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <img src={GifLoader} alt="Loading..." />
                      </div>
                    )} */}
                  </React.Fragment>
                )) : (
                  <>
                    {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <ClipLoader 
                      size={30}  // Size of the loader
                      color={"#e25233"}  // Color of the loader
                      loading={loader}
                      />
                    </div> : <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>It looks like you haven't ordered yet!</h1>}
                  </>
                )}
                {loader && orderItems && orderItems.length > 0 && 
                <div style={{display:"flex" , justifyContent:"center"}}>
                    <ClipLoader 
                      size={30}  // Size of the loader
                      color={"#e25233"}  // Color of the loader
                      loading={loader}
                      />
                      </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}