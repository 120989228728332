const tableInitialState = { 
    tableNo: ''
};

/**
 * Store table data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TableReducer = (state = tableInitialState, action) => {
    switch (action.type) {
            case 'GET_TABLE_DATA':
                return {
                    ...state,
                    tableNo: action.payload.table
                }
            case 'CLEAR_TABLE_DATA':
                return tableInitialState;
        default:
            return state;
      };
}