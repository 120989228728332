const productInitialState = { 
    items: []
};

/**
 * Store admin user list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalProductReducer = (state = productInitialState, action) => {
    switch (action.type) {
        case 'SET_TOTAL_PRODUCT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_PRODUCT_DATA':
            return productInitialState;
        default:
            return state;
      };
}