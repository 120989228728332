const brandInitialState = { 
    items: '',
    brand_code: '',
    brand_detail: {}
};

/**
 * Store sub-category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const BrandReducer = (state = brandInitialState, action) => {
    switch (action.type) {
            case 'GET_BRAND_DATA':
                return {
                    ...state,
                    items: action.payload.items,
                    brand_code: action.payload.code,
                    brand_detail: action.payload.brand_detail
                }
            case 'CLEAR_BRAND_DATA':
                return brandInitialState;
        default:
            return state;
      };
}

const brandClickInitialState = { 
    items: {}
};

/**
 * Store sub-category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const BrandClickReducer = (state = brandClickInitialState, action) => {
    switch (action.type) {
            case 'GET_BRAND_CLICK_DATA':
                return {
                    ...state,
                    items: action.payload.brand
                }
            case 'CLEAR_BRAND_CLICK_DATA':
                return brandClickInitialState;
        default:
            return state;
      };
}

const brandClickIdInitialState = { 
    items: ''
};

/**
 * Store sub-category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const BrandIdClickReducer = (state = brandClickIdInitialState, action) => {
    switch (action.type) {
            case 'GET_BRAND_ID_DATA':
                return {
                    ...state,
                    items: action.payload.brand
                }
        default:
            return state;
      };
}