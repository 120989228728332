import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import '../SearchBar/SearchBar.scss';
import { Dropdown } from 'react-bootstrap';
import { HiDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";
import LoginPopup from '../LoginPopup/LoginPopup';
import { ValidationMessages } from '../../Utils/Utils';
import { toast } from 'react-toastify';
import { loginAuth } from '../../pages/ReviewOrder/OrderAndLoginService/LoginService/LoginService.tsx';
import * as Yup from 'yup';
import { login } from '../../Redux/Actions/authAction/AuthAction';
import { verifyOtp } from '../../pages/ReviewOrder/OrderAndLoginService/VerifyOtpService/VerifyOtpService.tsx';

const validationSchema = Yup.object({
  firstName: Yup.string().required(ValidationMessages.FIRST_NAME),
  // lastName: Yup.string().required(ValidationMessages.LAST_NAME),
  // email: Yup.string().required(ValidationMessages.EMAIL).max(64, ValidationMessages.MAX_EMAIL_LIMIT).matches(Regex.EmailRegex, ValidationMessages.INVALID_EMAIL),
  number: Yup.string().min(10).required(ValidationMessages.PHONE)
})

export default function SearchBar(props) {

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    // email: '',
    number: '',
    otp: ''
  })

  const { setInputData, inputData } = props;

  const [timer, setTimer] = useState(120); // 2 minutes in seconds
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userAuthData = useSelector(state => state.auth?.user);
  const navigate = useNavigate();
  const [otpButtonVisible, setOtpButtonVisible] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const dispatch = useDispatch();

  const brandDetail = useSelector(state => state.brand?.brand_detail);
  const brandIdClick = useSelector(state => state.brandIdClick?.items);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsLinkActive(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [timer]);

  const handleClick = (values) => {
    if (isLinkActive) {
      handleLogin(values);
      setTimer(120); // Reset the timer to 2 minutes
      setIsLinkActive(false);
    }
  };

  /**
 * Handle login
 */
  const handleLogin = (values) => {
    if (values.firstName === '' ||
      // values.lastName === '' || 
      // values.email === '' || 
      values.number === '') {
      toast.error('Please fill the required data!');
      return;
    }
    // else if (!Regex.EmailRegex.test(values.email)) {
    //   toast.error('Please enter a valid email!');
    // }
    else {
      loginAuth(values, dispatch).then(res => {
        if (res && (res.status === 200 || res.status === '200' || res.status === 'success')) {
          setLoginData(res);
          setOtpButtonVisible(true);
        }
      })
    }
  };

  const handleCloseModal = () => {
    setOtpButtonVisible(false);
    setLoginData(null);
    setTimer(120); // Reset the timer to 2 minutes
    setIsLinkActive(false);
    setShowModal(false);
    setInitialValues({
      firstName: '',
      lastName: '',
      // email: '',
      number: '',
      otp: ''
    })
  }

  const handleShowModal = () => {

    setOtpButtonVisible(false);
    setShowModal(userAuthData?.token ? false : true);
    setInitialValues({
      firstName: '',
      lastName: '',
      // email: '',
      number: '',
      otp: ''
    })
  }

  const onChangeInput = (event) => {
    setInputData(event.target.value);
  }

  const handleNavigate = () => {
    const Data = {
      data: brandDetail?.brand_id ? brandDetail.brand_id : brandIdClick?.brandId ? brandIdClick.brandId : null
    }
    navigate('/order-history', { state: { Data } })
  }

  /**
 * Verify Otp service
 * @param {*} values 
 */
  const handleVerifyOTPButton = (values) => {
    if (values.otp && values.otp.length < 4) {
      toast.warn('Please enter a four digit OTP');
      return;
    }
    verifyOtp(values, dispatch).then(res => {
      if (res && (res.status === 200 || res.status === '200' || res.status === 'success')) {
        dispatch(login(loginData))
        handleCloseModal();
      }
    })
  }

  return (
    <>
      <LoginPopup handleCloseModal={handleCloseModal} initialValues={initialValues} handleLogin={handleLogin} validationSchema={validationSchema} handleVerifyOTPButton={handleVerifyOTPButton} otpButtonVisible={otpButtonVisible} showModal={showModal} timer={timer} isLinkActive={isLinkActive} handleClick={handleClick} />
      <div className='searchbar'>
        <input type="text" placeholder='What are you looking for 🤔 ?' onChange={onChangeInput} value={inputData} />
        <button className='searchbar-btn'>
          <CiSearch />
        </button>
        {inputData !== '' && inputData !== null &&
          <div className='close-icon'>
            <IoIosCloseCircle onClick={() => setInputData('')} />
          </div>}
        <Dropdown >
          {!props?.page && (
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <HiDotsVertical className="dot-icon" />
            </Dropdown.Toggle>
          )}
          {userAuthData?.token ? (
            <>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleNavigate}>My Orders</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => navigate('/profile')}>Profile</Dropdown.Item> */}
              </Dropdown.Menu>
            </>
          ) :
            <>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleShowModal}>Login</Dropdown.Item>
              </Dropdown.Menu>
            </>}
        </Dropdown>
      </div>

    </>
  )
}