/**
 * Update product count
 * @param {*} product 
 * @returns 
 */
export const setProductData = ({company, product}) => ({
    type: 'SET_PRODUCT_DATA',
    payload: {company, product}
})

/**
 * Clear product count
 * @returns 
 */
export const clearProductData = () => ({
    type: 'CLEAR_PRODUCT_DATA'
})

/**
 * Update product order count
 * @param {*} product 
 * @returns 
 */
export const setOrderProductData = ({company, product}) => ({
    type: 'SET_PRODUCT_ORDER_DATA',
    payload: {company, product}
})

/**
 * Clear product order count
 * @returns 
 */
export const clearOrderProductData = () => ({
    type: 'CLEAR_PRODUCT_ORDER_DATA'
})

/**
 * Get product data from api
 * @param {*} product 
 * @returns 
 */
export const getProductData = (product) => ({
    type: 'GET_PRODUCT_DATA',
    payload: product
})

/**
 * Clear product API data from reducer
 * @returns 
 */
export const clearGetProductData = () => ({
    type: 'CLEAR_GET_PRODUCT_DATA'
})

/**
 * Menu Product
 * @returns
 */
export const menuProductData = (product) => ({
    type: 'GET_MENU_PRODUCT',
    parload: product
})

export const clearMenuProductData = () => ({
    type: 'CLEAR_MENU_PRODUCT'
})