import { Form, Formik } from 'formik';
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Input from '../Input/Input';
import { getUserDetailService } from '../../pages/ReviewOrder/OrderAndLoginService/LoginService/LoginService.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function LoginPopup(props) {

    const { handleCloseModal, handleLogin, initialValues, validationSchema, handleVerifyOTPButton, otpButtonVisible, showModal, timer, isLinkActive, handleClick } = props;

    const dispatch = useDispatch();
    const loader = useSelector(state => state.loader?.items);

    const handlePhoneChange = (event, setFieldValue) => {
        const newValue = event.target.value.replace(/[^0-9\s]/g, '');
        if (newValue) {
            const trimmedValue = newValue.slice(0, 10);
            if (trimmedValue.length === 10) {
                getUserDetailService(trimmedValue, dispatch).then(res => {
                    if (res && (res.status === 200 || res.status === '200' || res.status === 'success')) {
                        setFieldValue('firstName', res.data.firstName);
                        setFieldValue('lastName', res.data.lastName);
                        // setFieldValue('email', res.data.email);
                    }
                });
            }
            else {
                setFieldValue('firstName', '');
                setFieldValue('lastName', '');
                // setFieldValue('email', '');
            }
        }
    }

    const handleKeyPress = (event, values) => {
        if (event.key === 'Enter') {
          handleVerifyOTPButton(values);
        }
      };

    return (
      <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Input
                  type="text"
                  name="number"
                  placeholder="Enter your phone number *"
                  className="add-roles"
                  maxLength={10}
                  Regex={/[^0-9\s]/g}
                  hidelength="true"
                  disabled={otpButtonVisible}
                  value={values.number}
                  onInput={(event) => handlePhoneChange(event, setFieldValue)}
                />
                <Input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name *"
                  className="add-roles"
                  maxLength={50}
                  Regex={/[^a-zA-z\s]/g}
                  hidelength="true"
                  disabled={otpButtonVisible || values.number.length < 10}
                  value={values.firstName}
                />
                <Input
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  className="add-roles"
                  maxLength={50}
                  Regex={/[^a-zA-z\s]/g}
                  hidelength="true"
                  disabled={otpButtonVisible || values.number.length < 10}
                  value={values.lastName}
                />
                {/* <Input
                                type="text"
                                name="email"
                                placeholder="Enter your email"
                                className="add-roles"
                                maxLength={64}
                                hidelength="true"
                                disabled={otpButtonVisible || values.number.length < 10}
                                values={values.email}
                            /> */}
                {otpButtonVisible && (
                  <Input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    className="add-roles"
                    maxLength={4}
                    Regex={/[^0-9\s]/g}
                    hidelength="true"
                    value={values.otp}
                    onKeyPress={(event) => handleKeyPress(event, values)}
                  />
                )}

                {!otpButtonVisible && (
                  <Button
                    className="login-btn mt-3"
                    type="submit"
                    disabled={loader}
                  >
                    Get OTP
                  </Button>
                )}
                {otpButtonVisible && (
                  <Button
                    className="login-btn mt-3"
                    type="button"
                    onClick={() => handleVerifyOTPButton(values)}
                    disabled={loader}
                  >
                    Login
                  </Button>
                )}
                {otpButtonVisible && (
                  <div className="otp-actions">
                    {isLinkActive && (
                      <Link
                        className="resend-otp"
                        onClick={() => handleClick(values)}
                      >
                        Resend OTP
                      </Link>
                    )}
                    {!isLinkActive && (
                      <p className="resend-disable-otp">
                        Resend OTP available in {timer} seconds
                      </p>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
}