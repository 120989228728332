import React from 'react'
import'../PmsMaggese/PmsMaggese.scss'
import Profile from "../../assets/images/PMS-TEA.png";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function PmsMaggese() {

  const companyDetail = useSelector(state => state.company?.company_detail);

  return (
    <>
      <div className="msg-pms">
      <div className="profile-img">
        <img src={companyDetail?.company_logo ? `data:image/jpeg;base64,${companyDetail?.company_logo}` : Profile} alt='Product' />
      </div>
      <div className="pms-msg">
            <h6>Hi , User</h6>
            <p>Do you want to place this order ?</p>
            <div className='order-btn-place'>
              <Link to="/">Yes</Link>
              <Link to="/" className='no-btn'>No</Link>
            </div>
        </div>
      </div>
    </>
  )
}
