const categoryInitialState = { 
    items: []
};

/**
 * Store category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const CategoryReducer = (state = categoryInitialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_CATEGORY_DATA':
            return categoryInitialState;
        default:
            return state;
      };
}

const totalCategoriesInitialState = {
    items: []
}

/**
 * Store total category list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalCategoryReducer = (state = totalCategoriesInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_CATEGORY':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_CATEGORY':
            return totalCategoriesInitialState
        default:
            return state;
      };
}