export const setBrand = ({items, code, brand_detail}) => ({
    type: 'GET_BRAND_DATA',
    payload: {items, code, brand_detail}
})

export const clearBrand = () => ({
    type: 'CLEAR_BRAND_DATA'
})

export const setBrandOnClick = (brand) => ({
    type: 'GET_BRAND_CLICK_DATA',
    payload: {brand}
})

export const setBrandIdOnClick = (brand) => ({
    type: 'GET_BRAND_ID_DATA',
    payload: {brand}
})

export const clearBrandOnClick = () => ({
    type: 'CLEAR_BRAND_CLICK_DATA'
})