import React, { useState, useEffect } from 'react';
import correct from "../../assets/images/correct.png";
import "./ThankYou.scss";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ThankYou() {
  const [animate, setAnimate] = useState(false);
  const brandDetail = useSelector(state => state.brand?.brand_detail);
  const brandIdClick = useSelector(state => state.brandIdClick?.items);
  const navigate = useNavigate();

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => {
      setAnimate(false);
      handleNavigate()
    }, 3000); // Automatically navigate after 3 seconds

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [navigate]);

  const handleNavigate = () => {
    const Data = {
      data: brandDetail?.brand_id ? brandDetail.brand_id : brandIdClick?.brandId ? brandIdClick.brandId : null
    }
    navigate('/order-history', {state: {Data}})
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     const Data = {
  //       data: brandDetail?.brand_id ? brandDetail.brand_id : brandIdClick?.brandId ? brandIdClick.brandId : null
  //     }
  //     navigate('/order-history', {state: {Data}})
  //   }, 3000); // 3000 milliseconds = 6 seconds
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="thank-you" onClick={handleNavigate}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={`thank-you-detail ${animate ? 'fade-in' : ''}`}>
              <h5>Thank You! 👩‍🍳🧑‍🍳 🎉</h5>
              <div className={`correct-icon ${animate ? 'bounce' : ''}`}>
                <img src={correct} alt="correct" />
              </div>
              <p>Your Order Has Been Placed Successfully!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
