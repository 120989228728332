import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../../Redux/Actions/LoaderAction/LoaderAction";
import { getApiService } from "../../../../Services/API-services/GetService";
import { API_URL, ValidationMessages, removePersistStorage, toastClass } from "../../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearCompany, setCompany } from "../../../../Redux/Actions/CompantAction";
import { clearBranch, setBranch } from "../../../../Redux/Actions/BranchAction";
import { clearTable } from "../../../../Redux/Actions/TableAction";
import { clearBrand, setBrand } from "../../../../Redux/Actions/BrandAction";

let errorShow = false;
/*
 * Service to get individual order
*/
export const getCompanyByCode = (
    code: string,
    branch_code: string,
    brand_code: string,
    dispatch: Dispatch, navigate?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const queryParams = brand_code ? `?code=${code}&pincode=${branch_code}&brand_code=${brand_code}` : `?code=${code}&pincode=${branch_code}`
            dispatch(setLoader())
            const response = await getApiService(API_URL.BASE_URL + API_URL.COMPANY + queryParams);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) { 
                if(response.data.Result && (response.data.Result === 1003 || response.data.Result === '1003')) {
                    if(navigate) {
                        navigate(`/`);
                    }
                    if (!document.querySelector(toastClass)) {
                        toast.error('Wrong pincode entered!');
                    }
                    return;
                }

                dispatch(setCompany({items: response.data.id, code: response.data.company_code, company_detail: response.data}))
                const branchItems = {
                    branch_name: response.data.branch_name,
                    branch_pincode: response.data.branch_pincode,
                    branch_address: response.data.branch_address,
                    branch_id: response.data.branch_id
                }
                dispatch(setBranch({items: response.data.branch_id, code: response.data.branch_pincode.toString(), branch_detail: branchItems}));

                if(branch_code) {
                    const brandData = {
                        brand_id: response.data.brand_id,
                        brand_tax: response.data.brand_tax,
                        brand_cgst: response.data.brand_cgst,
                        brand_sgst: response.data.brand_sgst,
                        brand_vat: response.data.brand_vat,
                        brand_color: JSON.parse(response.data.brand_color),
                        brand_publicly_available: response.data.brand_publicly_available,
                        brand_food_type: response.data.brand_food_type,
                        brand_name: response.data.brand_name,
                        brand_description: response.data.brand_description,
                        brand_type: response.data.brand_type,
                        brand_logo: response.data.brand_logo,
                        brand_banner: response.data.brand_banner,
                        delivery_available: response.data?.delivery_available,
                        distance_data: response.data?.distance_data ? JSON.parse(response.data.distance_data) : null
                    }
                    
                    dispatch(setBrand({items: response.data.brand_id, code: brand_code, brand_detail: brandData}));
                }
                else {
                    dispatch(clearBrand());
                    if(navigate) {
                        navigate('/');
                    }
                }
                resolve(response);
                // const colorData = JSON.parse(response.data.company_color);
                if(response.data?.brand_color) {
                    const colorData = JSON.parse(response.data.brand_color);
                    const root = document.documentElement;
                    root.style.setProperty('--main-color', colorData.mainColor);
                    root.style.setProperty('--primary-color', colorData.primaryColor);
                }
            }
            else {
                if(navigate) { 
                    navigate('/');
                }
                dispatch(clearCompany());
                dispatch(clearBranch());
                dispatch(clearTable());
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                }
            }
            resolve(response);
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            }
        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};