// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  color: red;
}

.input-chat {
  width: 100%;
  padding: 10px 0;
}
.input-chat::placeholder {
  font-size: 12px;
}

.form-control::placeholder {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AACA;EACI,WAAA;EACA,eAAA;AAEJ;AADI;EACI,eAAA;AAGR;;AACI;EACI,eAAA;AAER","sourcesContent":[".error{\n    color: red;\n}\n.input-chat{\n    width: 100%;\n    padding: 10px 0;\n    &::placeholder{\n        font-size: 12px;\n    }\n}\n.form-control{\n    &::placeholder{\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
