// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.otp-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0 10px 10px 10px;
  width: 100%;
  margin: 10px 0;
  background-color: #EF9C66;
}
.otp-detail p {
  font-size: 12px;
  margin-bottom: 5px;
  color: #FFF;
}
.otp-detail input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.otp-detail button {
  padding: 6px 10px;
  font-size: 14px;
  color: #fff;
  background-color: #e25233;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/OTP/OTP.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAFA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,+BAAA;EACA,WAAA;EACA,cAAA;EACA,yBDDS;ACMb;AAHI;EACE,eAAA;EACA,kBAAA;EACA,WDbO;ACkBb;AAFI;EACE,YAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AAIN;AADI;EACE,iBAAA;EACA,eAAA;EACA,WAAA;EACA,yBDpBU;ECqBV,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AAGN","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import\"../../variables\";\n.otp-detail {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 0 10px 10px 10px; \n    width: 100%;\n    margin: 10px 0;\n    background-color:$pms-chat-bg;\n  \n    p {\n      font-size: 12px;\n      margin-bottom: 5px;\n      color: $white-color;\n    }\n  \n    input {\n      padding: 5px;\n      font-size: 14px;\n      border: 1px solid #ccc;\n      border-radius: 5px;\n      margin-bottom: 10px;\n      width: 100%;\n    }\n  \n    button {\n      padding: 6px 10px;\n      font-size: 14px;\n      color: #fff;\n      background-color: $bespeakk-color;\n      border: none;\n      border-radius: 5px;\n      cursor: pointer;\n      transition: background-color 0.3s;\n\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
