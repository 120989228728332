import { Dispatch } from "redux";
import { GetAllProductRequest, GetAllProductResponse } from "./ProductModal";
import { clearLoader, setLoader } from "../../../Redux/Actions/LoaderAction/LoaderAction";
import { getApiService } from "../../../Services/API-services/GetService";
import { API_URL, ValidationMessages, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { getProductData } from "../../../Redux/Actions/ProductAction/ProductAction";
import { setTotalProductData } from "../../../Redux/Actions/ProductAction/TotalProductAvtion";

let errorShow = false;

/*
 * Service to get product list
*/
export const getAllProductService = (
  productListRequest: GetAllProductRequest, dispatch: Dispatch, total?: any
): Promise<GetAllProductResponse> => {
  return new Promise<GetAllProductResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (productListRequest.p_skip !== undefined && productListRequest.p_skip !== null) {
        queryParams += `p_skip=${productListRequest.p_skip}&`;
      }
      if (productListRequest.p_take !== undefined && productListRequest.p_take !== null) {
        queryParams += `p_take=${productListRequest.p_take}&`;
      }
      if (productListRequest.p_company_id !== undefined && productListRequest.p_company_id !== null) {
        queryParams += `p_company_id=${productListRequest.p_company_id}&`
      }
      if (productListRequest.p_name !== undefined && productListRequest.p_name !== null) {
        queryParams += `p_name=${productListRequest.p_name}&`;
      }
      if (productListRequest.p_brand_id !== undefined && productListRequest.p_brand_id !== null) {
        queryParams += `p_brand_id=${productListRequest.p_brand_id}&`;
      }
      if (productListRequest.p_category_id !== undefined && productListRequest.p_category_id !== null) {
        queryParams += `p_category_id=${productListRequest.p_category_id}&`;
      }
      if (productListRequest.p_sub_category_id !== undefined && productListRequest.p_sub_category_id !== null) {
        queryParams += `p_sub_category_id=${productListRequest.p_sub_category_id}&`;
      }
      if (productListRequest.p_leaf_level_category_id !== undefined && productListRequest.p_leaf_level_category_id !== null) {
        queryParams += `p_leaf_level_category_id=${productListRequest.p_leaf_level_category_id}&`;
      }
      if (productListRequest.p_tag_id !== undefined && productListRequest.p_tag_id !== null) {
        queryParams += `p_tag_id=${productListRequest.p_tag_id}&`;
      }
      if (productListRequest.p_sku_code !== undefined && productListRequest.p_sku_code !== null) {
        queryParams += `p_sku_code=${productListRequest.p_sku_code}&`;
      }
      if (productListRequest.p_is_active !== undefined && productListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${productListRequest.p_is_active}&`
      }
      if (productListRequest.p_IsDeleted !== undefined && productListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${productListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.PRODUCT + (queryParams && queryParams !== '' ? `?${queryParams}` : ''));
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(setTotalProductData(response));
        } 
        else {
          if (total && total === 'filter') {
            toast.success('Filter success!');
            dispatch(getProductData(response));
          }
          else {
            dispatch(getProductData(response));
          }
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Product permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};