import { toast } from "react-toastify";
import { ValidationMessages } from "../../Utils/Utils";

export const putApiService = async (Url, token, bodyData) => {
  try {
    const response = await fetch(Url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }, body: JSON.stringify(bodyData),
    });
    if (!response.ok) {
      const data = await response.json();
      return data;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
  }
}