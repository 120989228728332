import React from 'react'
import Chat from '../../components/Chat/Chat'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import "../ChatOrder/ChatOrder.scss"
import UserMaggese from '../../components/UserMaggese/UserMaggese'
import PmsMaggese from '../../components/PmsMaggese/PmsMaggese'
import OTP from '../../components/OTP/OTP'

export default function ChatOrder() {

    return (
        <>
            <div className="chat-order">
                <div className="order-header fixed-header">
                    <div className="row">
                        <div className="order-header-detail">
                            <Link to={`/`}>
                                <FaChevronLeft />
                            </Link>
                            <h6>PM'S TEAM BAR</h6>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Chat />
                        </div>
                        <div className="user-mdg">
                            <UserMaggese />
                        </div>
                        <div>
                            <PmsMaggese />
                        </div>
                        <div>
                            <OTP></OTP>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
