// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
  background-color: #f1f1f1 !important;
}

.container {
  width: 100%;
  max-width: 991px !important;
  margin: 0 auto;
  padding: 0 5px;
}

.hide-scrollbar {
  overflow: hidden; /* Completely hides both scrollbars */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.accordion {
  background-color: unset !important;
}

.accordion-item {
  background-color: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/App.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAFA;EACI,UAAA;EACA,SAAA;EACA,sBAAA;AAKJ;;AAHA;EACI,SAAA;AAMJ;;AAJA;EACI,gCAAA;AAOJ;;AALA;EACI,SAAA;EACA,oCAAA;AAQJ;;AANA;EACI,WAAA;EACA,2BAAA;EAEA,cAAA;EACA,cAAA;AAQJ;;AANA;EACI,gBAAA,EAAA,qCAAA;AASJ;;AANE;EACE,aAAA,EAAA,uCAAA;AASJ;;AAJA;EACI,kCAAA;AAOJ;;AALA;EACI,kCAAA;AAQJ","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import \"variables\";\n*{\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\nh1,h2,h3,h4,h5,h6{\n    margin: 0;\n}\na{\n    text-decoration: none !important;\n}\nbody {\n    margin: 0;\n    background-color: $offWhite !important;\n}\n.container{\n    width: 100%;\n    max-width: 991px !important;\n    // overflow: hidden;\n    margin: 0 auto;\n    padding: 0 5px;\n}\n.hide-scrollbar {\n    overflow: hidden; /* Completely hides both scrollbars */\n  }\n  \n  .hide-scrollbar::-webkit-scrollbar {\n    display: none; /* Hide scrollbar for WebKit browsers */\n  }\n//   .css-1nmdiq5-menu{\n//     z-index: 999999;\n//   }\n.accordion{\n    background-color: unset !important;\n}\n.accordion-item{\n    background-color: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
