import { Dispatch } from "redux";
import { verifyOtpResponse } from "./VerifyOtpModal";
import { clearLoader, setLoader } from "../../../../Redux/Actions/LoaderAction/LoaderAction";
import { API_URL, ValidationMessages } from "../../../../Utils/Utils";
import { getApiService } from "../../../../Services/API-services/GetService";
import { toast } from "react-toastify";

export const verifyOtp = (otpData: any, dispatch: Dispatch): Promise<verifyOtpResponse> => {
    return new Promise<verifyOtpResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        // Call your login API endpoint

        // const queryParams = `?email=${otpData.email}&otp=${otpData.otp}`
        const queryParams = `?phone_number=${otpData.number}&otp=${otpData.otp}`
  
        const response = await getApiService(API_URL.BASE_URL + API_URL.VERIFY_OTP + queryParams)
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          toast.success('OTP verified successfully!');
          resolve(response)
        }
        else {
            toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      } finally {
        dispatch(clearLoader());
      }
    });
  };