import React from 'react'
import Product from '../Product/Product';
import "../UserMaggese/UserMaggese.scss"
import Profile from "../../assets/images/man.png";

const productData = [
    {
      id: 1,
      name: 'Apna burger',
      price: '50',
      category: 'Sasta Burger',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora provident, consequatur reprehenderit delectus facilis libero est, eos impedit enim culpa eveniet accusamus. Necessitatibus minima, error voluptate vel minus placeat in!'
    }
  ];
export default function UserMaggese() {
  return (
    <>
    <div className="msg-pms user-pms">
      
        <div className="user-maggese">
            <div className="product-history-order">
          <div className="row">
            <div className="col-12">
              {productData.map((product, index) => (
                <Product
                  product={product}
                  key={index}
                  hideImageAndAddButton={true}
                  quantityData={true}
                />
              ))}
            </div>
          </div>
        </div>
        </div>
        <div className="profile-img">
        <img src={Profile} alt='Product' />
      </div>
    </div>
    </>
  )
}
