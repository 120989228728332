const productInitialState = {
    company: '',
    items: []
};

/**
 * Store admin user list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ProductReducer = (state = productInitialState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_DATA':
            return {
                ...state,
                company: action.payload.company,
                items: action.payload.product
            }
        case 'CLEAR_PRODUCT_DATA':
            return productInitialState;
        default:
            return state;
      };
}


const productOrderInitialState = {
    company: '',
    items: []
};

/**
 * Store admin user list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ProductOrderReducer = (state = productOrderInitialState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_ORDER_DATA':
            return {
                ...state,
                company: action.payload.company,
                items: action.payload.product
            }
        case 'CLEAR_PRODUCT_ORDER_DATA':
            return productOrderInitialState;
        default:
            return state;
      };
}

const productAPIInitialState = { 
    items: []
};
export const productAPIReducer = (state = productAPIInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_GET_PRODUCT_DATA':
            return productAPIInitialState;
        default:
            return state;
      };
}

const menuProductInitialState = { 
    items: []
};
export const MenuProductAPIReducer = (state = menuProductInitialState, action) => {
    switch (action.type) {
        case 'GET_MENU_PRODUCT':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_MENU_PRODUCT':
            return menuProductInitialState;
        default:
            return state;
      };
}