// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: rgb(116, 114, 114);
  color: aliceblue;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-width: 300px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/InstructionPopup/InstructionPopup.scss"],"names":[],"mappings":"AAAA,cAAA;AACA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;;AAGE;EACE,8BAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AAAJ","sourcesContent":["/* Popup.css */\n.popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    // background: rgba(0, 0, 0, 0.301);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .popup-content {\n    background: rgb(116, 114, 114);\n    color: aliceblue;\n    padding: 20px;\n    border-radius: 5px;\n    position: relative;\n    min-width: 300px;\n  }\n  \n  .popup-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    border: none;\n    background: none;\n    font-size: 18px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
