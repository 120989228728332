import React from 'react';
import './InstructionPopup.scss'; // Optional: For styling the popup

const InstructionPopup = ({ onClose, instruction }) => {    
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        {instruction}
        {/* <button className="popup-close" onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default InstructionPopup;
