import { Dispatch } from "redux";
import { getDiscountByCodeRequest } from "./DiscountModal";
import { clearLoader, setLoader } from "../../../Redux/Actions/LoaderAction/LoaderAction";
import { API_URL, removePersistStorage, toastClass, ValidationMessages } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { postApiService } from "../../../Services/API-services/PostService";
import { discountDataByCode } from "../../../Redux/Actions/DiscountAction/DiscountAction";

let errorShow = false;

/*
 * Service to get category list
*/
export const postDiscountByCodeService = (
    getDiscountRequest: getDiscountByCodeRequest, token: any, dispatch: Dispatch, discountBool?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            const response = await postApiService(API_URL.BASE_URL + API_URL.DISCOUNT, token, getDiscountRequest);
            
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                if(discountBool && discountBool === true) {
                    resolve(response);
                }
                else {
                    dispatch(discountDataByCode(response.data));
                    resolve(response);
                }
                dispatch(discountDataByCode(response.data));
                resolve(response);
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Address permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                }
                // dispatch(clearDiscountDataByCode());
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                if (!document.querySelector(toastClass)) {
                    toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
                }
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};