import { Dispatch } from "redux";
import { AddAddressRequest, GetAddressListRequest } from "./AddressModal";
import { clearLoader, setLoader } from "../../../Redux/Actions/LoaderAction/LoaderAction";
import { getApiService } from "../../../Services/API-services/GetService";
import { API_URL, removePersistLoginStorage, removePersistStorage, toastClass, ValidationMessages } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearAddressData, getAddressData } from "../../../Redux/Actions/AddressAction/AddressAction";
import { postApiService } from "../../../Services/API-services/PostService";
import { putApiService } from "../../../Services/API-services/PutService";

let errorShow = false;

/*
 * Service to get category list
*/
export const getAddressListService = (
    getAddressListRequest: GetAddressListRequest, token: any, dispatch: Dispatch
): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            let queryParams = '';
            // Adding query parameters if they are provided
            if (getAddressListRequest.p_skip !== undefined && getAddressListRequest.p_skip !== null) {
                queryParams += `p_skip=${getAddressListRequest.p_skip}&`;
            }
            if (getAddressListRequest.p_limit !== undefined && getAddressListRequest.p_limit !== null) {
                queryParams += `p_limit=${getAddressListRequest.p_limit}&`;
            }
            if (getAddressListRequest.p_postal_code !== undefined && getAddressListRequest.p_postal_code !== null) {
                queryParams += `p_postal_code=${getAddressListRequest.p_postal_code}&`
            }
            if (getAddressListRequest.p_city !== undefined && getAddressListRequest.p_city !== null) {
                queryParams += `p_city=${getAddressListRequest.p_city}&`
            }
            if (getAddressListRequest.p_IsDeleted !== undefined && getAddressListRequest.p_IsDeleted !== null) {
                queryParams += `p_IsDeleted=${getAddressListRequest.p_IsDeleted}&`;
            }
            const response = await getApiService(API_URL.BASE_URL + API_URL.ADDRESS + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {

                dispatch(getAddressData(response.data));
            }
            else {
                if(response.message === 'No token found in header.') {
                   
                }
                else {
                    if (!document.querySelector(toastClass)) {
                        toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Address permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
                    }
                    errorShow = true;
                    if (response?.message && response.message === 'Unauthorized access!') {
                        removePersistStorage(dispatch);
                    }
                }
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};

export const addAddressService = (
    bodyData: AddAddressRequest, token: string,
    dispatch: Dispatch, setAddFormOpen?: any, setEditAddressData?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            dispatch(setLoader());
            const response = await postApiService(API_URL.BASE_URL + API_URL.ADDRESS, token, bodyData)
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                toast.success('Address added successfully');
                dispatch(clearAddressData());
                if(setAddFormOpen) {
                    setAddFormOpen(false);
                }
                if(setEditAddressData) {
                    setEditAddressData(false);
                }
                resolve(response);
            }
            else {
                if (response.message === 'Unauthorized access!') {
                    toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
                    removePersistLoginStorage(dispatch);
                }
                else {
                    toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
                }
            }
        } catch (error) {
            toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
        } finally {
            dispatch(clearLoader());
        }
    });
};

export const updateAddressService = (
    bodyData: AddAddressRequest, token: string,
    dispatch: Dispatch, setAddFormOpen?: any, setEditAddressData?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            dispatch(setLoader());
            const response = await putApiService(API_URL.BASE_URL + API_URL.ADDRESS, token, bodyData)
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearAddressData());
                if(setAddFormOpen === 'delete') {
                    toast.success('Address deleted successfully');
                }
                else {
                    toast.success('Address updated successfully');
                    if(setAddFormOpen) {
                        setAddFormOpen(false);
                    }
                }
                if(setEditAddressData) {
                    setEditAddressData(false);
                }
                resolve(response);
            }
            else {
                if (response.message === 'Unauthorized access!') {
                    toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
                    removePersistLoginStorage(dispatch);
                }
                else {
                    toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
                }
            }
        } catch (error) {
            toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
        } finally {
            dispatch(clearLoader());
        }
    });
};