/**
 * Setting user data into reducer after login
 * @param {*} user 
 * @param {*} rememberMe 
 * @param {*} loginTime 
 * @returns {*}
 */
export const login = (user) => ({
    type: 'LOGIN',
    payload: { user },
});
    
/**
 * action for logout user
 * @returns {*}
 */
export const logout = () => ({
    type: 'LOGOUT',
}); 

/**
 * Action for login fail, if failed to login!
 * @returns {*}
 */
export const loginFail = () =>({
    type: 'LOGIN_FAILURE',
})