import React, { useEffect } from 'react'
import '../Header/Header.scss'
import Logo from "../../assets/images/Bespeakk-logo.png";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrandService } from '../../pages/HomePage/BrandServices/BrandService';
import { setBrandIdOnClick } from '../../Redux/Actions/BrandAction';
import { API_URL } from '../../Utils/Utils';
import { getAllProductService } from '../Product/ProductService/ProductService';
import { getAllCategoryService } from '../../pages/HomePage/Home-services/CategoryService/CategoryService';

export default function Header() {
  const companyCode = useSelector(state => state.company?.company_code);
  const company = useSelector((state) => state.company?.items);

  const brandDetail = useSelector(state => state.brand?.brand_detail);
  const brand_code = useSelector(state => state.brand?.brand_code);

  const branchCode = useSelector(state => state.branch?.branch_code);
  const brandClick =useSelector(state => state.brandClick?.items);
  const brandIdClick = useSelector(state => state.brandIdClick?.items);

  const productFilter = useSelector((state) => state.productFilter);
  const categoryFilter = useSelector((state) => state.categoryFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBrands = () => {
      if ((!brand_code || brand_code === '') && companyCode && companyCode !== '' && branchCode && branchCode !== '' && (!brandClick?.data)) {
        const requestBody = {
          p_skip: 0,
          p_limit: 1000,
          p_company_code: companyCode,
          p_branch_pincode: branchCode,
          p_is_active: '1'
        };
        getAllBrandService(requestBody, dispatch).then((res) => {
          if (res?.data && res.data.length > 0) {
            dispatch(setBrandIdOnClick(res.data[0]));
            fetchProduct(res.data[0].brandId);
            fetchCategory(res.data[0].brandId);
            const colorData = res.data[0]?.color ? JSON.parse(res.data[0]?.color) : null;
            const root = document.documentElement;
            if(colorData) {
              root.style.setProperty('--main-color', colorData.mainColor);
              root.style.setProperty('--primary-color', colorData.primaryColor);
            }
          }
        });
      }
      // else {
      //   dispatch(clearBrandOnClick());
      // }
    };

    fetchBrands();
    // eslint-disable-next-line
  }, [brand_code, companyCode, branchCode])

  /**
   * Fetch product data
   */
    const fetchProduct = (brandId) => {
      const requestBody = {
        p_skip: 0,
        p_take: 5000,
        p_company_id: company,
        p_brand_id: brandId
      };
      if (productFilter.name !== "" && productFilter.name !== null) {
        requestBody.p_name = productFilter.name;
      }
      if (
        productFilter.category !== "" &&
        productFilter.category !== null &&
        productFilter.category.length > 0
      ) {
        requestBody.p_category_id = productFilter.category;
      }
      if (
        productFilter.subCategory !== "" &&
        productFilter.subCategory !== null &&
        productFilter.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id = productFilter.subCategory;
      }
      if (
        productFilter.leafCategory !== "" &&
        productFilter.leafCategory !== null &&
        productFilter.leafCategory.length > 0
      ) {
        requestBody.p_leaf_level_category_id = productFilter.leafCategory;
      }
      if (
        productFilter.tags !== "" &&
        productFilter.tags !== null &&
        productFilter.tags.length > 0
      ) {
        requestBody.p_tag_id = productFilter.tags.join(",");
      }
      if (productFilter.isActive !== "" && productFilter.isActive !== null) {
        requestBody.p_is_active = productFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
        requestBody.p_IsDeleted = productFilter.isDelete;
      }
      getAllProductService(requestBody, dispatch);
    };

    const fetchCategory = (brandId) => {
      const requestBody = {
        p_skip: 0,
        p_take: 5000,
        p_company_id: company,
        p_brand_id: brandId,
        p_type: "0",
      };
      if (categoryFilter.name !== "" && categoryFilter.name !== null) {
        requestBody.p_name = categoryFilter.name;
      }
      if (categoryFilter.code !== "" && categoryFilter.code !== null) {
        requestBody.p_category_code = categoryFilter.code;
      }
  
      if (categoryFilter.isActive !== "" && categoryFilter.isActive !== null) {
        requestBody.p_is_active = categoryFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
  
      if (categoryFilter.isDelete !== "" && categoryFilter.isDelete !== null) {
        requestBody.p_IsDeleted = categoryFilter.isDelete;
      }
      getAllCategoryService(requestBody, dispatch);
    };
  

  const brandFunction = (item) => {
    fetchProduct(item?.brandId);
    fetchCategory(item?.brandId);
    dispatch(setBrandIdOnClick(item));

    const colorData = JSON.parse(item?.color);
    const root = document.documentElement;
    root.style.setProperty('--main-color', colorData.mainColor);
    root.style.setProperty('--primary-color', colorData.primaryColor);
  }

  return (
    <>
        <div className='header-logo'>
          {brand_code && branchCode !== '' ? (
            // <Link to={`/`}><img src={companyDetail?.company_logo ? `data:image/jpeg;base64,${companyDetail?.company_logo}` : Logo} alt={companyDetail?.name} /></Link>
            <Link to={`/`}><img src={brandDetail?.brand_logo ? API_URL.TOKEN_URL+brandDetail?.brand_logo : Logo} alt={brandDetail?.brand_name} /></Link>
          ) : (
            <>
            {brandClick && brandClick?.data && brandClick.data.length > 0 && (
              brandClick.data.map((item, index) => (
                <Link key={index} 
                onClick={() => brandFunction(item)} 
                >
                  <img 
                  src={item?.brand_logo ? API_URL.TOKEN_URL+item?.brand_logo : Logo} 
                  alt={item?.name} 
                  className={
                    (brandClick.data.length > 1) &&
                    (brandIdClick?.brandId
                      ? brandIdClick.brandId === item?.brandId
                      : index === 0
                    )
                      ? 'activeBrand'
                      : ''
                  }/>
                  {brandClick.data.length > 1 && (
                    <p className='name-class'>{item?.name}</p>
                  )}
                  </Link>
              ))
            )}
            </>
          )}
        </div>
    </>
  )
}
