import { Form, Formik } from 'formik';
import React from 'react';
import { FaHome } from 'react-icons/fa';
import { HiMiniBuildingOffice } from 'react-icons/hi2';
import { PiDotsThreeCircleFill } from 'react-icons/pi';
import Input from '../Input/Input';
import { Button, Dropdown, Offcanvas } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import { HiDotsVertical } from 'react-icons/hi';
import { MdDeleteForever, MdModeEdit, MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import '../AddressList/AddressList.scss';
import { ValidationMessages } from '../../Utils/Utils';
import { getAddressFromPincode } from '../../Services/API-services/AddressService';

export default function AddressList(props) {

    const { showAddressModal, handleAddressModal,
        addFormOpen, editAddressData, onBackClick,
        handleAddressChange, onEditClick,
        setAddFormOpen, addressInititalValues,
        handleAddUpdateAddress, addressValidationSchema,
        addressData, setAddressValidationSchema, setDeleteAddress } = props;

    

    const address = useSelector(state => state.address?.items);
    const userAuthData = useSelector(state => state.auth?.user);
    const loader = useSelector(state => state.loader?.items);

    const dispatch = useDispatch();

    const setTitleValue = (setFieldValue, checkData) => {
        setFieldValue('title', checkData)
        if (checkData === 'Other') {
            setAddressValidationSchema(Yup.object({
                title: Yup.string().required(ValidationMessages.TITLE),
                address1: Yup.string().required(ValidationMessages.ADDRESS),
                address2: Yup.string().required(ValidationMessages.LANDMARK),
                city: Yup.string().required(ValidationMessages.CITY),
                pin_code: Yup.string().required(ValidationMessages.PIN_CODE),
                otherInput: Yup.string().required(ValidationMessages.TITLE)
            }))
        }
        else {
            setAddressValidationSchema(Yup.object({
                title: Yup.string().required(ValidationMessages.TITLE),
                address1: Yup.string().required(ValidationMessages.ADDRESS),
                address2: Yup.string().required(ValidationMessages.LANDMARK),
                city: Yup.string().required(ValidationMessages.CITY),
                pin_code: Yup.string().required(ValidationMessages.PIN_CODE)
            }))
            setFieldValue('otherInput', '');
        }
    }

    const onChangePincode = (pincode, setFieldValue) => {
        if (pincode && pincode !== '' && pincode.length > 5) {
            getAddressFromPincode(pincode, dispatch).then(res => {
                if (res && res.length > 0) {
                    if (res[0]?.Status === 'Success') {
                        if (res[0]?.PostOffice && res[0].PostOffice.length > 0) {
                            const postOfficeData = res[0].PostOffice[0]?.Block && res[0].PostOffice[0].Block !== '' ? res[0].PostOffice[0].Block : res[0].PostOffice[0].District;
                            setFieldValue("city", postOfficeData)
                        }
                        else {
                            setFieldValue("city", '')
                        }
                    }
                    else {
                        setFieldValue("city", '')
                    }
                }
                else {
                    setFieldValue("city", '')
                }
            });
        }
        else {
            setFieldValue("city", '')
        }
    }

    return (
        <>
            <Offcanvas show={showAddressModal} onHide={() => handleAddressModal(false)} placement="bottom" backdrop="static" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="w-100">
                        {addFormOpen && editAddressData ? <><MdOutlineKeyboardBackspace className="back-icon" onClick={onBackClick} /> Edit Address</> : addFormOpen ? <><MdOutlineKeyboardBackspace className="back-icon" onClick={onBackClick} /> Add Address</> : 'Address List'}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="new-add-model">
                    {!addFormOpen ? (
                        <>
                            {address && address.length > 0 ? address.map((item, index) => (
                                <React.Fragment key={index}>
                                    <label className={`action-label ${addressData === item.addressId ? 'active' : ''}`}>
                                        <input
                                            type="radio"
                                            name={`address-${item.addressId}`}
                                            value={item.addressId}
                                            checked={addressData === item.addressId}
                                            onChange={() => handleAddressChange(item)}
                                        />&nbsp;
                                        <div className="address-box">
                                            {/* <span><b>Name:</b> {userAuthData?.data?.firstName} {userAuthData?.data?.lastName}</span> */}
                                            <span><b>Title:</b> {item.title}</span>
                                            <span><b>Address:</b> {item.address_line1}, {item.address_line2}, {item.city}, {item.postal_code}</span>
                                            <span><b>Phone Number:</b> {userAuthData?.data?.phoneNumber}</span>
                                        </div>
                                        &nbsp;
                                        <Dropdown className="no-backgound-dropdown">
                                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                <HiDotsVertical className="dot-icon-order" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => onEditClick(item)}><MdModeEdit className="delete-address" /> Edit</Dropdown.Item>

                                                {/* <Dropdown.Item onClick={() => onDeleteClick(item)}><MdDeleteForever className="delete-address" /> Delete</Dropdown.Item> */}

                                                <Dropdown.Item onClick={() => setDeleteAddress(item)}><MdDeleteForever className="delete-address" /> Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </label>
                                </React.Fragment>
                            )) : <b>No address found!</b>}
                           <div>
                           <Button className="btn-fillter-main" onClick={() => setAddFormOpen(true)}>
                                <FaPlus /> Add New Address
                            </Button>
                           </div>
                        </>
                    ) :
                        (
                            <>
                                <Formik initialValues={addressInititalValues} enableReinitialize={true} onSubmit={handleAddUpdateAddress} validationSchema={addressValidationSchema}>
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <div className="radio-btn">
                                                <label className={`radio-label ${values.title === 'Home' ? 'active' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        name="title-home"
                                                        value="Home"
                                                        checked={values.title === 'Home'}
                                                        // onChange={() => setFieldValue('title', 'Home')}
                                                        onChange={() => setTitleValue(setFieldValue, 'Home')}
                                                    />

                                                    &nbsp;<FaHome />Home
                                                </label>

                                                <label className={`radio-label ${values.title === 'Office' ? 'active' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        name="title-office"
                                                        value="Office"
                                                        checked={values.title === 'Office'}
                                                        // onChange={() => setFieldValue('title', 'Office')}
                                                        onChange={() => setTitleValue(setFieldValue, 'Office')}
                                                    />
                                                    &nbsp;<HiMiniBuildingOffice />Office
                                                </label>

                                                <label className={`radio-label ${values.title === 'Other' ? 'active' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        name="title-other"
                                                        value="Other"
                                                        checked={values.title === 'Other'}
                                                        // onChange={() => setFieldValue('title', 'Other')}
                                                        onChange={() => setTitleValue(setFieldValue, 'Other')}
                                                    />
                                                    &nbsp;<PiDotsThreeCircleFill />Other
                                                </label>
                                            </div>
                                            {values.title === 'Other' && (
                                                <Input
                                                    type="text"
                                                    name="otherInput"
                                                    placeholder="Enter other title *"
                                                    className="add-roles"
                                                    hidelength="true"
                                                    maxLength={70}
                                                    value={values.otherInput}
                                                />
                                            )}
                                            <Input
                                                type="text"
                                                name="address1"
                                                placeholder="Enter address *"
                                                className="add-roles"
                                                hidelength="true"
                                                maxLength={70}
                                                value={values.address1}
                                            />
                                            <Input
                                                type="text"
                                                name="address2"
                                                placeholder="Enter landmark *"
                                                className="add-roles"
                                                hidelength="true"
                                                maxLength={70}
                                                value={values.address2}
                                            />
                                            <Input
                                                type="text"
                                                name="pin_code"
                                                placeholder="Enter pincode *"
                                                className="add-roles"
                                                maxLength={6}
                                                Regex={/[^0-9\s]/g}
                                                hidelength="true"
                                                value={values.pin_code}
                                                onInput={(event) => onChangePincode(event.target.value, setFieldValue)}
                                            />
                                            <Input
                                                type="text"
                                                name="city"
                                                placeholder="Enter city *"
                                                className="add-roles"
                                                hidelength="true"
                                                maxLength={25}
                                                value={values.city}
                                                disabled={!values.pin_code || values.pin_code === '' || values.pin_code.length < 6 || loader}
                                            />
                                            <Button className="login-btn mt-3" type="submit" disabled={loader === true}>
                                                Save
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}