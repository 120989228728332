import { Dispatch } from "redux";
import { getAllBrandRequest, getAllBrandResponse } from "./BrandModal";
import { clearLoader, setLoader } from "../../../Redux/Actions/LoaderAction/LoaderAction";
import { getApiService } from "../../../Services/API-services/GetService";
import { API_URL, toastClass, ValidationMessages } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { setBrandOnClick } from "../../../Redux/Actions/BrandAction";
import { logout } from "../../../Redux/Actions/authAction/AuthAction";

let errorShow = false;

/*
 * Service to get brand list
*/
export const getAllBrandService = (
    brandListRequest: getAllBrandRequest, dispatch: Dispatch
): Promise<getAllBrandResponse> => {
    return new Promise<getAllBrandResponse>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            let queryParams = '';
            // Adding query parameters if they are provided
            if (brandListRequest.p_skip !== undefined && brandListRequest.p_skip !== null) {
                queryParams += `p_skip=${brandListRequest.p_skip}&`;
            }
            if (brandListRequest.p_limit !== undefined && brandListRequest.p_limit !== null) {
                queryParams += `p_limit=${brandListRequest.p_limit}&`;
            }
            if (brandListRequest.p_company_code !== undefined && brandListRequest.p_company_code !== null) {
                queryParams += `p_company_code=${brandListRequest.p_company_code}&`
            }
            if (brandListRequest.p_branch_pincode !== undefined && brandListRequest.p_branch_pincode !== null) {
                queryParams += `p_branch_pincode=${brandListRequest.p_branch_pincode}&`
            }
            if (brandListRequest.p_name !== undefined && brandListRequest.p_name !== null) {
                queryParams += `p_name=${brandListRequest.p_name}&`;
            }
            if (brandListRequest.p_category_id !== undefined && brandListRequest.p_category_id !== null) {
                queryParams += `p_category_id=${brandListRequest.p_category_id}&`;
            }
            if (brandListRequest.p_sub_category_id !== undefined && brandListRequest.p_sub_category_id !== null) {
                queryParams += `p_sub_category_id=${brandListRequest.p_sub_category_id}&`;
            }
            if (brandListRequest.p_leaf_level_category_id !== undefined && brandListRequest.p_leaf_level_category_id !== null) {
                queryParams += `p_leaf_level_category_id=${brandListRequest.p_leaf_level_category_id}&`;
            }
            if (brandListRequest.p_tag_id !== undefined && brandListRequest.p_tag_id !== null) {
                queryParams += `p_tag_id=${brandListRequest.p_tag_id}&`;
            }
            if (brandListRequest.p_is_active !== undefined && brandListRequest.p_is_active !== null) {
                queryParams += `p_is_active=${brandListRequest.p_is_active}&`
            }
            if (brandListRequest.p_IsDeleted !== undefined && brandListRequest.p_IsDeleted !== null) {
                queryParams += `p_IsDeleted=${brandListRequest.p_IsDeleted}&`
            }
            if (brandListRequest.p_type !== undefined && brandListRequest.p_type !== null) {
                queryParams += `p_type=${brandListRequest.p_type}&`
            }
            const response = await getApiService(API_URL.BASE_URL + API_URL.BRANDS + (queryParams && queryParams !== '' ? `?${queryParams}` : ''));
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {

                dispatch(setBrandOnClick(response));
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Brand permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    dispatch(logout());
                }
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};