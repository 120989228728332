import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../../../Redux/Actions/LoaderAction/LoaderAction";
import { postApiService } from "../../../../../Services/API-services/PostService";
import { API_URL, ValidationMessages, removePersistLoginStorage, removePersistProductStorage } from "../../../../../Utils/Utils";
import { toast } from "react-toastify";
import { placeOrderRequest, updateOrderRequest } from "./PlaceOrderService";
import { putApiService } from "../../../../../Services/API-services/PutService";

export const placeOrderService = (
    bodyData: placeOrderRequest, token: string,
    dispatch: Dispatch, page?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());

        const response = await postApiService(API_URL.BASE_URL + API_URL.PLACE_ORDER, token, bodyData)
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          if(page && page === 'orderHistory'){
            resolve(response);
          }
          else {
            removePersistProductStorage(dispatch);
          }
          resolve(response);
        }
        else {
          if (response.message === 'Unauthorized access!') {
            // toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            removePersistLoginStorage(dispatch);
          }
          else {
            toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
          }
        }
      } catch (error) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      } finally {
        dispatch(clearLoader());
      }
    });
  };

  export const updateOrderService = (
    bodyData: updateOrderRequest, token: string,
    dispatch: Dispatch, page?: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        const response = await putApiService(API_URL.BASE_URL + API_URL.UPDATE_ORDER, token, bodyData)
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          if(page && page === 'orderHistory'){
            resolve(response);
          }
          else{
            removePersistProductStorage(dispatch);
          }
          resolve(response);
        }
        else {
          if (response.message === 'Unauthorized access!') {
            // toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
            removePersistLoginStorage(dispatch);
          }
          else {
            toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
          }
        }
      } catch (error) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      } finally {
        dispatch(clearLoader());
      }
    });
  };