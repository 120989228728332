import React, { useEffect, useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import '../Detail-container/DetailContainer.scss';
import { parseImage } from '../../Services/Regular-services/ImageService';
import { useSelector } from 'react-redux';

export default function DetailContainer(props) {
  const {
    selectedProduct,
    handleCloseOffcanvas,
    showOffcanvas,
    imageData,
    handleDecrease,
    selectedProductQuantity,
    handleQuantityChange,
    handleIncrease,
    addProductFromDetail,
    // parsedAttributes,
    setParsedAttributes,
    totalPrize,
    setTotalPrize,
    page = false
  } = props;


  const addedProducts = useSelector((state) => state.product?.items);
  const productOrder = useSelector((state) => state.productOrder?.items);

  const choicesContainerRef = useRef(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  useEffect(() => {
    if (selectedProduct.attribute_data) {
      const parsedData = parseImage(selectedProduct.attribute_data);
      setParsedAttributes(parsedData);
      initializeSelectedAttributes(parsedData);

      // changes by sakshi
      setTimeout(() => {
        if (choicesContainerRef.current) {
          choicesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    } else {
      setParsedAttributes([]);
      setSelectedAttributes({});
    }
    // eslint-disable-next-line
  }, [selectedProduct, addedProducts, productOrder]);


  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    let newTotalPrize = parseFloat(selectedProduct.price) || 0;
    Object.values(selectedAttributes).forEach(attr => {
      attr.map(data => {
        const numericValue = parseFloat(data.value);
        if (!isNaN(numericValue)) {
          newTotalPrize += numericValue;
        }
      })
    });
    setTotalPrize(newTotalPrize);
    // eslint-disable-next-line
  }, [selectedAttributes, selectedProduct.price]);

  useEffect(() => {
    if (selectedProduct?.attribute_data) {
      const attrData = parseImage(selectedProduct?.attribute_data);
      const data = groupByCategory(attrData);
      setGroupedData(data);
    }
  }, [selectedProduct?.attribute_data]);

  const groupByCategory = (products) => {
    return products.reduce((acc, product) => {
      const categories = product.cat_name
        .split(",")
        .map(category => category.trim());
      categories.forEach(category => {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(product);
      });
      return acc;
    }, {});
  };

  /**
   * Return true if json is empty
   * @param {*} obj 
   * @returns 
   */
  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const initializeSelectedAttributes = (data) => {
    const initialSelected = {};
    const groupedAttributes = data.reduce((acc, attr) => {
      if (!acc[attr.cat_name]) {
        acc[attr.cat_name] = [];
      }
      acc[attr.cat_name].push(attr);
      return acc;
    }, {});

    // if (grou) {
      
    // }
    Object.keys(groupedAttributes).forEach(cat_name => {

      const filteredAttributes = groupedAttributes[cat_name].filter(attr => attr.cat_selection_type === "1");
      
      if (filteredAttributes.length > 0) {  
        const firstOption=filteredAttributes.find(attr => attr.value === "0" || attr.value === 0);
         if (firstOption!==undefined && firstOption) {
          initialSelected[cat_name] = [{
            id: firstOption.id,
            name: firstOption.name,
            value: firstOption.value,
            cat_id: firstOption.cat_id,
            cat_name: firstOption.cat_name,
            cat_selection_type: firstOption.cat_selection_type
          }];
         } 
      }
    });
    const isOrderHistory = page && page === 'orderHistory';
    const currentOrder = isOrderHistory ? productOrder : addedProducts;
    const attributeProduct = currentOrder && currentOrder.length > 0 ? currentOrder.find(item => item.productId === selectedProduct.productId) : null;
    if (attributeProduct) {
      if (attributeProduct?.selectedAttributes && !isObjectEmpty(attributeProduct.selectedAttributes) && attributeProduct.selectedAttributes !== '') {
        setSelectedAttributes(attributeProduct.selectedAttributes)
      }
      else {
        setSelectedAttributes(initialSelected);
      }
    }
    else {
      setSelectedAttributes(initialSelected);
    }
  };

  const handleRadioChange = (id, name, value, cat_id, cat_name, cat_selection_type) => {
    setSelectedAttributes(prevAttributes => ({
      ...prevAttributes,
      [cat_name]: [{
        id,
        name,
        value,
        cat_id,
        cat_name,
        cat_selection_type
      }]
    }));
  };

  const handleCheckboxChange = (event, id, name, value, cat_id, cat_name, cat_selection_type) => {
    const { checked } = event.target;
    setSelectedAttributes(prevAttributes => {
      const categoryAttributes = Array.isArray(prevAttributes[cat_name]) ? prevAttributes[cat_name] : [];

      if (checked) {
        // Add item if checkbox is checked
        return {
          ...prevAttributes,
          [cat_name]: [
            ...categoryAttributes,
            {
              id,
              name,
              value,
              cat_id,
              cat_name,
              cat_selection_type
            }
          ]
        };
      } else {
        // Remove item if checkbox is unchecked
        return {
          ...prevAttributes,
          [cat_name]: categoryAttributes.filter(item => item.id !== id)
        };
      }
    });
  };



  return (
    <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="bottom">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="w-100">
          <div className="d-flex justify-content-between">
            <span>{selectedProduct.name}</span>
            <span>₹{totalPrize}</span>
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="product-details">
          <div className="prodect-img-new">
            <img className="product-img" src={imageData} alt={selectedProduct.name} />
          </div>

          <p className='product-description'>{selectedProduct.description && typeof selectedProduct.description === 'string' ? new DOMParser().parseFromString(selectedProduct.description, 'text/html').body.innerText : selectedProduct.description}</p>

          <div ref={choicesContainerRef}>
            {groupedData && (
              <div className='attr-main-container'>
                {
                  selectedProduct.attribute_data && (
                    <p>Select your choices</p>
                  )
                }

                <div className='attr-container'>
                  {Object.keys(groupedData).map((cat_name, index) => (
                    <div key={index}>
                      <p className='cat-name'>{cat_name}</p>
                      <div>
                        {groupedData[cat_name].map(attr => (
                          attr.cat_selection_type === '1' ? (
                            <div key={attr.id} className="attr-row">
                              <label className='attr-name-label' htmlFor={attr.id}>{attr.name}</label>
                              <div>
                                <label className='attr-name-label' htmlFor={attr.id}>₹{attr.value}</label>
                                <input
                                  type='radio'
                                  id={attr.id}
                                  value={attr.value}
                                  checked={Array.isArray(selectedAttributes[attr.cat_name]) &&
                                    selectedAttributes[attr.cat_name].some(item => item.id === attr.id)}
                                  onChange={() => handleRadioChange(attr.id, attr.name, attr.value, attr.cat_id, attr.cat_name, attr.cat_selection_type)}
                                />
                              </div>
                            </div>
                          ) : (
                            <div key={attr.id} className="attr-row">
                              <label className='attr-name-label' htmlFor={attr.id}>{attr.name}</label>
                              <div>
                                <label className='attr-name-label' htmlFor={attr.id}>₹{attr.value}</label>
                                <input
                                  type="checkbox"
                                  id={attr.id}
                                  name={attr.name}
                                  value={attr.value}
                                  checked={Array.isArray(selectedAttributes[attr.cat_name]) &&
                                    selectedAttributes[attr.cat_name].some(item => item.id === attr.id)}
                                  onChange={(e) => handleCheckboxChange(e, attr.id, attr.name, attr.value, attr.cat_id, attr.cat_name, attr.cat_selection_type)}
                                />
                              </div>
                            </div>
                          )
                        ))}

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>


          <div className="quantity-add-container">
            <div className="quantity-input">
              <button onClick={handleDecrease} className="quantity-button"><span>-</span></button>
              <input
                type="text"
                value={selectedProductQuantity}
                onChange={handleQuantityChange}
                disabled={true}
                min="1"
                className="quantity-field"
              />
              <button onClick={handleIncrease} className="quantity-button"><span>+</span></button>
            </div>
            <button onClick={() => addProductFromDetail(totalPrize, selectedAttributes)} className="add-button-home">Add item</button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
