import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../Invoice/Invoice.scss";
import { format } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import Logo from "../../assets/images/Bespeakk-logo.png";
import { MdDownload } from "react-icons/md";
import { API_URL } from "../../Utils/Utils";

const Invoice = forwardRef(() => {
  const location = useLocation();
  const { Data } = location.state || {};
  const [productData, setProductData] = useState([]);
  const invoiceRef = useRef();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (Data && Data.productData && Data.productData.length > 0) {
      const cleanProductData = Data.productData.map((product) => ({
        ...product,
        productName: product.productName, // Remove leading and trailing single quotes
      }));

      setProductData(cleanProductData);
    } else {
      setProductData([]);
    }
    // eslint-disable-next-line
  }, [Data]);

  const [totalWithoutTax, setTotalWithoutTTax] = useState(0);

  useEffect(() => {
    if (productData && productData.length > 0) {
      const totalPrice = productData.reduce((sum, product) => {
        const price = parseFloat(
          product.price === "NULL" || product.price === "null"
            ? 0
            : product.price
        );
        const quantity = Number(product.quantity);
        return sum + price * quantity;
      }, 0);

      setTotalWithoutTTax(totalPrice);

      const cgst =
        Data?.orderData?.brand_tax_type === "GST"
          ? (totalPrice * parseFloat(Data?.orderData?.brand_cgst)) / 100
          : 0;
      const sgst =
        Data?.orderData?.brand_tax_type === "GST"
          ? (totalPrice * parseFloat(Data?.orderData?.brand_sgst)) / 100
          : 0;
      const vat =
        Data?.orderData?.brand_tax_type === "VAT"
          ? (totalPrice * parseFloat(Data?.orderData?.brand_vat)) / 100
          : 0;
      // const taxAmount = totalPrice * taxRate;
      const totalPriceWithTax = totalPrice + cgst + sgst + vat;
      setTotalPrice(totalPriceWithTax);
    }
    // eslint-disable-next-line
  }, [productData]);

  const windowWidth = window.innerWidth;

  const handleDownload = () => {
    const pdf =
      windowWidth <= 700
        ? new jsPDF("p", "px", "a5")
        : new jsPDF("p", "pt", "a4");
    pdf.addFont("NotoSans-Regular.ttf", "NotoSans", "normal");
    pdf.setFont("NotoSans");
    pdf.html(invoiceRef.current, {
      callback: (pdf) => {
        pdf.save(Data.orderData.order_number + ".pdf");
      },
      x: windowWidth <= 700 ? 50 : -50,
      y: windowWidth <= 700 ? 40 : 10,
      html2canvas: {
        width: windowWidth,
        scale: 0.5, // Scale down to fit content into a single page, adjust as needed
      },
    });
  };

  console.log("data: ", Data.orderData);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="header-logo">
            <Link to={`/`}>
              <img
                // src={
                //   Data?.orderData?.company_logo
                //     ? `data:image/jpeg;base64,${Data?.orderData?.company_logo}`
                //     : Logo
                // }
                src={
                  Data?.orderData?.brand_image_data &&
                  JSON.parse(Data?.orderData?.brand_image_data).find(
                    (item) => item.type === "3" || item.type === 3
                  )
                    ? API_URL.TOKEN_URL +
                      JSON.parse(Data?.orderData?.brand_image_data).find(
                        (item) => item.type === "3" || item.type === 3
                      )?.url
                    : Logo
                }
                alt={Data?.orderData?.companyName}
                className="company-logo"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="download">
        <div className="bar">
          <MdDownload onClick={handleDownload} />
        </div>
      </div>

      <div className="divData" ref={invoiceRef}>
        <p className="company-info">
          <strong>{Data?.orderData?.companyName}</strong>
          <br />
          {Data?.orderData?.brand_tax_type === "GST" &&
            "GSTIN - " + Data?.orderData?.brand_gst_number}
          <br />
          {Data?.orderData?.companyAddress}
        </p>
        <p className="order-info">
          Date: {format(Data?.orderData?.createdAt, "dd-MM-yyyy")}
          <br />
          Dine In: {Data.orderData.type === "0" ? "Yes" : "No"}
          <br />
          Table Number:{" "}
          {Data.orderData.type === "0" ? Data.orderData.table_no : "—"}
          <br />
          Cashier: {Data?.orderData?.companyName}
        </p>

        <table className="invoice-table">
          {productData && productData.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th>Item</th>
                  <th></th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {productData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.productName} -{" "}
                      {item.food_type_2 === "2"
                        ? "Regular"
                        : item.food_type_2 === "1"
                        ? "Jain"
                        : item.food_type_2 === "0"
                        ? "No onion/garlic"
                        : ""}
                    </td>
                    <td>
                      {item.attribute_data &&
                      item.attribute_data !== "" &&
                      item.attribute_data !== "null" &&
                      item.attribute_data !== "NULL" ? (
                        <div className="attribute-data">
                          {JSON.parse(item.attribute_data).map(
                            (attr, attrIndex) => (
                              <div key={attrIndex} className="attr-div">
                                {attr.cat_name}: {attr.name}
                                {attr.value && Number(attr.value) > 0
                                  ? `, +₹${attr.value}`
                                  : ""}
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>
                      <span>
                        {item.quantity && item.price
                          ? Number(item.quantity) * parseFloat(item.price)
                          : 0}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="total">Sub Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="total" colSpan="2">
                    {Data?.orderData?.country_currency_code
                      ? Data?.orderData.country_currency_code
                      : "INR"}{" "}
                    {productData.reduce(
                      (sum, product) =>
                        sum +
                        parseFloat(
                          product.price === "NULL" ||
                            product.price === "null" ||
                            product.price === null
                            ? 0
                            : product.price
                        ) *
                          Number(product.quantity),
                      0
                    )}
                  </td>
                </tr>
                {Data?.orderData?.brand_tax_type === "GST" && (
                  <>
                    <tr>
                      <td>CGST @ {Data?.orderData?.brand_cgst}%</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {(parseFloat(Data?.orderData?.brand_cgst) *
                          totalWithoutTax) /
                          100}
                      </td>
                    </tr>
                    <tr>
                      <td>SGST @ {Data?.orderData?.brand_sgst}%</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {(parseFloat(Data?.orderData?.brand_sgst) *
                          totalWithoutTax) /
                          100}
                      </td>
                    </tr>
                  </>
                )}
                {Data?.orderData?.brand_tax_type === "VAT" && (
                  <>
                    <tr>
                      <td>VAT @ {Data?.orderData?.brand_vat}%</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {(parseFloat(Data?.orderData?.brand_vat) *
                          totalWithoutTax) /
                          100}
                      </td>
                    </tr>
                  </>
                )}
                {Data?.orderData?.discount_id && (
                   <>
                   {/* <tr>
                     <td>Dicount code</td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td>
                       {Data.orderData?.Discount_Code}
                     </td>
                   </tr> */}
                   <tr>
                     <td>Discount Amount</td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td>
                       {Number(Data.orderData?.total_discount_amount).toFixed(2)}
                     </td>
                   </tr>
                 </>
                )}
                {Data.orderData.type === '2' && (
                  <tr>
                  <td>Delivery charge</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                  {Data.orderData?.delivery_charge ? Data.orderData.delivery_charge : 0}
                  </td>
                </tr>
                  )}
                <tr>
                  <td className="total">Grand Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="total">
                    {Data?.orderData?.country_currency_code
                      ? Data?.orderData.country_currency_code
                      : "INR"}{" "}
                    {Data.orderData?.order_amount
                      ? Number(Data.orderData?.order_amount).toFixed(2)
                      : Number(totalPrice).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <h6>No Product Bought!</h6>
          )}
        </table>

        <p className="thank-you-message">Thanks for visiting!</p>
      </div>
    </>
  );
});

export default Invoice;
