/**
 * Set cltegory list data into reducer
 * @param {*} discount 
 * @returns {*}
 */
export const discountDataByCode = (discount) => ({
    type: 'GET_DISCOUNT_BY_CODE',
    payload: discount
})

/**
 * Clear discount list data
 * @returns {*}
 */
export const clearDiscountDataByCode = () => ({
    type: 'CLEAR_DISCOUNT_BY_CODE'
})