import { toast } from "react-toastify";
import { API_URL, ValidationMessages, removePersistStorage, toastClass } from "../../../../Utils/Utils";
import { clearLoader, setLoader } from "../../../../Redux/Actions/LoaderAction/LoaderAction";
import { getApiService } from "../../../../Services/API-services/GetService";
import { CategoryListResponse, getAllCategoryRequest } from "./CategoryModal";
import { Dispatch } from "redux";
import { getCategoryData } from "../../../../Redux/Actions/categoryAction/CategoryAction";

let errorShow = false;

/*
 * Service to get category list
*/
export const getAllCategoryService = (
  categoryListRequest: getAllCategoryRequest, dispatch: Dispatch, total?: any
): Promise<CategoryListResponse> => {
  return new Promise<CategoryListResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (categoryListRequest.p_skip !== undefined && categoryListRequest.p_skip !== null) {
        queryParams += `p_skip=${categoryListRequest.p_skip}&`;
      }
      if (categoryListRequest.p_take !== undefined && categoryListRequest.p_take !== null) {
        queryParams += `p_take=${categoryListRequest.p_take}&`;
      }
      if (categoryListRequest.p_type !== undefined && categoryListRequest.p_type !== null) {
        queryParams += `p_type=${categoryListRequest.p_type}&`
      }
      if (categoryListRequest.p_company_id !== undefined && categoryListRequest.p_company_id !== null) {
        queryParams += `p_company_id=${categoryListRequest.p_company_id}&`
      }
      if (categoryListRequest.p_name !== undefined && categoryListRequest.p_name !== null) {
        queryParams += `p_name=${categoryListRequest.p_name}&`;
      }
      if (categoryListRequest.p_category_code !== undefined && categoryListRequest.p_category_code !== null) {
        queryParams += `p_category_code=${categoryListRequest.p_category_code}&`;
      }
      if (categoryListRequest.p_brand_id !== undefined && categoryListRequest.p_brand_id !== null) {
        queryParams += `p_brand_id=${categoryListRequest.p_brand_id}&`
      }
      if (categoryListRequest.p_is_active !== undefined && categoryListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${categoryListRequest.p_is_active}&`
      }
      if (categoryListRequest.p_IsDeleted !== undefined && categoryListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${categoryListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.CATEGORY + (queryParams && queryParams !== '' ? `?${queryParams}` : ''));
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        // if (total && total === true) {
        //   dispatch(getTotalCategoryData(response));
        // }
        // else 
        if (total && total === 'filter') {
          toast.success('Filter success!');
          
          dispatch(getCategoryData(response));
        }
        else {
          dispatch(getCategoryData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Category permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};