// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-discount-canvas {
  height: 50% !important;
}

.submit-discount {
  margin-top: 10px;
  display: flex;
}
.submit-discount .btn-fillter-main {
  width: 100%;
  max-width: 200px;
}

.apply-discount {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/DiscountPopup/DiscountPopup.scss"],"names":[],"mappings":"AAAE;EACE,sBAAA;AACJ;;AAGE;EACE,gBAAA;EACA,aAAA;AAAJ;AAEG;EACC,WAAA;EACA,gBAAA;AAAJ;;AAIA;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ","sourcesContent":["  .show-discount-canvas {\n    height: 50% !important;\n    // border-radius: unset !important;\n  }\n\n  .submit-discount {\n    margin-top: 10px;\n    display: flex;\n\n   .btn-fillter-main{\n    width: 100%;\n    max-width: 200px;\n   }\n  }\n\n.apply-discount {\n    font-size: 20px;\n    font-weight: bold;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
