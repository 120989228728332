import React from 'react'
import { Form } from "react-bootstrap";
import "../Input/Input.scss"

export default function NormalInput(props) {
    const {type, name, value, handleChange, placeholder, label, ...rest} = props
     return (
        <Form.Group controlId="formTableNo" className='input-chat'>
            {label && <Form.Label>{label}</Form.Label>}
            
            <Form.Control
                type={type}
                name={name}
                value={value ? value : ''}
                onChange={handleChange ? handleChange : ''}
                placeholder={placeholder}
                {...rest}
            />
        </Form.Group>
    )
}
