const addressInitialState = { 
    items: []
};

/**
 * Store category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const AddressReducer = (state = addressInitialState, action) => {
    switch (action.type) {
        case 'GET_ADDRESS_DATA':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_ADDRESS_DATA':
            return addressInitialState;
        default:
            return state;
      };
}