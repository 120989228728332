// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.header-logo {
  padding: 10px;
  text-align: center;
  max-width: 991px;
  margin: 0 auto;
  overflow-x: auto;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  justify-content: center;
}
.header-logo a {
  color: #FFF;
  font-weight: bold;
  padding: 0 10px;
}
.header-logo a img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.brand-active {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
}

.name-class {
  color: black;
  font-weight: bold;
}

.activeBrand {
  border-radius: 50%;
  border: 4px solid #e25233;
}

.activeBrand + p {
  color: #e25233 !important;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AAFA;EACI,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;AAKJ;AAJI;EACI,WDVK;ECWL,iBAAA;EACA,eAAA;AAMR;AALQ;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;AAOZ;;AAFA;EACI,wCAAA;EACA,wCAAA;AAKJ;;AADA;EACI,YAAA;EACA,iBAAA;AAIJ;;AAFA;EACI,kBAAA;EACA,yBAAA;AAKJ;;AAHA;EACI,yBAAA;AAMJ","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import '../../variables';\n.header-logo{\n    padding: 10px;\n    text-align: center;\n    max-width: 991px;\n    margin: 0 auto;\n    overflow-x: auto;\n    display: flex;\n    align-items: center;\n    overflow-y: hidden  ;\n    justify-content: center;\n    a{\n        color: $white-color;\n        font-weight: bold;\n        padding: 0 10px;\n        img{\n            width: 100px;\n            height: 100px;\n            border-radius: 50%;\n            object-fit: cover;\n            object-position: center;\n        }\n    }\n}\n\n.brand-active {\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);\n    background-color: transparent !important;\n    // transition: opacity 0.5s ease-in-out !important;\n}\n\n.name-class {\n    color: black;\n    font-weight: bold;\n}\n.activeBrand{\n    border-radius: 50%;\n    border: 4px solid $bespeakk-color;\n}\n.activeBrand + p{\n    color: $bespeakk-color !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
