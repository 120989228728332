/**
 * Update product count
 * @param {*} product 
 * @returns 
 */
export const setTotalProductData = (product) => ({
    type: 'SET_TOTAL_PRODUCT_DATA',
    payload: product
})
/**
 * Clear product count
 * @returns 
 */
export const clearTotalProductData = () => ({
    type: 'CLEAR_TOTAL_PRODUCT_DATA'
})