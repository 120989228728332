import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../../../Redux/Actions/LoaderAction/LoaderAction";
import { toast } from "react-toastify";
import { API_URL, ValidationMessages } from "../../../../Utils/Utils";
import { postApiService } from "../../../../Services/API-services/PostService";
import { loginFail } from "../../../../Redux/Actions/authAction/AuthAction";
import { getUserDetailResponse, registerUserResponse } from "./LoginModal";
import { getApiService } from "../../../../Services/API-services/GetService";

/**
 * Service for login API Integration
 * @param email 
 * @param password 
 * @param rememberMe 
 * @param token 
 * @param dispatch 
 * @returns 
 */

export const loginAuth = (userData: any, dispatch: Dispatch): Promise<registerUserResponse> => {
    return new Promise<registerUserResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader());
        // Call your login API endpoint
        const bodyData = {
            p_mobile_no: userData.number,
            p_first_name: userData.firstName,
            p_last_name: userData.lastName,
            p_email: userData.email
        }
        const response = await postApiService(API_URL.BASE_URL + API_URL.LOGIN, null, bodyData)
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          toast.success('OTP sent successfully!');
          resolve(response)
        }
        else {
          toast.error(response?.message ? response.message : ValidationMessages.SOMETHING_WENT_WRONG);
          dispatch(loginFail());
          // reject(response);
        }
      } catch (error) {
        toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
        dispatch(loginFail());
      } finally {
        dispatch(clearLoader());
      }
    });
  };


/**
 * Service for get user detail API Integration
*/

  let errorShow = false;

  export const getUserDetailService = (
    mobile: number, dispatch: Dispatch
  ): Promise<getUserDetailResponse> => {
    return new Promise<getUserDetailResponse>(async (resolve, reject) => {
      try {
        dispatch(setLoader())
        const response = await getApiService(API_URL.BASE_URL + API_URL.USER_DETAIL + '?p_mobile_no='+mobile);
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          resolve(response);
        }
        else {
          // if (!document.querySelector(toastClass)) {
          //   toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'User permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
          // }
          errorShow = true;
        }
        resolve(response)
      } catch (error) {
        if (errorShow === false) {
          // toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };