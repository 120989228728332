/**
 * Set address list data into reducer
 * @param {*} category 
 * @returns {*}
 */
export const getAddressData = (address) => ({
    type: 'GET_ADDRESS_DATA',
    payload: address
})

/**
 * Clear address list data
 * @returns {*}
 */
export const clearAddressData = () => ({
    type: 'CLEAR_ADDRESS_DATA'
})

