// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #e25233;
  --primary-color: #234047;
}

.footer-copyright {
  background-color: #234047;
  font-size: 14px;
  color: #FFF;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px 0;
}
.footer-copyright a {
  color: var(--white-color);
  transition: 0.2s ease-in-out;
}
.footer-copyright a:hover {
  color: #e25233;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAcA;EACI,qBAAA;EACA,wBAAA;ACbJ;;AADA;EACE,yBDUgB;ECThB,eAAA;EACA,WDHW;ECIX,gBAAA;EAQA,eAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AAHF;AARE;EACE,yBAAA;EACA,4BAAA;AAUJ;AATI;EACE,cAAA;AAWN","sourcesContent":["$main-color:#9b6d34;\n$primary-color:#4B4B4D;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$border-color:#DEE2E6;\n$yellow-color:#f0f02b;\n$user-chat-bg:#ff4155;\n$pms-chat-bg:#EF9C66;\n$order-bg-color:#f5ece1;\n$bespeakk-color:#e25233;\n$gray:#E6E6E6;\n$bespeakk-primary:#234047;\n:root {\n    --main-color: #e25233;\n    --primary-color: #234047;\n}","@import '../../variables';\n\n.footer-copyright {\n  background-color: $bespeakk-primary;\n  font-size: 14px;\n  color: $white-color;\n  font-weight: 500;\n  a {\n    color: var(--white-color);\n    transition: 0.2s ease-in-out;\n    &:hover {\n      color: $bespeakk-color;\n    }\n  }\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n  padding: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
