import React, { useEffect, useState } from "react";
import "../OrderCard/OrderCard.scss";
import { IoReload } from "react-icons/io5";
import { format, parseISO } from "date-fns";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import Profileimg from "../../assets/images/Bespeakk-logo.png";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { API_URL } from "../../Utils/Utils";
import HomePage from "../../pages/HomePage/HomePage";
import {
  clearOrderProductData,
  clearProductData,
  setProductData,
} from "../../Redux/Actions/ProductAction/ProductAction";
import { getAllProductService } from "../Product/ProductService/ProductService";
import { updateOrderService } from "../../pages/ReviewOrder/OrderAndLoginService/OrderService/PlaceOrderService/PlaceOrderService.tsx";
import { postDiscountByCodeService } from "../../pages/ReviewOrder/DiscountService/DiscountService";
import InstructionPopup from "../InstructionPopup/InstructionPopup";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { getApiService } from "../../Services/API-services/GetService";
import { clearDiscountDataByCode } from "../../Redux/Actions/DiscountAction/DiscountAction";
import Tooltip from "@mui/material/Tooltip";
// import ReactStars from "react-rating-stars-component";
// import StarRatings from 'react-star-ratings';

const AddMoreCount = (props) => {
  const count = props.count < 5 ? 5 : props.count;
  const loader = useSelector((state) => state.loader?.items);
  const brandDetail = useSelector((state) => state.brand?.brand_detail);

  const spans = [];

  const handleClick = (index) => {
    if (!loader) {
      if (props.onCountClick) {
        props.onCountClick(index + 1);
      }
    }
    return;
  };

  for (let i = 0; i < count; i++) {
    let index = i;
    spans.push(
      <span key={index} onClick={() => handleClick(index)}>
        {index + 1}
      </span>
    );
  }
  return (
    <div
      className={
        brandDetail && brandDetail?.brand_id
          ? "add-more-brand-count"
          : "add-more-count"
      }
    >
      {spans}
    </div>
  );
};

export default function OrderComponent({ orderData, reorderClick, brandId }) {
  const [expanded, setExpanded] = useState(false);
  const [showModal, handleShowModal] = useState(false);
  const [newOrderData, setOrderData] = useState(null);
  const [cleanProductNewData, setCleanProductNewData] = useState(null);
  const companyCode = useSelector((state) => state.company?.company_code);
  const productFilter = useSelector((state) => state.productFilter);
  const company = useSelector((state) => state.company?.items);
  const brandDetail = useSelector((state) => state.brand?.brand_detail);
  const tokenData = UtilsData();
  const userAuthData = useSelector((state) => state.auth?.user);
  const brandIdClick = useSelector((state) => state.brandIdClick?.items);

  const [itemCount, setItemCount] = useState(0);
  const [itemData, setItemData] = useState({});
  const [addITemModal, setAddItemModal] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [open, setOpen] = React.useState(false);

  // const [rating, setRating] = useState(0);
  // const branchCode = useSelector(state => state.branch?.branch_code);
  // const table = useSelector(state => state.table?.tableNo);
  // const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    const parsedProductData = JSON.parse(orderData.productData);
    if (
      parsedProductData[0].attribute_data !== null ||
      parsedProductData[0].attribute_data !== "null" ||
      parsedProductData[0].attribute_data !== undefined
    ) {
      const attrData = JSON.parse(parsedProductData[0].attribute_data);
      const data = groupByCategory(attrData);

      if (data !== undefined) {
        // setGroupedData(data);
      }
    }
  }, [orderData]);

  // changes by sakshi
  const groupByCategory = (products) => {
    if (products) {
      return products.reduce((acc, product) => {
        const categories = product.cat_name
          .split(",")
          .map((category) => category.trim());
        categories.forEach((category) => {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(product);
        });
        return acc;
      }, {});
    }
  };

  // };

  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleShowModal(false);
  };

  // const [totalWithTax, setTotalWithTax] = useState(0);
  const [cleanProductData, setCleanProductData] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const dispatch = useDispatch();
  const [instructionMessage, setInstructionMessage] = useState("");

  useEffect(() => {
    setCleanProductData(
      orderData && orderData?.productData && orderData.productData.length > 0
        ? JSON.parse(orderData.productData)
        : []
    );
  }, [orderData]);

  const openPopup = (message, type) => {
    setInstructionMessage(message);
    setOpen(type);
    // if(type === 'common') {
    //   if(type === open){
    //     setInstructionMessage('');
    //     setOpen(false);
    //   }
    //   setInstructionMessage(message);
    //   setOpen(true);
    // }
    // else {
    //   if(type === open) {
    //     setInstructionMessage('');
    //     setOpen(false);
    //     return;
    //   }
    //   setInstructionMessage(message);
    //   setOpen(type);
    // }
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".i-icon-class") &&
      !event.target.closest(".MuiTooltip-tooltip")
    ) {
      setOpen(false);
      setInstructionMessage("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setInstructionMessage("");
  };

  const closePopup = () => {
    setInstructionMessage("");
    setIsPopupVisible(false);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const navigateToOrder = () => {
    const Data = {
      data: brandDetail?.brand_id
        ? brandDetail?.brand_id
        : brandIdClick?.brandId
        ? brandIdClick.brandId
        : null,
    };
    navigate("/order-review", { state: { Data } });
  };

  const handlePaymentClick = (payment, amount) => {
    // This is an example of a UPI payment link
    // const paymentLink = "upi://pay?pa=merchant@upi&pn=MerchantName&am=100&cu=INR";

    // Redirect to the payment app
    window.location.href = `${payment}&am=${amount}`;
  };

  /**
   * Open modal for reorder function
   * @param {*} order
   * @param {*} cleanProduct
   */
  const handleOpenModal = (order, cleanProduct) => {
    const queryParams = `?p_skip=0&p_take=1000000&p_company_id=${company}&p_brand_id=${order?.brand_id}&p_is_active=1`;
    getApiService(
      API_URL.BASE_URL +
        API_URL.PRODUCT +
        (queryParams && queryParams !== "" ? `?${queryParams}` : "")
    ).then((res) => {
      if (
        res?.status &&
        (res.status === 200 ||
          res.status === "200" ||
          res.status === "success" ||
          res.status === "Success")
      ) {
        if (res?.data && res.data.length > 0) {
          const productIdsFromArray1 = new Set(
            res.data.map((item) => item.productId)
          );

          // Filter array2 to include only items with productId present in array1
          const filteredArray2 = cleanProduct.filter((item) =>
            productIdsFromArray1.has(item.productId)
          );

          // Create a new array combining data from array1 and filteredArray2
          const combinedArray = filteredArray2.map((item) => {
            // Find corresponding item in array1
            const array1Item = res.data.find(
              (a1Item) => a1Item.productId === item.productId
            );

            let initialSelected = {};
            if (
              item?.attribute_data &&
              item.attribute_data !== "" &&
              item.attribute_data !== "null" &&
              item.attribute_data !== "NULL"
            ) {
              const groupedAttributes = JSON.parse(item?.attribute_data).reduce(
                (acc, attr) => {
                  if (!acc[attr.cat_name]) {
                    acc[attr.cat_name] = [];
                  }
                  acc[attr.cat_name].push(attr);
                  return acc;
                },
                {}
              );
              initialSelected = groupedAttributes;
            }

            // Combine data from both arrays
            return {
              ...array1Item,
              price: item.price,
              quantity: item.quantity,
              selectedAttributes: item?.attribute_data ? initialSelected : null,
              food_type_jain_regular: item?.food_type_2,
            };
          });
          dispatch(
            setProductData({ company: company, product: combinedArray })
          );
          dispatch(clearDiscountDataByCode());
          navigateToOrder();
        }
      }
    });
  };

  useEffect(() => {
    setCleanProductData(
      orderData && orderData?.productData && orderData.productData.length > 0
        ? JSON.parse(orderData.productData)
        : []
    );
  }, [orderData]);

  /**
   * Open modal for reorder function
   * @param {*} order
   * @param {*} cleanProduct
   */

  /**
   * Reorder function
   */
  const reorderFunction = () => {
    reorderClick(newOrderData, cleanProductNewData);
    setOrderData(false);
    setCleanProductNewData(false);
    handleShowModal(false);
  };

  const handleDownload = () => {
    const Data = {
      orderData: orderData,
      productData: cleanProductData,
    };
    navigate("/invoice", { state: { Data } });
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    dispatch(clearOrderProductData());
    fetchProduct();
  };

  /**
   * Fetch product data
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_company_id: company,
      p_brand_id: brandId,
    };
    if (productFilter.name !== "" && productFilter.name !== null) {
      requestBody.p_name = productFilter.name;
    }
    if (
      productFilter.category !== "" &&
      productFilter.category !== null &&
      productFilter.category.length > 0
    ) {
      requestBody.p_category_id = productFilter.category;
    }
    if (
      productFilter.subCategory !== "" &&
      productFilter.subCategory !== null &&
      productFilter.subCategory.length > 0
    ) {
      requestBody.p_sub_category_id = productFilter.subCategory;
    }
    if (
      productFilter.leafCategory !== "" &&
      productFilter.leafCategory !== null &&
      productFilter.leafCategory.length > 0
    ) {
      requestBody.p_leaf_level_category_id = productFilter.leafCategory;
    }
    if (
      productFilter.tags !== "" &&
      productFilter.tags !== null &&
      productFilter.tags.length > 0
    ) {
      requestBody.p_tag_id = productFilter.tags.join(",");
    }
    if (productFilter.isActive !== "" && productFilter.isActive !== null) {
      requestBody.p_is_active = productFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (productFilter.isDelete !== "" && productFilter.isDelete !== null) {
      requestBody.p_IsDeleted = productFilter.isDelete;
    }
    getAllProductService(requestBody, dispatch);
  };

  const showCanvas = () => {
    dispatch(clearProductData());
    setShowOffcanvas(true);
  };

  const handleCountClick = (item, number) => {
    setItemCount(number);
    setItemData(item);
    setAddItemModal(true);
    // You can handle the click event here as needed
  };

  const updateOrderFunction = async () => {
    const total = orderData?.order_amount ? Number(orderData.order_amount) : 0;
    const tax = orderData?.total_tax_amount
      ? Number(orderData.total_tax_amount)
      : 0;
    const discount = orderData?.total_discount_amount
      ? Number(orderData.total_discount_amount)
      : 0;

    let newTotal = total - tax + discount + Number(itemData.price) * itemCount;
    let newTax =
      ((total - tax + discount + Number(itemData.price) * itemCount) *
        ((orderData.brand_cgst ? Number(orderData.brand_cgst) : 0) +
          (orderData.brand_sgst ? Number(orderData.brand_sgst) : 0) +
          (orderData.brand_vat ? Number(orderData.brand_vast) : 0))) /
      100;

    let newDiscount = 0;

    if (orderData?.discount_id) {
      const discountRequestBody = {
        p_code: orderData.Discount_Code,
        p_cart_amount: newTotal,
        p_phone_number: userAuthData.data.phoneNumber,
        p_brand_id: orderData?.brand_id,
        p_company_id: orderData?.company_id,
      };
      const discountData = await postDiscountByCodeService(
        discountRequestBody,
        userAuthData?.token,
        dispatch,
        true
      ).then((res) => {
        if (
          res.status === 200 ||
          res.status === "200" ||
          res.status === "success" ||
          res.status === "Success"
        ) {
          let discountCalc =
            (newTotal * res.data.discount_amount) / 100 >
            res.data.max_discount_ammount
              ? res.data.max_discount_ammount
              : (total * res.data.discount_amount) / 100;

          return discountCalc;
        }
      });
      newDiscount += discountData;
      newTotal = newTotal - discountData + newTax;
    }

    const requestBody = {
      p_order_id: orderData?.orderId,
      p_discount_id: orderData?.discount_id ? orderData.discount_id : null,
      p_discount_code: orderData?.Discount_Code
        ? orderData.Discount_Code
        : null,
      p_discount_amount: newDiscount,
      p_total_amount: newTotal,
      p_total_tax_amount: newTax,
      p_product_data: [
        {
          product_id: itemData.productId,
          product_size: itemData.size,
          quantity: itemCount,
          product_price: Number(itemData.price),
          product_purchase_price: Number(itemData.purchase_price),
          product_instruction:
            itemData.product_instruction === null ||
            itemData.product_instruction === "null" ||
            itemData.product_instruction === "NULL"
              ? null
              : itemData.product_instruction,
          food_type_one: itemData.food_type_1,
          food_type_two: itemData.food_type_2,
          attribute_data: itemData.attribute_data,
        },
      ],
    };
    updateOrderService(requestBody, tokenData, dispatch, "orderHistory").then(
      (response) => {
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          // onPlay();
          navigate("/thank-you");
        }
      }
    );
  };

  const handleItemModalClose = () => {
    setItemCount(0);
    setItemData({});
    setAddItemModal(false);
  };

  return (
    <>
      <div className="order-card-top">
        {/* Reorder Modal start */}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to reorder?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                onClick={reorderFunction}
                className={`me-2 flex-grow-1 ${
                  brandDetail?.brand_id ? "yes-button" : "no-brand-yes-button"
                }`}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={handleCloseModal}
                className="flex-grow-1"
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Reorder Modal end */}

        {/* Add Items Modal start */}

        <Modal show={addITemModal} onHide={handleItemModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want add {itemCount} more {itemData.productName}?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                onClick={updateOrderFunction}
                className={`me-2 flex-grow-1 ${
                  brandDetail?.brand_id ? "yes-button" : "no-brand-yes-button"
                }`}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={handleItemModalClose}
                className="flex-grow-1"
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Add Items Modal end */}

        <div className="product">
          <div className="row">
            <div className="new-order-card">
              <div className="col-12">
                <div className="company-detail">
                  <div className="company-detail-text">
                    <div className="company-img">
                      <img
                        src={
                          orderData?.brand_image_data &&
                          JSON.parse(orderData?.brand_image_data).find(
                            (item) => item.type === "3" || item.type === 3
                          )
                            ? API_URL.TOKEN_URL +
                              JSON.parse(orderData?.brand_image_data).find(
                                (item) => item.type === "3" || item.type === 3
                              )?.url
                            : Profileimg
                        }
                        alt="Order"
                      />
                    </div>
                    <div className="company-text">
                      <h6>{orderData.brand_name}</h6>
                      <span>
                        {orderData.branch_name} - {orderData.branch_pincode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div
                  className={`product-detail ${
                    orderData.status === "0"
                      ? "order-time-date"
                      : orderData.status === "1"
                      ? "order-confirm"
                      : orderData.status === "2"
                      ? "order-progress"
                      : orderData.status === "3"
                      ? "order-complete"
                      : orderData.status === "4"
                      ? "order-cancel"
                      : ""
                  }`}
                >
                  <p className="total-amount">
                    {format(
                      parseISO(orderData.createdAt),
                      "dd-MM-yyyy hh:mm a"
                    )}
                  </p>

                  <div className="total-amount stutas p-0">
                    {orderData.status === "0"
                      ? "Pending"
                      : orderData.status === "1"
                      ? "Confirmed"
                      : orderData.status === "2"
                      ? "In Progress"
                      : orderData.status === "3"
                      ? "Completed"
                      : "Cancelled"}
                  </div>
                </div>
              </div>
              <div className="product-detail">
                <div className="order-date">
                  {orderData.order_number}{" "}
                  {orderData?.instructions &&
                    orderData.instructions !== "" &&
                    orderData.instructions !== "null" &&
                    orderData.instructions !== "NULL" && (
                      <>
                        <HiMiniInformationCircle
                          id="clickableone"
                          className="i-icon-class instruction-icon"
                          onClick={() =>
                            openPopup(orderData.instructions, "common")
                          }
                        />
                        <Tooltip
                          open={open === "common"}
                          onClose={handleClose}
                          title={instructionMessage}
                          leaveDelay={5000}
                          arrow
                        ></Tooltip>
                      </>
                    )}
                </div>

                {cleanProductData && cleanProductData.length > 0 ? (
                  <>
                    <div className="total-amount">
                      {orderData?.country_symbol
                        ? orderData.country_symbol
                        : "₹"}

                      {/* {totalWithTax} */}
                      {orderData.order_amount}
                    </div>
                  </>
                ) : (
                  <div className="total-amount">
                    {orderData?.country_symbol ? orderData.country_symbol : "₹"}
                    0
                  </div>
                )}
              </div>
              {expanded && (
                <div className="reorder-btn-detail">
                  <div className="product-name">
                    <p style={{ fontSize: 13 }}>Type</p>
                    <p style={{ fontSize: 13 }}>
                      {orderData.type === "2"
                        ? "DELIVERY"
                        : orderData.type === "1"
                        ? "TAKE AWAY"
                        : "DINE IN"}
                    </p>
                  </div>
                  {orderData?.brand_tax_type === "GST" && (
                    <>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>GST Number</p>
                        <p style={{ fontSize: 13 }}>
                          {orderData?.brand_gst_number}
                        </p>
                      </div>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>
                          CGST @ {orderData?.brand_cgst}%
                        </p>
                        <p style={{ fontSize: 13 }}>
                          ₹
                          {((parseFloat(orderData?.brand_cgst) *
                            cleanProductData.reduce(
                              (sum, product) =>
                                sum +
                                Number(
                                  product.price === "NULL" ||
                                    product.price === "null" ||
                                    731372 === null
                                    ? 0
                                    : product.price
                                ) *
                                  Number(product.quantity),
                              0
                            )) /
                            100).toFixed(2)}
                        </p>
                      </div>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>
                          SGST @ {orderData?.brand_sgst}%
                        </p>
                        <p style={{ fontSize: 13 }}>
                          ₹
                          {((parseFloat(orderData?.brand_sgst) *
                            cleanProductData.reduce(
                              (sum, product) =>
                                sum +
                                Number(
                                  product.price === "NULL" ||
                                    product.price === "null" ||
                                    731372 === null
                                    ? 0
                                    : product.price
                                ) *
                                  Number(product.quantity),
                              0
                            )) /
                            100).toFixed(2)}
                        </p>
                      </div>
                    </>
                  )}
                  {orderData?.brand_tax_type === "VAT" && (
                    <>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>
                          VAT @ {orderData?.brand_vat}%
                        </p>
                        <p style={{ fontSize: 13 }}>
                          {((parseFloat(orderData?.brand_vat) *
                            cleanProductData.reduce(
                              (sum, product) =>
                                sum +
                                Number(
                                  product.price === "NULL" ||
                                    product.price === "null" ||
                                    731372 === null
                                    ? 0
                                    : product.price
                                ) *
                                  Number(product.quantity),
                              0
                            )) /
                            100).toFixed(2)}
                        </p>
                      </div>
                    </>
                  )}
                  {orderData?.discount_id && orderData.discount_id > 0 ? (
                    <>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>Discount Code</p>
                        <p style={{ fontSize: 13 }}>
                          {orderData?.Discount_Code}
                        </p>
                      </div>
                      <div className="product-name">
                        <p style={{ fontSize: 13 }}>Discount Amount</p>
                        <p style={{ fontSize: 13 }}>
                          ₹{orderData?.total_discount_amount}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {orderData.type === "2" && (
                    <div className="product-name">
                      <p style={{ fontSize: 13 }}>Delivery charge</p>
                      <p style={{ fontSize: 13 }}>
                        ₹
                        {orderData?.delivery_charge
                          ? orderData.delivery_charge
                          : 0}
                      </p>
                    </div>
                  )}
                  {cleanProductData &&
                    cleanProductData.length > 0 &&
                    cleanProductData.map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="product-name">
                          <div className="product-bg-new">
                            <div className="new-pro">
                              <p>
                                {item.quantity} x {item.productName} -{" "}
                                {item.food_type_2 === "2"
                                  ? "Regular"
                                  : item.food_type_2 === "1"
                                  ? "Jain"
                                  : item.food_type_2 === "0"
                                  ? "No onion/garlic"
                                  : ""}
                                {item?.product_instruction &&
                                  item.product_instruction !== "" &&
                                  item.product_instruction !== "null" &&
                                  item.product_instruction !== "NULL" && (
                                    <>
                                      {" "}
                                      <HiMiniInformationCircle
                                        id="clickable"
                                        className="i-icon-class instruction-icon"
                                        onClick={() =>
                                          openPopup(
                                            item.product_instruction,
                                            index
                                          )
                                        }
                                      />
                                      <Tooltip
                                        open={open === index}
                                        onClose={handleClose}
                                        title={instructionMessage}
                                        leaveDelay={5000}
                                        arrow
                                      ></Tooltip>
                                    </>
                                  )}
                              </p>
                              <p>
                                {orderData?.country_symbol
                                  ? orderData.country_symbol
                                  : "₹"}
                                {Number(item.price) && Number(item.quantity)
                                  ? Number(item.price) * Number(item.quantity)
                                  : 0}
                              </p>
                            </div>
                            {/* <p> {typeof (item.attribute_data)}</p> */}
                            {/* issue */}
                            {item.attribute_data &&
                            JSON.parse(item.attribute_data) !== null
                              ? (() => {
                                  const parsedData = JSON.parse(
                                    item.attribute_data
                                  );
                                  const data = groupByCategory(parsedData);
                                  return Object.keys(data).map(
                                    (categoryKey, categoryIndex) => {
                                      const categoryItems = data[categoryKey];
                                      return (
                                        <div
                                          key={categoryIndex}
                                          className="product-details-order-history toppings "
                                        >
                                          <p className="product-piza-name">
                                            {categoryKey} :{" "}
                                          </p>
                                          <div className="pizza-toppings">
                                            {categoryItems.map((attr, idx) => (
                                              <span key={idx}>
                                                {attr.name}
                                                {/* Optional value condition */}
                                                {/* {attr.value !== "0" && attr.value !== 0 ? <>: +₹{attr.value}</> : null} */}
                                                {idx < categoryItems.length - 1
                                                  ? ",\u00A0"
                                                  : ""}
                                              </span>
                                            ))}
                                          </div>
                                        </div>
                                      );
                                    }
                                  );
                                })()
                              : null}
                            {brandDetail &&
                              brandDetail?.brand_id &&
                              brandDetail.brand_id === orderData?.brand_id &&
                              orderData.status !== "3" &&
                              orderData.status !== "4" &&
                              orderData.type === "0" && (
                                <>
                                  <div className="add-more-product">
                                    <div className="add-more-btn">
                                      <span>Add more ?</span>
                                    </div>
                                    <AddMoreCount
                                      count={Number(item.quantity)}
                                      item={item}
                                      onCountClick={(number) =>
                                        handleCountClick(item, number)
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            {(!brandDetail || !brandDetail?.brand_id) &&
                              orderData.status !== "3" &&
                              orderData.status !== "4" &&
                              orderData.type === "0" && (
                                <div className="add-more-product">
                                  <div className="add-more-btn">
                                    <span>Add more ?</span>
                                  </div>
                                  <div className="add-more-count">
                                    <AddMoreCount
                                      count={Number(item.quantity)}
                                      onCountClick={(number) =>
                                        handleCountClick(item, number)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                          </div>

                          {/* <div className="product-bg-new">
                            <p>
                              {orderData?.country_symbol
                                ? orderData.country_symbol
                                : "₹"}
                              {Number(item.price) && Number(item.quantity)
                                ? Number(item.price) * Number(item.quantity)
                                : 0}
                            </p>
                          </div> */}
                        </div>
                      </React.Fragment>
                    ))}
                  <div className="reting-star">
                    {/* <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={30}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#ffd700"
                                /> */}
                    {/* <StarRatings
                                        rating={rating}
                                        starRatedColor="#ffd700"
                                        starHoverColor="#ffd700"
                                        starEmptyColor="gray"
                                        changeRating={ratingChanged}
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="30px"
                                        starSpacing="5px"
                                        isSelectable={true}
                                        isAggregateRating={false}
                                        allowHalfIcon={true}
                                        svgIconViewBox="0 0 32 32"
                                        svgIconPath="M16,23.27l-7.06,3.95l1.35-7.87l-5.71-5.57l7.89-1.15L16,6.1l3.53,7.53l7.89,1.15l-5.71,5.57l1.35,7.87L16,23.27z"
                                    /> */}
                  </div>
                  <div className="btn-download">
                    {orderData.status === "3" && (
                      <>
                        {orderData.company_code === companyCode && (
                          <div className="btn-bar">
                            <IoReload
                              onClick={() =>
                                handleOpenModal(orderData, cleanProductData)
                              }
                            />
                          </div>
                        )}
                        <div className="btn-bar">
                          <IoEyeSharp onClick={handleDownload} />
                        </div>
                      </>
                    )}
                    {brandDetail &&
                      brandDetail?.brand_id &&
                      brandDetail.brand_id === orderData?.brand_id &&
                      orderData.status !== "3" &&
                      orderData.status !== "4" &&
                      orderData.type === "0" && (
                        <>
                          <button
                            className="add-more-order-brand-btn"
                            onClick={showCanvas}
                          >
                            Add more items
                          </button>
                          &nbsp;
                          {orderData?.brand_payment_url &&
                            orderData.brand_payment_url !== "" &&
                            orderData.brand_payment_url !== "null" &&
                            orderData.brand_payment_url !== "NULL" &&
                            orderData.payment === "0" && (
                              <button
                                className="add-more-order-brand-btn"
                                onClick={() =>
                                  handlePaymentClick(
                                    orderData.brand_payment_url,
                                    Number(orderData.order_amount)
                                  )
                                }
                              >
                                Pay Now
                              </button>
                            )}
                        </>
                      )}
                    {(!brandDetail || !brandDetail?.brand_id) &&
                      orderData.status !== "3" &&
                      orderData.status !== "4" &&
                      orderData.type === "0" && (
                        <>
                          <button
                            className="add-more-order-btn"
                            onClick={showCanvas}
                          >
                            Add more items
                          </button>
                          &nbsp;
                          {orderData?.brand_payment_url &&
                            orderData.brand_payment_url !== "" &&
                            orderData.brand_payment_url !== "null" &&
                            orderData.brand_payment_url !== "NULL" && (
                              <button
                                className="add-more-order-btn"
                                onClick={() =>
                                  handlePaymentClick(
                                    orderData.brand_payment_url,
                                    Number(orderData.order_amount)
                                  )
                                }
                              >
                                Pay Now
                              </button>
                            )}
                        </>
                      )}
                  </div>
                </div>
              )}
              <div className="see-more" onClick={toggleExpand}>
                {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          show={showOffcanvas}
          onHide={handleCloseOffcanvas}
          placement="bottom"
          className="home-canvas"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <HomePage
              page="orderHistory"
              propsBrandId={orderData?.brand_id}
              orderData={orderData}
              setShowCanvasData={handleCloseOffcanvas}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {isPopupVisible && (
        <InstructionPopup
          onClose={closePopup}
          instruction={instructionMessage}
        />
      )}
    </>
  );
}
