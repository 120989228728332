import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ReviewOrder from "./pages/ReviewOrder/ReviewOrder";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import ChatOrder from "./pages/ChatOrder/ChatOrder";
import ThankYou from "./pages/ThankYou/ThankYou";
import { useDispatch, useSelector } from "react-redux";
import Invoice from "./components/Invoice/Invoice";
import { useEffect } from "react";
import { getCompanyByCode } from "./pages/HomePage/Home-services/CompanyService/CompanyService.tsx";
// import { clearProductData } from "./Redux/Actions/ProductAction/ProductAction.js";
import Profile from "./pages/Profile/Profile";
import { clearTable, setTable } from "./Redux/Actions/TableAction";
// import { clearBranch } from "./Redux/Actions/BranchAction";
import Bespeakk from "./pages/Bespeakk/Bespeakk";
// import haversine from 'haversine-distance';
// import { setLocationBool, setLocationMessage } from "./Redux/Actions/Location/LocationAction";
import { removePersistCompanyBranchStorage } from "./Utils/Utils";
import { clearBrand } from "./Redux/Actions/BrandAction";
// import axios from 'axios';

function App() {
  // const [userLocation, setUserLocation] = useState(null);

  const userAuthData = useSelector(state => state.auth?.user);
  const company = useSelector(state => state.company?.company_code);
  const companyDetail = useSelector(state => state.company?.company_detail);
  const branchCode = useSelector(state => state.branch?.branch_code);
  const table = useSelector(state => state.table?.tableNo);
  const brandCode = useSelector(state => state?.brand?.brand_code);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleSuccess = (position) => {
  //   const { latitude, longitude } = position.coords;
  //   setUserLocation({ latitude, longitude });
  // };

  // const fetchIPLocation = async () => {
  //   try {
  //     const response = await axios.get('https://ipapi.co/json/');
  //     const { latitude, longitude } = response.data;
  //     setUserLocation({ latitude, longitude });
  //   } catch (err) {
  //     console.error('Failed to fetch IP-based location');
  //   }
  // };

  // const handleError = (error) => {
  //   console.error(error.message);
  // };

  // useEffect(() => {
  //   if ('geolocation' in navigator) {
  //     const options = {
  //       enableHighAccuracy: true,
  //       // timeout: 10000,
  //       // maximumAge: 0
  //     };
  //     navigator.geolocation.getCurrentPosition(handleSuccess, handleError, options);
  //   } else {
  //     console.error('Geolocation is not supported by your browser');
  //     fetchIPLocation();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const pattern = /\/menu\/([^/]+)\/([^/]+)\/?([^/]*)\/brand\/([^/]+)/;

  useEffect(() => {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const secondLast = parts[parts.length - 2];
    const thirdLast = parts[parts.length - 3];

    const singleSlashCondition = (pathname.match(/\//g) || []).length;

    if (parts[1] === 'menu') {
      if (singleSlashCondition > 4) {
        if(lastPart && lastPart !== '' && lastPart !== 'brand') {
          const match = pathname.match(pattern);
          if (match) {
              const companyDataCode = match[1];
              const branchDataCode = match[2];
              const tableNumber = match[3] || "N/A";
              const brandDataCode = match[4];

              if (tableNumber === "N/A") {
                dispatch(setTable(''));
              }
              else {
                dispatch(setTable(tableNumber));
              }
              if(brandCode !== brandDataCode) {
                removePersistCompanyBranchStorage(dispatch);
                getCompanyByCode(companyDataCode, branchDataCode, brandDataCode, dispatch, navigate);
              }
          }
        }
        else if (lastPart === 'brand') {
          const brandPattern = /\/menu\/([^/]+)\/([^/]+)\/?([^/]*)\/brand$/;
          const match = pathname.match(brandPattern);
          if (match) {
              const companyDataCode = match[1];
              const branchDataCode = match[2];
              const tableNumber = match[3] || "N/A";

              if(tableNumber === "N/A") {
                dispatch(clearTable());
              }
              else if (tableNumber !== table) {
                dispatch(setTable(tableNumber));
              }
              if (company !== companyDataCode || branchCode !== branchDataCode) {
                removePersistCompanyBranchStorage(dispatch);
                getCompanyByCode(companyDataCode, branchDataCode, null, dispatch, navigate);
              }
              navigate('/')
          }
        }
        else if (lastPart === '' && singleSlashCondition <= 7) {
          const match = pathname.match(pattern);
          if (match) {
              const companyDataCode = match[1];
              const branchDataCode = match[2];
              const tableNumber = match[3] || "N/A";
              const brandDataCode = match[4] || "N/A";

              if(tableNumber === "N/A") {
                dispatch(clearTable());
              }
              else if (tableNumber !== table) {
                dispatch(setTable(tableNumber));
              }
              if(brandDataCode === "N/A") {
                removePersistCompanyBranchStorage(dispatch);
                getCompanyByCode(companyDataCode, branchDataCode, null, dispatch, navigate);
              }
              else if (company !== companyDataCode || branchCode !== branchDataCode || brandCode !== brandDataCode) {
                removePersistCompanyBranchStorage(dispatch);
                getCompanyByCode(companyDataCode, branchDataCode, brandDataCode, dispatch, navigate);
              }
            }
            navigate('/');
        }
      }
      else if (singleSlashCondition === 4) {
        // dispatch(clearBrand());
        if (lastPart === 'brand') {
          dispatch(setTable(''));
          if (secondLast !== branchCode || thirdLast !== company) {
            removePersistCompanyBranchStorage(dispatch);
            getCompanyByCode(thirdLast, secondLast, null, dispatch, false).then(() => {
              navigate(`/menu/${thirdLast}/${secondLast}/`)
            });
          }
          else {
            navigate('/');
          }
        }
        else {
          dispatch(clearBrand());
          if (lastPart === '') {
            dispatch(clearTable());
            navigate('/');
          }
          else if (lastPart !== table) {
            dispatch(setTable(lastPart));
          }
          if (secondLast !== branchCode || thirdLast !== company) {
            removePersistCompanyBranchStorage(dispatch);
            getCompanyByCode(thirdLast, secondLast, null, dispatch, navigate);
          }
        }
      }
      else if (singleSlashCondition === 3) {
        dispatch(clearBrand());
        dispatch(setTable(''));
        if (lastPart === '') {
          removePersistCompanyBranchStorage(dispatch);
          // dispatch(setTable(''));
          navigate('/')
        }
        else if (lastPart !== branchCode || secondLast !== company) {
          removePersistCompanyBranchStorage(dispatch);
          // dispatch(setTable(''));
          getCompanyByCode(secondLast, lastPart, null,dispatch, navigate);
        }
      }
      else if (singleSlashCondition === 2) {
        removePersistCompanyBranchStorage(dispatch);
        dispatch(setTable(''));
        navigate('/');
      }
      else if (singleSlashCondition === 1) {
        removePersistCompanyBranchStorage(dispatch);
        dispatch(setTable(''));
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (companyDetail && companyDetail?.brand_color) {
      // const colorData = JSON.parse(companyDetail?.company_color);
      const colorData = JSON.parse(companyDetail?.brand_color);
      const root = document.documentElement;
      root.style.setProperty('--main-color', colorData.mainColor);
      root.style.setProperty('--primary-color', colorData.primaryColor);
    }
    // eslint-disable-next-line
  }, [companyDetail])

  // useEffect(() => {
  //   if (userLocation && companyDetail) {
  //     dispatch(setLocationMessage(false));
  //     const start = { latitude: userLocation.latitude, longitude: userLocation.longitude };
  //     const end = { latitude: parseFloat(companyDetail.lat), longitude: parseFloat(companyDetail.lng) };
  //     const distanceInMeters = haversine(start, end);
  //     console.log("start: ", start);
  //     console.log("end: ", end);
  //     console.log("disp: ", distanceInMeters);
  //     if (distanceInMeters <= companyDetail?.company_range) {
  //       dispatch(setLocationBool(true));
  //     }
  //     else {
  //       dispatch(clearProductData());
  //       dispatch(setLocationBool(false));
  //     }
  //   }
  //   else if (companyDetail) {
  //     dispatch(setLocationMessage(true));
  //   }
  //   // eslint-disable-next-line
  // }, [userLocation, companyDetail]);

  // Common Routes Start
  const commonRoutes = [
    {
      path: `/`,
      element: (brandCode && brandCode !== '' && table && table !== '') ? <Navigate to={`/menu/${company}/${branchCode}/${table}/brand/${brandCode}`} /> : (brandCode && brandCode !== '' && (!table || (table && table === ''))) ? <Navigate to={`/menu/${company}/${branchCode}/brand/${brandCode}`}/> : (company && company !== '' && branchCode && branchCode !== '') ? <Navigate to={`/menu/${company}/${branchCode}/${table}`} /> : <Bespeakk />
    },
    {
      path: `/menu/${company}/${branchCode}/${table}/brand/${brandCode}`,
      element: (brandCode && brandCode !== '') ? <HomePage /> : (company && company !== '' && branchCode && branchCode !== '') ? <Navigate to={`/menu/${company}/${branchCode}/${table}`} /> : <Bespeakk />
    },
    {
      path: `/menu/${company}/${branchCode}/brand/${brandCode}`,
      element: <HomePage />
    },
    {
      path: `/menu/${company}`,
      element: <Navigate to={'/'} />
    },
    {
      path: `/menu/${company}/${branchCode}`,
      element: (company && company !== '' && branchCode && branchCode !== '') ? <HomePage /> : <Navigate to={'/'} />
    },
    {
      path: `/view-menu`,
      element: <HomePage />
    },
    {
      path: `/menu/${company}/${branchCode}/${table}`,
      element: <HomePage />
    },
    {
      path: `/menu`,
      element: <HomePage />
    },
    {
      path: '/order-review',
      element: <ReviewOrder />
    },
    {
      path: '/thank-you',
      element: <ThankYou />
    },
    {
      path: "/chat",
      element: <ChatOrder />
    },
    {
      path: "/profile",
      element: <Profile />
    }
  ]

  const authenticatedRoutes = [
    {
      path: '/order-history',
      element: <OrderHistory />
    },
    {
      path: '/invoice',
      element: <Invoice />
    }
  ]

  return (
    <Routes>
      {commonRoutes && commonRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.element}
        />
      ))}

      {authenticatedRoutes && authenticatedRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={userAuthData?.token ? route.element : <Navigate to="/" />}
        />
      ))}
    </Routes>
  );
}

export default App;