import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "../DiscountPopup/DiscountPopup.scss";
import NormalInput from "../Input/NormalInput";

export default function DiscountPopup(props) {
  const {
    showDiscountModal,
    handleDiscountModal,
    handleDiscountChange,
    discountCode,
    setDiscountCode,
  } = props;

  const closeDiscountModal = () => {
    handleDiscountModal(false);
    setDiscountCode(false);
  };

  const submitDiscount = () => {
    handleDiscountChange(discountCode);
  };

  return (
    <>
      <Offcanvas
        className="show-discount-canvas"
        show={showDiscountModal}
        onHide={closeDiscountModal}
        placement="bottom"
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="w-100"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <h5 className="apply-discount">Apply Discount Here</h5>
          </div>
          <NormalInput
            type="text"
            name="discount"
            placeholder="Enter Discount Code"
            value={discountCode}
            onChange={(event) => setDiscountCode(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submitDiscount();
              }
            }}
          />
          {discountCode && discountCode !== "" && (
            <div className="submit-discount d-flex justify-content-center">
              <Button className="btn-fillter-main" onClick={submitDiscount}>
                Apply
              </Button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
